import i18next from 'i18next'
import moment from 'moment'
import { customerConstants } from '../../constants/customer_constants'
import { customerService } from '../../services/customersServices'
import { alertActions } from './alert_actions'

export const customerActions = {
  getAll,
  deleteUser,
  getId,
  clearResults,
  getFriendSharingByScooterId,
  getAllFirebaseUser,
  getDeletedCustomers,
}

function deleteUser(body, action) {
  return (dispatch) => {
    customerService.deleteUser(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.customers.deleteUserSuccess'),
          ),
        )
        dispatch(getAllFirebaseUser())
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.customers.deleteUserError')),
        )
      },
    )
  }
}

function getAllFirebaseUser() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllFirebaseUser().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_ALL_FIREBASE_USERS_REQUEST }
  }

  function success(results) {
    return {
      type: customerConstants.GET_ALL_FIREBASE_USERS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_ALL_FIREBASE_USERS_FAILURE,
      error,
    }
  }
}

function getFriendSharingByScooterId(scooterId) {
  return (dispatch) => {
    dispatch(request())
    customerService.getFriendSharingByScooterId(scooterId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_REQUEST }
  }

  function success(results) {
    return {
      type: customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_FAILURE,
      error,
    }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAll().then(
      (results) => {
        results.forEach((element) => {
          element.birthday = moment(element.birthday).format('DD/MM/YYYY')
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     i18next.t("services.actions.customers.error.getAll")
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: customerConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error }
  }
}

function getId(id) {
  return (dispatch) => {
    dispatch(request(id))
    dispatch(success({ customer: {} }))
  }

  function request(id) {
    return { type: customerConstants.DETAILS_REQUEST, id }
  }

  function success(result) {
    return { type: customerConstants.DETAILS_SUCCESS, result }
  }

  function failure(error) {
    return { type: customerConstants.DETAILS_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: customerConstants.CLEAR_RESULTS }
  }
}

function getDeletedCustomers() {
  return (dispatch) => {
    dispatch(request())
    customerService.getDeletedCustomers().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_DELETED_CUSTOMERS_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_DELETED_CUSTOMERS_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_DELETED_CUSTOMERS_FAILURE, error }
  }
}
