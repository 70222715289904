import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  formControl: {
    width: '100%',
  },
  title: {
    padding: 25,
  },
}))

export default useStyles
