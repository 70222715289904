import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firmwareActions } from '../../../../redux/actions/fota/firmware_actions'
import Dashboard from '../../../dashboard'
import FirmwareCreateView from './page'

class FirmwareCreate extends React.Component {
  render() {
    const { firmwareReducer, t } = this.props
    return (
      <Dashboard
        component={
          <FirmwareCreateView
            title={t('fota.firmware.create.index.title')}
            insert={this.props.insert}
            firmwareReducer={firmwareReducer}
            targetTypes={firmwareReducer.targetTypes}
            hardwareTypes={firmwareReducer.hardwareTypes}
            firmwares={firmwareReducer.results}
            t={t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { firmwareReducer } = state
  return { firmwareReducer }
}

const actionCreators = {
  insert: firmwareActions.insert,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FirmwareCreate)
