import stringConstants from '../constants/strings'
import { handleCall } from './handleResponse'

// return handleCall(`/fota/v1/upgrade${id}`, {
//   method: "GET",
//   body: null,
// });
export const userService = {
  insertSilenceUser,
  changePassword,
  deleteUser,
}

function changePassword(body) {
  return handleCall(`/customers/v1/Users/UpdatePassword`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Users/UpdatePassword`,
  //   requestOptions
  // ).then(handleResponse);
}

function insertSilenceUser(body) {
  return handleCall(`/customers/v1/Users/NewSilenceUser`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/users/NewSilenceUser`,
  //   requestOptions
  // ).then(handleResponse);
}

function deleteUser(email) {
  return handleCall(
    `/customers/v1/Customers/Delete?email=${email}`,
    {
      method: 'DELETE',
      body: null,
    },
    stringConstants.URL_Fleet,
  )
}

function handleResponse(response) {
  // console.log('handleResponse', response)
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || text
      return Promise.reject(error)
    }
    return data
  })
}
