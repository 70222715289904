export const commandString = {
  POWER_ON: 'VEHICLE_ON',
  POWER_OFF: 'VEHICLE_OFF',
  TOPCASE_OPEN: 'TOPCASE_OPEN',
  TOPCASE_CLOSE: 'TOPCASE_CLOSE',
  SEAT_OPEN: 'OPEN_TRUNK',
  LOCATE: 'LOCATE',
  MESSAGE_DISPLAY: '$MESG',
  STATUS: 'STATUS',
  SCOOTER_ON: 'SCOOTER_ON',
  WARNINGS: 'WARNINGS',
}
