import { Button, Grid } from '@material-ui/core'
import React from 'react'

class FooterFormButtons extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Grid container item md={2} justify="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={this.props.onClickLeftButton}
            style={{ margin: 15 }}
            startIcon={this.props.leftIcon}
          >
            {this.props.leftButtonValue}
          </Button>
        </Grid>
        <Grid container item md={2} justify="center" alignItems="center">
          <Button
            variant="contained"
            type={this.props.typeButton}
            color="primary"
            size="small"
            style={{ margin: 15 }}
            disabled={this.props.submitted ? true : false}
            onClick={this.props.onClickRightButton}
            startIcon={this.props.rightIcon}
          >
            {this.props.rightButtonValue}
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
}

export default FooterFormButtons
