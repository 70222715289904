import GoogleMapReact from 'google-map-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import useStyles from './styles'

import stringConstants from '../../../constants/strings'

import InfoWindow from './mapComponents/infoWindow'

import useSupercluster from 'use-supercluster'
import { selectIconMarkerMap } from '../../shared/selectIconMap'
import DrawerFilter from './mapComponents/drawerFilter'

const AppUrl = process.env.PUBLIC_URL

const MapsGeofence = ({ scooterReducer, google, ongetId, saveCenterMap }) => {
  const { mapPosition } = scooterReducer
  const classes = useStyles()
  const mapRef = useRef()
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)
  const [isInfoWindowOpen, setisInfoWidnowOpen] = useState(false)
  const [selectedScooter, setselectedScooter] = useState({})
  const [displayList, setdisplayList] = useState([])
  const [isDrawerFilterOpen, setisDrawerFilterOpen] = useState(false)
  const [centerMap, setcenterMap] = useState()
  const [zoomMap, setzoomMap] = useState()

  useEffect(() => {
    const { zoom, center } = mapPosition
    setcenterMap(center)
    setzoomMap(zoom)
  }, []) //mapPosition

  const selectIconMarker = (scooter) => {
    return (
      <img
        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
        src={selectIconMarkerMap(scooter)}
      ></img>
    )
  }

  const markerStyle = {
    marginTop: '-34px',
    marginLeft: '-17px',
  }
  const handleClickMap = () => {
    setisInfoWidnowOpen(false)
  }

  const handleMarkerSelected = (scooter) => {
    setisInfoWidnowOpen(true)
    setselectedScooter(scooter)
  }

  const handleSelectScooterFilter = (value) => {
    console.log('handleSelectScooterFilter value', value)
    if (value) {
      const { gps, scooterId } = value || {
        gps: { latitude: 0, longitude: 0 },
        scooterId: null,
      } //gps: {latitude: 41.375488, longitude: 2.085005}

      mapRef.current.setZoom(15)
      mapRef.current.panTo({ lat: gps.latitude, lng: gps.longitude })

      setisDrawerFilterOpen(false)
      setdisplayList(
        scooterReducer.dynamics.filter((s) => s.scooterId === scooterId),
      )
      handleMarkerSelected(value)
    }
  }

  useEffect(() => {
    handleShowAllScooters()
  }, [scooterReducer.dynamics])

  const handleShowAllScooters = () => {
    setisDrawerFilterOpen(false)
    setdisplayList(scooterReducer.dynamics)
    setisInfoWidnowOpen(false)
    setselectedScooter({})
  }

  const points = displayList
    .filter((s) => s.gps)
    .map((s) => {
      const { gps, model, imei, id } = s
      return {
        properties: {
          cluster: false,
          scooterData: s,
        },
        geometry: {
          coordinates: [gps.longitude, gps.latitude],
        },
      }
    })

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 30, maxZoom: 20 },
  })
  const mapInstance = useRef()
  const mapApi = useRef()
  return (
    <Fragment>
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          options={{
            streetViewControl: true,
          }}
          onClick={handleClickMap}
          bootstrapURLKeys={{ key: stringConstants.GOOGLE_API_KEY }}
          defaultCenter={centerMap}
          defaultZoom={zoomMap}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map

            mapInstance.current = map
            mapApi.current = maps

            const searchBtn = document.createElement('button')
            const buttonize = (btn) => {
              btn.type = 'button'
              btn.setAttribute(
                'style',
                `
                  cursor: pointer;
                  padding: 0px 8px;
                  margin: 5px 10px 5px -5px;
                  color: rgb(121, 121, 121);
                  font-weight: bold;
                  border: none;
                  background: white;
                  outline: none;
                  box-shadow: none;
                  z-index: 0;
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  width: 40px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              `,
              )
              btn.innerHTML = 'Filter'
              btn.addEventListener('mouseover', () => {
                btn.style.color = 'black'
              })
              btn.addEventListener('mouseout', () => {
                btn.style.color = 'rgb(121, 121, 121)'
              })
            }
            buttonize(searchBtn)
            searchBtn.addEventListener('click', () => {
              setisDrawerFilterOpen(true)
            })
            map.controls[mapApi.current.ControlPosition.TOP_RIGHT].push(
              searchBtn,
            )
          }}
          onChange={({ zoom, bounds, center }) => {
            saveCenterMap({ zoom, center })
            setZoom(zoom)
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ])
          }}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties

            if (isCluster) {
              return (
                <div
                  style={markerStyle}
                  key={cluster.id}
                  lat={latitude}
                  lng={longitude}
                  className={classes.clusterMarker}
                  // style={{
                  //   width: `${10 + (pointCount / points.length) * 20}px`,
                  //   height: `${10 + (pointCount / points.length) * 20}px`,
                  //   cursor: "pointer",
                  // }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20,
                    )
                    mapRef.current.setZoom(expansionZoom)
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                  }}
                >
                  <img
                    src={`${AppUrl}/content/icons/m${
                      pointCount < 10 ? '1' : '2'
                    }.png`}
                  />
                  <span
                    className={
                      pointCount < 10
                        ? classes.pointCountSingle
                        : classes.pointCount
                    }
                  >
                    {pointCount}
                  </span>
                </div>
              )
            } else {
              return (
                <div
                  style={markerStyle}
                  key={cluster.id}
                  lat={latitude}
                  lng={longitude}
                  onClick={() =>
                    handleMarkerSelected(cluster.properties.scooterData)
                  }
                >
                  {selectIconMarker(cluster.properties.scooterData)}
                </div>
              )
            }
          })}
        </GoogleMapReact>
        {isInfoWindowOpen && (
          <InfoWindow
            scooterReducer={scooterReducer}
            scooterData={selectedScooter}
            getDetailsScooter={() => ongetId(selectedScooter.scooterId)}
          />
        )}

        {/* <div className={classes.btnFilterDrawer}>
          <IconButton
            onClick={() => setisDrawerFilterOpen(true)}
            className={classes.btnFilter}
          >
            <SearchIcon
              fontSize="large"
              // className={classes.iconStyle}
              onClick={() => setisDrawerFilterOpen(true)}
            />
          </IconButton>
        </div> */}
      </div>

      <DrawerFilter
        isOpen={isDrawerFilterOpen}
        onClose={() => setisDrawerFilterOpen(false)}
        scooterData={scooterReducer.dynamics}
        onSelect={handleSelectScooterFilter}
        onShowAllScooters={handleShowAllScooters}
      />
    </Fragment>
  )
}

export default MapsGeofence
