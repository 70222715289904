import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fileActions } from '../../../redux/actions/fota/file_actions'
import Dashboard from '../../dashboard'
import FileView from './page'

class File extends React.Component {
  render() {
    return <Dashboard component={<FileView {...this.props} />} />
  }
}

function mapState(state) {
  const { fileReducer } = state
  return { fileReducer }
}

const actionCreators = {
  getFiles: fileActions.getAll,
  getById: fileActions.getById,
  _delete: fileActions._delete,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(File)
