import { customerConstants } from '../../constants/customer_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  friendSharingByScooterId: { sharingUsers: [], lastChange: null },
  firebaseUsers: [],
  deletedCustomers: [],
}

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.GET_ALL_FIREBASE_USERS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_ALL_FIREBASE_USERS_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        firebaseUsers: action.results,
      }
    case customerConstants.GET_ALL_FIREBASE_USERS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        firebaseUsers: [],
        error: action.error,
      }

    case customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        friendSharingByScooterId: action.results,
      }
    case customerConstants.FRIEND_SHARING_BY_SCOOTER_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        friendSharingByScooterId: { sharingUsers: [], lastChange: null },
        error: action.error,
      }

    case customerConstants.FILTER_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.FILTER_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        results: action.results,
      }
    case customerConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case customerConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case customerConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case customerConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.DETAILS_SUCCESS:
      return {
        ...state,
        customer: action.detail,
        loadingResults: false,
        showResults: true,
      }
    case customerConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case customerConstants.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        showResults: false,
        loadingResults: false,
      }
    case customerConstants.GET_DELETED_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.GET_DELETED_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingResuls: false,
        showResults: true,
        deletedCustomers: action.results,
      }
    case customerConstants.GET_DELETED_CUSTOMERS_FAILURE:
      return {
        ...state,
        loadingResuls: false,
        error: action.error,
      }
    default:
      return state
  }
}
