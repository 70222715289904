import { notificationsConstants } from '../../constants/notifications_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  notification: {},
}

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case notificationsConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        notification: action.results,
      }
    case notificationsConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        notification: {},
        error: action.error,
      }

    case notificationsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case notificationsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        results: action.results,
      }
    case notificationsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }

    default:
      return state
  }
}
