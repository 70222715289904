import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firmwareActions } from '../../../../redux/actions/fota/firmware_actions'
import Dashboard from '../../../dashboard/index'
import FirmwareEditView from './page'

class FirmwareEdit extends React.Component {
  render() {
    const { firmwareReducer, t } = this.props
    const firmware = firmwareReducer.firmware
    return (
      <Dashboard
        component={
          <FirmwareEditView
            id={firmware.id}
            targetType={firmware.targetType}
            targetModel={firmware.targetModel}
            initialFrameNo={firmware.initialFrameNo}
            finalFrameNo={firmware.finalFrameNo}
            hardwareType={firmware.hardwareType}
            hardwareModel={firmware.hardwareModel}
            version={firmware.version}
            responsible={firmware.responsible}
            description={firmware.description}
            readEdit={true}
            title={t('fota.firmware.edit.index.title')}
            targetTypes={firmwareReducer.targetTypes}
            hardwareTypes={firmwareReducer.hardwareTypes}
            firmwares={firmwareReducer.results}
            update={this.props.update}
            firmwareReducer={firmwareReducer}
            t={t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { firmwareReducer } = state
  return { firmwareReducer }
}

const actionCreators = {
  update: firmwareActions.update,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FirmwareEdit)
