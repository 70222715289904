import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import React from 'react'
import { factory } from '../../../../helpers/factory'
import AlertDialog from '../../../alert-dialog'
import MaterialTableExtend from '../../../material-table/index'
import Spinner from '../../../shared/Spinner'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

class TargetsFotaSelectionView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsSelected: [],
      targets: props.results,
      openModal: false,
    }
    this.onSelectionChange = this.onSelectionChange.bind(this)
    this.onClickButton = this.onClickButton.bind(this)
    this.onClickOpenModal = this.onClickOpenModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.processDataFile = this.processDataFile.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
  }

  onSelectionChange(rows) {
    this.setState({
      rowsSelected: rows,
    })
  }

  onClickAcceptModal() {
    this.setState({ openModal: false })
    this.onClickButton()
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  onClickOpenModal() {
    this.setState({ openModal: true })
  }

  onClickButton() {
    const formState = this.props.dates
    const silId = formState.silSelected.id
    const filtered = Object.values(formState.oldVersion).filter(
      (value) => value.selected !== '',
    )
    const upgrades = []
    Object.values(filtered).forEach((item) => {
      upgrades.push(item.selected.id)
    })
    const targetIds = this.state.rowsSelected.map((x) => x.id)
    const startDate = formState.startDate
    const expireDate = formState.endDate
    const skipCustomerConfirmation = formState.skipCustomerConfirmation
    const process = factory.createStartProcessFota(
      silId,
      targetIds,
      upgrades,
      startDate,
      expireDate,
      skipCustomerConfirmation,
    )
    this.props.startProcess(process)
    this.props.handleClose()
    this.props.startSubmit()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.results !== null) {
      this.setState({ targets: nextProps.results })
    }
  }

  processDataFile(result) {
    const data = this.processData(result)
    const targets = this.state.targets
    const selected = this.state.rowsSelected
    targets.forEach((target) => {
      data.forEach((itemFile) => {
        if (itemFile.id === target.id) {
          target.tableData.checked = true
          selected.push(itemFile)
        }
      })
    })
    this.setState({ targets, rowsSelected: selected })
  }

  handleChangeFile() {
    const file = document.querySelector('#FileBinary').files[0]

    var reader = new FileReader()
    reader.onload = () => this.processDataFile(reader.result)
    if (file != null) {
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file)
    }
  }

  processData(allText) {
    var allTextLines = allText.split(/\r\n|\n/)
    var headers = allTextLines[0].split(',')
    var lines = []
    for (var i = 1; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(',')
      if (data.length === headers.length) {
        var tarr = {}
        for (var j = 0; j < headers.length; j++) {
          tarr[headers[j]] = data[j]
        }
        lines.push(tarr)
      }
    }
    return lines
  }

  render() {
    const classes = this.props.styles
    const { t } = this.props
    return (
      <React.Fragment>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t('fota.fota.form.targets.title_1')}
              </Typography>
              <Button
                color="secondary"
                component="label"
                variant="contained"
                style={{ marginRight: 15 }}
                startIcon={<AttachFileIcon />}
              >
                <input
                  type="file"
                  name="FileBinary"
                  id="FileBinary"
                  onChange={this.handleChangeFile}
                  className={classes.inputFile}
                  style={{ display: 'none' }}
                  accept=".csv"
                />
                {t('fota.fota.form.targets.uploadFile')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={this.onClickOpenModal}
                disabled={this.state.rowsSelected.length === 0 ? true : false}
                startIcon={<CloudUploadIcon />}
              >
                {t('fota.fota.form.targets.button')}
              </Button>
            </Toolbar>
          </AppBar>
          {!this.props.loadingResults ? (
            <MaterialTableExtend
              title={t('fota.fota.form.targets.title_2')}
              columns={[
                { title: 'Id', field: 'id' },
                {
                  title: t('fota.fota.form.targets.columns.model'),
                  field: 'model',
                },
                {
                  title: t('fota.fota.form.targets.columns.vin'),
                  field: 'vin',
                },
                {
                  title: t('fota.fota.form.targets.columns.matricula'),
                  field: 'plate',
                },
                {
                  title: t('fota.fota.form.targets.columns.imei'),
                  field: 'imei',
                },
                {
                  title: t('fota.fota.form.targets.columns.name'),
                  field: 'name',
                },
              ]}
              data={this.state.targets}
              actionsColumnIndex={10}
              selection={true}
              onSelectionChange={this.onSelectionChange}
            />
          ) : (
            <Spinner loading={this.props.loadingResults} />
          )}
        </Dialog>
        <AlertDialog
          open={this.state.openModal}
          title={t('fota.fota.form.targets.alert_dialog.title')}
          onClickAccept={this.onClickAcceptModal}
          onClickCancel={this.onClickCancelModal}
        />
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const styles = useStyles()
  return <TargetsFotaSelectionView styles={styles} {...props} />
}

export default Hook
