import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BallBeat } from 'react-pure-loaders'

const RsdkDetails = ({
  isOpen,
  onClose,
  data,
  resetRsdk,
  getRsdkByVehicleId,
  scooterReducer,
}) => {
  const { t } = useTranslation()
  const [genericKey, setGenericKey] = useState(null)

  const fetchData = async () => {
    return await getRsdkByVehicleId(data.id)
  }

  useEffect(() => {
    fetchData().then(
      (genericKey) => {
        setGenericKey(genericKey ?? null)
      },
      () => {
        setGenericKey(null)
      },
    )
    return () => {
      setGenericKey(null)
    }
  }, [data])

  const handleClose = () => {
    onClose()
  }

  if (!data) return null

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={'100%'}>
        <DialogTitle>
          {t('scooters.dialogs.resetRsdk.manageRsdkTitle')}
        </DialogTitle>
        <DialogContent>
          {scooterReducer.loadingResetRsdk ? (
            <BallBeat loading={true} color={'#e41622'} size={50} />
          ) : genericKey && genericKey.timestamp ? (
            <p>
              {t('scooters.dialogs.resetRsdk.lastRsdkCreated')}
              {moment(genericKey.timestamp).format('DD/MM/YYYY HH:mm:ss')}
            </p>
          ) : (
            <p>{t('scooters.dialogs.resetRsdk.vehicleNoHaveRsdk')}</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={scooterReducer.loadingResetRsdk}
            onClick={() => resetRsdk(data.id)}
            color="primary"
          >
            {genericKey
              ? t('scooters.page.columns.resetRsdk')
              : t('scooters.page.columns.newRsdk')}
          </Button>
          <Button
            disabled={scooterReducer.loadingResetRsdk}
            onClick={handleClose}
            color="primary"
          >
            {t('scooters.dialogs.sendOptions.cancelar')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default RsdkDetails
