import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { customerActions } from 'src/redux/actions/customers_actions'
import { oemActions } from 'src/redux/actions/oem_actions'
import { operatorActions } from 'src/redux/actions/operator_action'

const useStyles = makeStyles({
  buttonSection: {
    marginTop: ' 10%',
    // padding: '0% 4%'
  },
})

const AssignScooterManualyToUser = ({
  isOpen,
  onClose,
  onConfirm,
  operatorReducer,
  customerReducer,
  oemReducer,
  getAllOperators,
  getAllCustomers,
  getAllOEMs,
}) => {
  const { results: operators } = operatorReducer
  const { results: users } = customerReducer
  const { results: oems } = oemReducer

  const { t } = useTranslation()
  const classes = useStyles()
  const [userType, setuserType] = useState(
    t('assignScooter.assignScooterManualyToUser.operator'),
  )
  const [userSelected, setuserSelected] = useState({})
  const [labelAutocomplete, setlabelAutocomplete] = useState(
    t('assignScooter.assignScooterManualyToUser.operator'),
  )
  const [noOptionMail, setnoOptionMail] = useState('')
  const [autocompleteList, setautocompleteList] = useState([])
  const [errorMsg, seterrorMsg] = useState(null)

  const [diasbleBtnConfirm, setdiasbleBtnConfirm] = useState(false)

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    switch (userType) {
      case t('assignScooter.assignScooterManualyToUser.operator'):
        setautocompleteList(operators)
        setlabelAutocomplete('Operator')
        seterrorMsg(
          t('assignScooter.assignScooterManualyToUser.helperTextOperator'),
        )
        setdiasbleBtnConfirm(false)
        break
      case t('assignScooter.assignScooterManualyToUser.customer'):
        setautocompleteList(users)
        setlabelAutocomplete(
          t('assignScooter.assignScooterManualyToUser.customer'),
        )
        seterrorMsg(
          t('assignScooter.assignScooterManualyToUser.helperTextCustomer'),
        )
        setdiasbleBtnConfirm(false)
        break
      case t('assignScooter.assignScooterManualyToUser.oem'):
        setautocompleteList(oems)
        setlabelAutocomplete(t('assignScooter.assignScooterManualyToUser.oem'))
        seterrorMsg(t('assignScooter.assignScooterManualyToUser.helperTextOEM'))
        setdiasbleBtnConfirm(false)
        break
      default:
        break
    }
    setuserSelected({})
  }, [userType])

  useEffect(() => {
    if (isOpen) {
      getAllOperators()
      getAllCustomers()
      getAllOEMs()
    }
  }, [isOpen])

  useEffect(() => {
    setautocompleteList(operators)
  }, [operators])

  const handleChangeAutoCompleteValue = (value) => {
    setuserSelected(value)
  }

  const hasAnyError = () => {
    switch (userType) {
      case t('assignScooter.assignScooterManualyToUser.operator'):
        const updatedOperatorList = operators.filter((o) => {
          return o.id === (userSelected && userSelected.id)
        })
        if (updatedOperatorList.length > 0) {
          setnoOptionMail('')
          setdiasbleBtnConfirm(false)
        }
        break
      case t('assignScooter.assignScooterManualyToUser.customer'):
        const updatedUserList = users.filter((o) => {
          return o.id === (userSelected && userSelected.id)
        })
        if (updatedUserList.length > 0) {
          setnoOptionMail('')
          setdiasbleBtnConfirm(false)
        }
        break
      case t('assignScooter.assignScooterManualyToUser.oem'):
        const updateOEMList = oems.filter((o) => {
          return o.oid === (userSelected && userSelected.oid)
        })
        if (updateOEMList.length > 0) {
          setnoOptionMail('')
          setdiasbleBtnConfirm(false)
        }
        break
      default:
        break
    }
  }
  useEffect(() => {
    hasAnyError()
  }, [userSelected])

  const handleChangeInputValue = (value) => {
    setnoOptionMail(value)
  }

  const handleConfirm = () => {
    onConfirm(userSelected, userType, noOptionMail)
    onClose()
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('assignScooter.assignScooterManualyToUser.Title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('assignScooter.assignScooterManualyToUser.dialogContent')}
          </DialogContentText>

          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={userType}
            onChange={(e) => setuserType(e.target.value)}
          >
            <FormControlLabel
              value={t('assignScooter.assignScooterManualyToUser.operator')}
              control={<Radio color="primary" />}
              label={t('assignScooter.assignScooterManualyToUser.operator')}
              labelPlacement="end"
            />
            <FormControlLabel
              value={t('assignScooter.assignScooterManualyToUser.customer')}
              control={<Radio color="primary" />}
              label={t('assignScooter.assignScooterManualyToUser.customer')}
              labelPlacement="end"
            />
            <FormControlLabel
              value={t('assignScooter.assignScooterManualyToUser.oem')}
              control={<Radio color="primary" />}
              label={t('assignScooter.assignScooterManualyToUser.oem')}
              labelPlacement="end"
            />
          </RadioGroup>
          <Autocomplete
            size="small"
            onChange={(_, value) => handleChangeAutoCompleteValue(value)}
            value={userSelected}
            debug
            disableClearable
            forcePopupIcon
            options={autocompleteList}
            getOptionLabel={(option) => {
              switch (userType) {
                case t('assignScooter.assignScooterManualyToUser.operator'):
                  return option.managerEmail || ''
                case t('assignScooter.assignScooterManualyToUser.customer'):
                  return option.email || ''
                case t('assignScooter.assignScooterManualyToUser.oem'):
                  return option.email || ''
              }
            }}
            noOptionsText={errorMsg}
            className={classes.root}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => handleChangeInputValue(e.target.value)}
                label={labelAutocomplete}
                placeholder={`Email ${labelAutocomplete}`}
                InputLabelProps={{
                  style: { color: 'black', borderColor: 'black' },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.buttonSection}>
          <Button onClick={handleClose} color="primary">
            {t('assignScooter.assignScooterManualyToUser.cancel')}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={diasbleBtnConfirm}
          >
            {t('assignScooter.assignScooterManualyToUser.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
function mapState(state) {
  const { operatorReducer, customerReducer, oemReducer } = state
  return { operatorReducer, customerReducer, oemReducer }
}

const actionCreators = {
  getAllOperators: operatorActions.getAll,
  getAllCustomers: customerActions.getAll,
  getAllOEMs: oemActions.getAll,
}

export default connect(mapState, actionCreators)(AssignScooterManualyToUser)
