import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import Dashboard from '../../dashboard'
import Spinner from '../../shared/Spinner'
import DetailsViewScooter from './page'

const ScooterDetails = (props) => {
  const { idScooter, scooterReducer } = props
  useEffect(() => {
    props.getTrips(idScooter)
    props.getDynamyc(idScooter)
  }, [])

  return (
    <Dashboard
      component={
        scooterReducer.trips != null && scooterReducer.dynamic != null ? (
          <DetailsViewScooter {...props} />
        ) : (
          <Spinner loading={scooterReducer.loadingResults} />
        )
      }
    />
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getTrips: scooterActions.getTripsByScooterId,
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
}

function Hook(props) {
  const { id } = useParams()
  return <ScooterDetails {...props} idScooter={id} />
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
