import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Command from '../details/command'
const SendOptions = ({ isOpen, onClose, data }) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={'100%'}>
        <DialogTitle>
          {t('scooters.dialogs.sendOptions.dialogTitle')}
        </DialogTitle>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '0% 2%',
          }}
        >
          <span>
            (
            {Array.isArray(data)
              ? data.map((i, index) => {
                  return `${i.plate}${index != data.length - 1 ? ', ' : ''}`
                })
              : `${data && data.plate}`}
            )
          </span>
        </div>
        <DialogContent>
          <Command multipleScooters={true} data={data} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('scooters.dialogs.sendOptions.cancelar')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SendOptions
