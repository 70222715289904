import { loginService } from './loginService'

import { handleCall } from './handleResponse'

export const scooterService = {
  getAll,
  getById,
  insert,
  update,
  _delete,
  filter,
  getDynamics,
  getDynamicsById,
  getTripsByScooterId,
  getTripDetails,
  getAllMapByOperatorId,
  assignToOperate,
  getAllDynamicByOperator,
  getAllDynamics,
  getDataById,
  getMissingDynamic,
  assignToSharingOperator,
  assignToPrivateCustomer,
  unassignToSharingOperator,
  createNotification,
  getAllByCustomer,
  assignToOEM,
  getAllByOemId,
  sendCommand,
  removeFriendSharingUser,
  getImage,
  getStatistics,
  resetRsdk,
  getRsdkByVehicleId,
}

function getImage(options) {
  const { model, color } = options

  return handleCall(
    `/vehicles/v1/Vehicles/GetImage?model=${model}&color=${color}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // const requestOptions = {
  //   method: "get",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetImage?model=${model}&color=${color}`,
  //   requestOptions
  // ).then(handleResponse);
}

function removeFriendSharingUser(body) {
  return handleCall(`/vehicles/v1/Vehicles/RemoveFriendSharingUser`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/RemoveFriendSharingUser`,
  //   requestOptions
  // ).then(handleResponse);
}

function sendCommand(body) {
  let params = ''
  if (body.command.args) {
    params = `${body.command.command},${body.command.args[0]},${body.command.args[1]}`
  } else {
    params = body.command.command
  }
  return handleCall(`/vehicles/v1/Vehicles/${body.imei}/command`, {
    method: 'PUT',
    body: `"${params}"`,
  })

  // const requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: `"${params}"`,
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/${body.imei}/command`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllByOemId(oemId) {
  return handleCall(`/vehicles/v1/Vehicles?field=Oem.OemOid&value=${oemId}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles?field=Oem.OemOid&value=${oemId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllByCustomer(userId) {
  return handleCall(`/vehicles/v1/Vehicles/${userId}/GetByCustomer`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // // `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles?field=UserId&value=${userId}`,
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/${userId}/GetByCustomer`,
  //   requestOptions
  // ).then(handleResponse);
}

function createNotification(body) {
  return handleCall(`/vehicles/v1/Notifications/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Notifications/New",
  //   requestOptions
  // ).then(handleResponse);
}

function assignToOEM(body) {
  return handleCall(`/vehicles/v1/Vehicles/AssignOem`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/AssignOem",
  //   requestOptions
  // ).then(handleResponse);
}

function assignToPrivateCustomer(body) {
  return handleCall(`/vehicles/v1/Vehicles/AssignPrivateCustomer`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/Vehicles/AssignPrivateCustomer",
  //   requestOptions
  // ).then(handleResponse);
}

function unassignToSharingOperator(body) {
  return handleCall(`/vehicles/v1/Vehicles/UnassignOwner`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/UnassignOwner",
  //   requestOptions
  // ).then(handleResponse);
}

function assignToSharingOperator(body) {
  return handleCall(`/vehicles/v1/Vehicles/AssignSharingOperator`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/Vehicles/AssignSharingOperator",
  //   requestOptions
  // ).then(handleResponse);
}

function getMissingDynamic() {
  return handleCall(`/vehicles/v1/Vehicles/GetMissingDynamic`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/GetMissingDynamic",
  //   requestOptions
  // ).then(handleResponse);
}

function getDataById(scooterId) {
  return handleCall(`/vehicles/v1/Vehicles/${scooterId}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/" + scooterId,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDynamics() {
  return handleCall(`/vehicles/v1/Vehicles/GetAllDynamic`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/GetAllDynamic",
  //   requestOptions
  // ).then(handleResponse);
}

function assignToOperate(body) {
  return handleCall(`/vehicles/v1/Vehicles/CanOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/CanOperate",
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDynamicByOperator(operatorId) {
  return handleCall(
    `/vehicles/v1/Vehicles/GetAllDynamicByOperator/${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetAllDynamicByOperator/${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllMapByOperatorId(operatorId) {
  return handleCall(`/vehicles/v1/Vehicles/GetAllMapByOperator/${operatorId}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetAllMapByOperator/${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll(operatorId) {
  const params = operatorId
    ? `?field=SharingOperatorId&value=${operatorId}`
    : ''

  return handleCall(`/vehicles/v1/Vehicles/GetForWeb${params}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const params = operatorId
  //   ? `?field=SharingOperatorId&value=${operatorId}`
  //   : "";

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles" + params,
  //   requestOptions
  // ).then(handleResponse);
}

function getById(id) {
  return handleCall(`/vehicles/v1/Vehicles/${id}`, {
    method: 'GET',
    body: null,
  })

  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(upgrade) {
  return handleCall(`/vehicles/v1/Vehicles`, {
    method: 'POST',
    body: JSON.stringify(upgrade),
  })
  // const requestOptions = {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles",
  //   requestOptions
  // ).then(handleResponse);
}

function update(upgrade) {
  const id = JSON.stringify(upgrade).id
  return handleCall(`/vehicles/v1/Vehicles/${id}`, {
    method: 'PUT',
    body: JSON.stringify(upgrade),
  })

  // const requestOptions = {
  //   method: "PUT",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // const id = JSON.stringify(upgrade).id;
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function filter(data) {
  const querystring = data.field + '&value=' + data.value
  return handleCall(`/vehicles/v1/vehicles/?field=${querystring}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // const querystring = data.field + "&value=" + data.value;
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/?field=" +
  //     querystring,
  //   requestOptions
  // ).then(handleResponse);
}

function _delete(id) {
  return handleCall(`/vehicles/v1/vehicles/${id}`, {
    method: 'DELETE',
    body: null,
  })
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function getDynamicsById(idScooter) {
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/dynamic`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/dynamic",
  //   requestOptions
  // ).then(handleResponse);
}

function getTripsByScooterId(idScooter, since, until) {
  const filter = since && until ? `?from=${since}&until=${until}` : ''
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/trips${filter}`, {
    method: 'GET',
    body: null,
  })

  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // const filter = since && until ? `?from=${since}&until=${until}` : "";
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/trips" +
  //     filter,
  //   requestOptions
  // ).then(handleResponse);
}

function getTripDetails(idScooter, idTrip) {
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/trips/${idTrip}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/trips/" +
  //     idTrip,
  //   requestOptions
  // ).then(handleResponse);
}

function getDynamics() {
  return handleCall(`/vehicles/v1/vehicles/dynamics`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/dynamics",
  //   requestOptions
  // ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}

function getStatistics(antiquityCheckDays) {
  return handleCall(
    `/vehicles/v1/vehicles/statistics?dynamicsAntiquityDays=${antiquityCheckDays}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function resetRsdk(vehicleId) {
  const body = { vehicleId }
  return handleCall(`/vehicles/v1/Vehicles/${vehicleId}/Rsdk`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getRsdkByVehicleId(vehicleId) {
  return handleCall(`/vehicles/v1/Vehicles/${vehicleId}/Rsdk`, {
    method: 'GET',
    body: null,
  })
}
