import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { history } from '../../../helpers/history'
import AlertDialog from '../../alert-dialog/index'
import Spinner from '../../shared/Spinner'
import DetailPanelUpgrade from './detail'
import useStyles from './styles'

import CustomMaterialTable from '../../shared/customMaterialTable'
class UpgradeView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
  }

  onClickComeBack() {
    history.push('/firmwares')
  }

  componentDidMount() {
    this.props.getUpgrades()
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  onClickAcceptModal() {
    this.setState({ openModal: false })
    this.props._delete(this.state.item.id)
  }

  onClickOpenModal(data) {
    this.setState({ openModal: true, item: data })
  }

  render() {
    const { upgradeReducer, t } = this.props
    const classes = this.props.styles
    const Wrapper = () => (
      <React.Fragment>
        <Tooltip title={t('fota.upgrade.page.wrapper.back')}>
          <IconButton onClick={this.onClickComeBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <Wrapper />
        {!upgradeReducer.loadingResults ? (
          <React.Fragment>
            <div className={classes.results}>
              <CustomMaterialTable
                saveFilters={true}
                title={t('fota.upgrade.page.title')}
                columns={[
                  {
                    title: t('fota.upgrade.page.columns.target_type'),
                    field: 'targetType',
                  },
                  {
                    title: t('fota.upgrade.page.columns.target_model'),
                    field: 'targetModel',
                  },
                  { title: 'Id', field: 'id', hidden: true },
                  {
                    title: t('fota.upgrade.page.columns.hw_type'),
                    field: 'hardwareType',
                  },
                  {
                    title: t('fota.upgrade.page.columns.hw_model'),
                    field: 'hardwareModel',
                  },
                  {
                    title: t('fota.upgrade.page.columns.fw_initial'),
                    field: 'oldVersion',
                  },
                  {
                    title: t('fota.upgrade.page.columns.fw_final'),
                    field: 'newVersion',
                  },
                  {
                    title: t('fota.upgrade.page.columns.dateCreate'),
                    field: 'createDate',
                    hidden: true,
                  },
                  {
                    title: t('fota.upgrade.page.columns.userCreate'),
                    field: 'createUser',
                    hidden: true,
                  },
                ]}
                data={upgradeReducer.results}
                actions={[
                  {
                    icon: 'delete',
                    tooltip: t('fota.upgrade.page.actions.delete'),
                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                  },
                ]}
                detailPanel={(rowData) => {
                  return (
                    <DetailPanelUpgrade
                      t={t}
                      rowData={rowData}
                      classes={classes}
                    />
                  )
                }}
              />
            </div>
            <AlertDialog
              open={this.state.openModal}
              title={t('fota.upgrade.page.alert_dialog.title')}
              onClickAccept={this.onClickAcceptModal}
              onClickCancel={this.onClickCancelModal}
            />
          </React.Fragment>
        ) : (
          <Spinner loading={upgradeReducer.loadingResults} />
        )}
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const styles = useStyles()
  return <UpgradeView styles={styles} {...props} />
}

export default Hook
