import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  results: {
    ' & .MuiTableHead-root .MuiIconButton-label': {
      display: 'none',
    },
  },
  rootDetail: {
    ' & .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

export default useStyles
