import {
  AppBar,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeletedUserDetails = ({ selectedRow, open, handleClose }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Unified details</Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Grid
            container
            spacing={1}
            style={{ padding: '1%', maxWidth: '100%' }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.name')}
                value={selectedRow.name}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.surname')}
                value={selectedRow.surnames}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.email')}
                value={selectedRow.email}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.city')}
                value={selectedRow.city}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.address')}
                value={selectedRow.address}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('deletedUsers.details.gender')}
                value={selectedRow.gender}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

export default DeletedUserDetails
