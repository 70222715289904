import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { history } from '../../../../helpers/history'
import Dashboard from '../../../dashboard/index'
import FotaDetailsView from './page'

class FotaDetails extends React.Component {
  onClickComeBack() {
    history.goBack()
  }

  render() {
    const { fotaReducer, t } = this.props
    return (
      <Dashboard
        component={
          <FotaDetailsView
            {...this.props}
            fota={fotaReducer.fota}
            readOnly={true}
            onClickLeftButton={this.onClickComeBack}
            leftIcon={<KeyboardBackspaceIcon />}
            leftButtonValue={t('fota.fota.details.index.leftButtonValue')}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { fotaReducer } = state
  return { fotaReducer }
}

const actionCreators = {
  /*  getById: fotaActions.getById */
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FotaDetails)
