import { makeStyles } from '@material-ui/core/styles'

const TEXT_STYLE = { fontSize: '13pt', fontWeight: 'bold' }

const useStyles = makeStyles((theme) => ({
  text: TEXT_STYLE,
  copyrightText: {
    fontSize: '10pt',
    fontWeight: 'bold',
  },
  websiteText: { ...TEXT_STYLE, textDecoration: 'none', color: '#A7292A' },
  acceptButton: {
    backgroundColor: '#A7292A',
    color: 'white',
    fontSize: '10pt',
    fontWeight: 'bold',
  },
}))

export default useStyles
