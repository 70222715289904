import store from '../redux/store'

export const rolesConstants = {
  MANAGER_ADMIN: 'Mgmt.Admin',
  SHARING_ADMIN: 'Sharing.Admin',
  MANAGER: 'Mgmt.Manager', //no pot donar de baixa res, no ha de veure FOTA ni mòdul de creació OEM
  CUSTOMER: 'Mgmt.Customer',
  OEM: 'Mgmt.Oem',

  SHARING_OPERATOR_MANAGER: 'Sharing.OperatorManager',
  SHARING_OPERATOR: 'Sharing.Operator',
  SHARING_TECHNICIAN: 'Sharing.Technician',
  SHARING_CUSTOMER: 'Sharing.Customer',
}

export const rolesFirebase = [
  rolesConstants.MANAGER_ADMIN,
  rolesConstants.SHARING_ADMIN,
  rolesConstants.CUSTOMER,
  rolesConstants.MANAGER,
  rolesConstants.OEM,
  rolesConstants.SHARING_OPERATOR_MANAGER,
  rolesConstants.SHARING_OPERATOR,
  rolesConstants.SHARING_TECHNICIAN,
  rolesConstants.SHARING_CUSTOMER,
]

export const rolesAllowed = [
  rolesConstants.MANAGER_ADMIN,
  rolesConstants.SHARING_ADMIN,
  rolesConstants.MANAGER,
  rolesConstants.OEM,
]

export const isRoleAllowedToAccess = (role) => {
  return rolesAllowed.filter((ra) => role === ra).length > 0
}
export const allowAccessRole = (rolesDetected) => {
  let rolesAllowedResult = []
  rolesDetected.map((i) => {
    rolesAllowed.map((roleConstant) => {
      roleConstant === i.role && rolesAllowedResult.push(i)
    })
  })
  return rolesAllowedResult
}

export const isManager = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.hasOwnProperty('user') &&
    authenticationReducer.user.role === rolesConstants.MANAGER
  )
}

store.subscribe(isManager)
