import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import Root from './Root'
import theme from './components/theme'
import i18n from './i18n'
import store from './redux/store'

export default class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Root />
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    )
  }
}
