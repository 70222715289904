function toUint(value) {
  if (value === '') return 0
  value.replace(/[a-zA-Z]+/g, '')
  value = parseInt(value)
  return value
}

function clamp(value, min, max) {
  return value < min ? min : value > max ? max : value
}

export { clamp, toUint }
