import { Card, Grid, TextField } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import useStyles from './style'

const SpecificationScooterView = ({ t, scooterReducer }) => {
  const classes = useStyles()

  const { dynamic, scooter } = scooterReducer

  const {
    analogues,
    data, //{battery1Soc: 100,battery1Voltage: 0,battery1currentDrain: 0,battery2Soc: 0,battery2Voltage: 0,battery2currentDrain: 0,drivingMode: 0,flags: 0,lifetimeOdometer: 1805,range: 97,status: 0,velocity: 0,version: "generic"}
    devicePower, //{externalInputVoltage: 12, internalBackupBatteryLevel: 94}
    digitals,
    es4,
    gpsData, //{lastFixTimestamp: "2020-10-27T10:31:07Z", latitude: 41.222297, longitude: 1.535074, currentSpeed: 4, maxSpeed: 0}
    lastconnectionTimestamp,
    motoState,
    scooterId,
    scutum, //{ ambientTemperature: 0,batteryCurrentDrain: 0,batteryMaxTemperature: 31,batteryPackId: 20497,batterySoc: 67,batteryVoltage: 527,drivingMode: 0,inverterTemperature: 32,motorTemperature: 33,status: 0,totalOdometer: 0}
    id,
  } = dynamic || {}
  const {
    activeServiceId,
    bastidorNum,
    frameNo,
    imei,
    model,
    plate,
    sharingOperatorId,
    login,
    userId,
    name,
    revision,
    manufactureDate,
    color,
    bluetoothMAC,
  } = scooter || {}

  return (
    <Grid container spacing={3} justify="center">
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.vehicule.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.vehicule.id')}
                value={id}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.vehicule.user')}
                value={userId}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.vehicule.name')}
                value={name}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.vehicule.model')}
                value={model}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.vehicule.revision')}
                value={revision}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.dateFab')}
                value={moment(manufactureDate).format('DD/MM/YYYY')}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.matricula')}
                value={plate}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.color')}
                value={color}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.codVin')}
                value={frameNo}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.astra.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.codEmei')}
                value={imei}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.model')}
                value={model}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.version')}
                value={login ? login.hardwareVersion : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.dirMAC')}
                value={bluetoothMAC}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.idSIM')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.fr_version')}
                value={login ? login.firmwareVersion : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.protocolo')}
                value={login ? login.protocol : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5} className={classes.endRow}>
              <TextField
                label={t('scooters.details.spec.page.astra.lastRecept')}
                value={
                  gpsData
                    ? moment(gpsData.lastFixTimestamp).format('DD/MM/YYYY h:m')
                    : 'undefined'
                }
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.lastReception.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.lat')}
                value={gpsData ? gpsData.latitude : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.long')}
                value={gpsData ? gpsData.longitude : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.speed')}
                value={gpsData ? gpsData.currentSpeed : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.speed_max')}
                value={gpsData ? gpsData.maxSpeed : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.rumb')}
                value={gpsData ? gpsData.heading : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.alt')}
                value={gpsData ? gpsData.altitude : 'undefined'}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.total_long')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.total_km')}
                value={scutum && scutum.totalOdometer}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.mode')}
                value={scutum && scutum.drivingMode}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.temp_eng')}
                value={scutum && scutum.motorTemperature}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.temp_inv')}
                value={scutum && scutum.inverterTemperature}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.temp_amb')}
                value={scutum && scutum.ambientTemperature}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.flag_od')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.flag_carg')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.flag_reg')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.flag_desc')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.lastReception.warnings')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.battery.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.id_bat')}
                value={scutum && scutum.batteryPackId}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.level_bat')}
                value={scutum && `${scutum.batterySoc}%`}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.temp_max')}
                value={scutum && scutum.batteryMaxTemperature}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.state_carg')}
                value={scutum && scutum.status}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.volt')}
                value={scutum && scutum.batteryVoltage}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.ten')}
                //value={ || ''}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t('scooters.details.spec.page.battery.corriente')}
                value={scutum && scutum.batteryCurrentDrain}
                // focused
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SpecificationScooterView
