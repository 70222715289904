import { Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { clamp, toUint } from '../../helpers/numberUtils'
import { scooterService } from '../../services/scooterService'
import CustomMaterialTable from '../shared/customMaterialTable'
import useStyles from './styles'

const StatisticsView = () => {
  const [isLoading, setIsLoading] = useState()
  const [vehicleStatistics, setVehicleStatistics] = useState()
  const [antiquityCheckDays, setAntiquityCheckDays] = useState(20)

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    handleVehicleStatisticsSearch()
  }, [])

  function handleVehicleStatisticsSearch() {
    setIsLoading(true)
    scooterService
      .getStatistics(antiquityCheckDays)
      .then(setVehicleStatistics)
      .finally(() => setIsLoading(false))
  }

  return isLoading ? (
    <div className={classes.progressBarContainer}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <Grid
        direction="row"
        container
        spacing={1}
        alignItems={'flex-end'}
        style={{ padding: 16 }}
      >
        <Grid item>
          <TextField
            label={t('statistics.page.filters.fields.antiquityDays')}
            value={antiquityCheckDays}
            type={'number'}
            onChange={(e) => {
              let value = e.target.value
              if (value !== '') {
                if (isNaN(value)) return
                value = clamp(toUint(value), 1, Number.POSITIVE_INFINITY)
              }
              setAntiquityCheckDays(value)
            }}
            onBlur={(e) => {
              if (e.target.value === '') setAntiquityCheckDays(1)
            }}
          />
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handleVehicleStatisticsSearch}
          >
            {t('statistics.page.filters.actions.search')}
          </Button>
        </Grid>
      </Grid>

      <CustomMaterialTable
        saveFilters={true}
        selection={false}
        filtering={false}
        padding={'expand'}
        title={t('statistics.page.title')}
        columns={[
          {
            title: t('statistics.page.columns.year'),
            field: 'manufactureYear',
            tooltip: t('statistics.page.tooltips.year'),
          },
          {
            title: t('statistics.page.columns.month'),
            field: 'manufactureMonth',
            tooltip: t('statistics.page.tooltips.month'),
          },
          {
            title: t('statistics.page.columns.manufacturedVehicles'),
            field: 'manufacturedVehicles',
            tooltip: t('statistics.page.tooltips.manufacturedVehicles'),
          },
          {
            title: t('statistics.page.columns.manufacturedVehiclesAccumulated'),
            field: 'manufacturedVehiclesAccumulated',
            tooltip: t(
              'statistics.page.tooltips.manufacturedVehiclesAccumulated',
            ),
          },
          {
            title: t('statistics.page.columns.connectedVehicles'),
            field: 'connectedVehicles',
            tooltip: t('statistics.page.tooltips.connectedVehicles'),
          },
          {
            title: t('statistics.page.columns.connectedVehiclesAccumulated'),
            field: 'connectedVehiclesAccumulated',
            tooltip: t('statistics.page.tooltips.connectedVehiclesAccumulated'),
          },
          {
            title: t('statistics.page.columns.disconnectedVehicles'),
            field: 'disconnectedVehicles',
            tooltip: t('statistics.page.tooltips.disconnectedVehicles'),
          },
          {
            title: t('statistics.page.columns.disconnectedVehiclesAccumulated'),
            field: 'disconnectedVehiclesAccumulated',
            tooltip: t(
              'statistics.page.tooltips.disconnectedVehiclesAccumulated',
            ),
          },
        ]}
        data={vehicleStatistics}
        options={{
          pageSize: 10,
          rowStyle: {
            padding: '0% !important',
          },
        }}
        localization={{
          toolbar: {
            nRowsSelected: '{0} Fila(s) Seleccionada(s)',
          },
        }}
      />
    </>
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer, customerReducer, oemReducer } = state
  return { scooterReducer, operatorReducer, customerReducer, oemReducer }
}

const actionCreators = {}

export default connect(mapState, actionCreators)(StatisticsView)
