import { dynamicConstants } from '../../constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
}

export function dynamicsReducer(state = initialState, action) {
  switch (action.type) {
    case dynamicConstants.GET_UNIFIED_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case dynamicConstants.GET_UNIFIED_SUCCESS:
      return {
        ...state,
        showResults: false,
        loadingResults: false,
        results: action.results,
      }
    case dynamicConstants.GET_UNIFIED_FAILURE:
      return {
        ...state,
        showResults: false,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
