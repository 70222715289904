import React from 'react'
import Dashboard from '../dashboard'
import UserView from './page'

class UserList extends React.Component {
  render() {
    return <Dashboard component={<UserView />} />
  }
}

export default UserList
