import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const operatorService = {
  getAll,
  insert,
  updateRestricted,
  update,
  insertOperatorUsers,
  validateOperator,
  getAllToValidate,
  getAllPrivateOperators,
  getallValidatedOperators,
  getAllDiscounts,
  createDiscount,
  updateDiscount,
  getAffectedCustomersByDiscount,
  getDiscountsByCustomerId,
}

function getDiscountsByCustomerId(customerId) {
  return handleCall(
    `/vehicles/v1/Discounts/GetByCustomer?customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //     method: 'GET',
  //     headers: authHeader(),
  //     redirect: 'follow',
  // };

  // return fetch(`${stringConstants.URL_ApiGateway}/vehicles/v1/Discounts/GetByCustomer?customerId=${customerId}`, requestOptions)
  // .then(handleResponse)
}

function updateDiscount(body) {
  const discountId = body.discountId
  delete body.discountId

  return handleCall(`/vehicles/v1/Discounts/${discountId}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  //   var requestOptions = {
  //     method: "PUT",
  //     headers: authHeader(),
  //     redirect: "follow",
  //     body: JSON.stringify(body),
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Discounts/${discountId}/Update`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getAffectedCustomersByDiscount(discountId) {
  return handleCall(
    `/vehicles/v1/Discounts/${discountId}/GetAffectedCustomers`,
    {
      method: 'GET',
      body: null,
    },
  )
  //   var requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Discounts/${discountId}/GetAffectedCustomers`,
  //     requestOptions
  //   ).then(handleResponse);
}

function createDiscount(body) {
  return handleCall(`/vehicles/v1/Discounts/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  //   var requestOptions = {
  //     method: "POSt",
  //     headers: authHeader(),
  //     redirect: "follow",
  //     body: JSON.stringify(body),
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Discounts/New`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getAllDiscounts(operatorId) {
  const filter = operatorId
    ? '?field=SharingOperatorId&value=' + operatorId
    : ''
  return handleCall(`/vehicles/v1/Discounts${filter}`, {
    method: 'GET',
    body: null,
  })
  //   var requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //     redirect: "follow",
  //   };

  //   const filter = operatorId
  //     ? "?field=SharingOperatorId&value=" + operatorId
  //     : "";
  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Discounts${filter}`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getallValidatedOperators() {
  return handleCall(`/vehicles/v1/operators?field=CanOperate&value=true`, {
    method: 'GET',
    body: null,
  })
  //   var requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/operators?field=CanOperate&value=true`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getAllPrivateOperators() {
  return handleCall(`/vehicles/v1/Operators?field=IsPrivate&value=true`, {
    method: 'GET',
    body: null,
  })
  //   var requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators?field=IsPrivate&value=true`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getAllToValidate() {
  return handleCall(`/vehicles/v1/Operators?field=CanOperate&value=false`, {
    method: 'GET',
    body: null,
  })
  //   var requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators?field=CanOperate&value=false`,
  //     requestOptions
  //   ).then(handleResponse);
}

function validateOperator(body) {
  return handleCall(
    `/vehicles/v1/Operators/${body.sharingOperatorId}/Validate`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  //   var requestOptions = {
  //     method: "PUT",
  //     headers: authHeader(),
  //     redirect: "follow",
  //     body: JSON.stringify(body),
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators/${body.sharingOperatorId}/Validate`,
  //     requestOptions
  //   ).then(handleResponse);
}

function getAll(operatorId) {
  const filter = operatorId ? `/${operatorId}` : ''
  return handleCall(`/vehicles/v1/Operators${filter}`, {
    method: 'GET',
    body: null,
  })
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };

  //   const filter = operatorId ? `/${operatorId}` : "";
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/vehicles/v1/Operators" + filter,
  //     requestOptions
  //   ).then(handleResponse);
}

function insert(body) {
  return handleCall(`/vehicles/v1/Operators/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  //   var requestOptions = {
  //     method: "POST",
  //     headers: authHeader(),
  //     body: JSON.stringify(body),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators/New`,
  //     requestOptions
  //   ).then(handleResponse);
}
function insertOperatorUsers(body) {
  return handleCall(`/vehicles/v1/OperatorUsers/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  //   var requestOptions = {
  //     method: "POST",
  //     headers: authHeader(),
  //     body: JSON.stringify(body),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/OperatorUsers/New`,
  //     requestOptions
  //   ).then(handleResponse);
}

function update(body) {
  const operatorId = body.id
  delete body.id

  return handleCall(`/vehicles/v1/Operators/${operatorId}/update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  //   var requestOptions = {
  //     method: "PUT",
  //     headers: authHeader(),
  //     body: JSON.stringify(body),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators/${operatorId}/update`,
  //     requestOptions
  //   ).then(handleResponse);
}

function updateRestricted(body) {
  const operatorId = body.id

  delete body.id

  return handleCall(`/vehicles/v1/Operators/${operatorId}/UpdateRestricted`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  //   let requestOptions = {
  //     method: "PUT",
  //     headers: authHeader(),
  //     body: JSON.stringify(body),
  //     redirect: "follow",
  //   };

  //   return fetch(
  //     `${stringConstants.URL_ApiGateway}/vehicles/v1/Operators/${operatorId}/UpdateRestricted`,
  //     requestOptions
  //   ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
