import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import i18next from 'i18next'
import React from 'react'
import { WEB_VERSION } from '../../../constants/global_constants'
import useStyles from './styles'

const AboutDialog = ({ open, onClose }) => {
  const handleClose = () => onClose()
  const styles = useStyles()

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            style={{ alignSelf: 'center', marginBottom: 20 }}
            src={process.env.PUBLIC_URL + '/content/img/altech_logo.png'}
            width={250}
          />
          <DialogContentText className={styles.text}>
            Silence Management
          </DialogContentText>
          <DialogContentText className={styles.text}>
            v{WEB_VERSION}
          </DialogContentText>
          <DialogContentText className={styles.copyrightText}>
            Copyright 2010-2023 Altech.{' '}
            {i18next.t('dashboard.about.allRightsReserved')}
          </DialogContentText>
          <a className={styles.websiteText} href="https://www.altech.es">
            www.altech.es
          </a>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            variant="contained"
            color="primary"
            className={styles.acceptButton}
            onClick={handleClose}
          >
            {i18next.t('dashboard.about.accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AboutDialog
