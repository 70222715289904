import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  results: {
    ' & .MuiTableHead-root .MuiIconButton-label': {
      display: 'none',
    },
    '& h2': {
      padding: 18,
    },
  },
}))

export default useStyles
