import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  buttonGroup: {
    marginTop: '15%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}))

const ModalConfirmAction = ({
  headerText,
  descriptionText,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }
  const handleAccept = () => {
    onConfirm()
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 id="simple-modal-title">{headerText}</h2>
            <p id="simple-modal-description"> {descriptionText}</p>
            <div className={classes.buttonGroup}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                {t('ui.modalConfirmAction.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept}
              >
                {t('ui.modalConfirmAction.confirm')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ModalConfirmAction
