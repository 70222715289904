import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    ' & .MuiFormControl-root': {
      width: '100%',
    },
  },
  divContainer: {
    flexGrow: 1,
  },
  select: {
    width: '25ch',
  },
  autocomplete: {
    width: '20ch',
  },
  title: {
    padding: 25,
  },
  saveButton: {
    margin: 20,
  },
}))

export default useStyles
