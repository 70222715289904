import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-root': {
      width: '100%',
    },
  },
}))

export default useStyles
