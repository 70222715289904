import i18next from 'i18next'
import { notificationsConstants } from '../../constants/notifications_constants'
import { notificationsService } from '../../services/notificationsServices'
import { alertActions } from './alert_actions'

export const notificationsActions = {
  getAll,
  getById,
  insert,
  update,
  deleteNotification,
}
function getById(id) {
  return (dispatch) => {
    if (id) {
      dispatch(request())
      notificationsService.getById(id).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(
            alertActions.error(
              i18next.t('services.notifications.error.getAll'),
            ),
          )
        },
      )
    }
  }
  function request() {
    return { type: notificationsConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return {
      type: notificationsConstants.GET_BY_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: notificationsConstants.GET_BY_ID_FAILURE,
      error,
    }
  }
}

function update(body) {
  return (dispatch) => {
    notificationsService.update(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.notifications.success.update'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.error.update')),
        )
      },
    )
  }
}

function deleteNotification(body) {
  return (dispatch) => {
    notificationsService.deleteNotification(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.notifications.success.delete'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.error.delete')),
        )
      },
    )
  }
}

function insert(body) {
  return (dispatch) => {
    notificationsService.insert(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.notifications.success.insert'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.error.insert')),
        )
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    notificationsService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.error.getAll')),
        )
      },
    )
  }

  function request() {
    return { type: notificationsConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return {
      type: notificationsConstants.GET_ALL_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: notificationsConstants.GET_ALL_FAILURE,
      error,
    }
  }
}
