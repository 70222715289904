import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { validations } from '../../../../helpers/validation'
import Spinner from '../../../shared/Spinner'
import FooterFormButtons from '../../../shared/footerFormButtons'
import TargetsFotaSelection from '../targets'
import useStyles from './styles'

class FotaFormView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      targetType: '',
      targetModel: '',
      skipCustomerConfirmation: false,
      silSelected: '',
      sils: props.sils,
      silsFiltered: [],
      startDate: '',
      endDate: '',
      upgrades: props.upgrades,
      oldVersion: {
        tcu: {
          selected: '',
          upgrades: [],
        },
        ecu: {
          selected: '',
          upgrades: [],
        },
        bms: {
          selected: '',
          upgrades: [],
        },
        mcu: {
          selected: '',
          upgrades: [],
        },
      },
      errors: {
        targetType: { result: false, message: '' },
        targetModel: { result: false, message: '' },
        silSelected: { result: false, message: '' },
        startDate: { result: false, message: '' },
        endDate: { result: false, message: '' },
        skipCustomerConfirmation: { result: true, message: '' },
        oldVersion: {
          ecu: {
            result: false,
            message: '',
          },
          tcu: {
            result: false,
            message: '',
          },
          bms: {
            result: false,
            message: '',
          },
          mcu: {
            result: false,
            message: '',
          },
        },
      },
      targetModels: [],
    }
    this.onClickRefreshSelects = this.onClickRefreshSelects.bind(this)
    this.setSils = this.setSils.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSil = this.handleChangeSil.bind(this)
    this.handleChangeOldVersion = this.handleChangeOldVersion.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.startSubmit = this.startSubmit.bind(this)
  }

  handleCloseModal() {
    this.setState({
      openModal: false,
    })
  }

  onClickRefreshSelects(event) {
    let { name, value } = event.target
    let errors = this.state.errors
    name = name == null ? 'targetModel' : name
    errors[name].message = ''
    let targetModels = this.state.targetModels
    const firmwares = this.props.firmwares
    // refresh models about target type
    if (name === 'targetType') {
      targetModels = [
        ...new Set(
          firmwares
            .filter((x) => x.targetType === value)
            .map((x) => x.targetModel),
        ),
      ]
    }
    this.setState({ [name]: value, targetModels }, this.setSils)
  }

  onChangeAutocompleteTarget = (event, newValue) => {
    this.setState({
      targetModel: newValue,
    })
    this.onClickRefreshSelects(event)
  }

  handleChange(event) {
    let { name, value } = event.target
    let errors = this.state.errors
    errors[name].message = ''
    if (event.target.type === 'checkbox') {
      this.setState({ [name]: event.target.checked })
    } else {
      this.setState({ [name]: value })
    }
  }

  setSils() {
    let filter = this.state.sils.filter((obj) => {
      return (
        obj.targetType === this.state.targetType &&
        obj.targetModel === this.state.targetModel
      )
    })
    let newOldVersion = this.state.oldVersion
    newOldVersion.bms.selected = ''
    newOldVersion.bms.upgrades = []
    newOldVersion.tcu.selected = ''
    newOldVersion.tcu.upgrades = []
    newOldVersion.ecu.selected = ''
    newOldVersion.ecu.upgrades = []
    newOldVersion.mcu.selected = ''
    newOldVersion.mcu.upgrades = []
    this.setState({
      silsFiltered: filter,
      oldVersion: newOldVersion,
    })
  }

  handleChangeSil(event) {
    const { name, value } = event.target
    let newOldVersion = this.state.oldVersion
    let firmwares = Object.values(value.firmwares)
    let errors = this.state.errors
    errors[name].message = ''
    let upgradesFiltered = this.state.upgrades.filter((obj) => {
      return (
        obj.targetType === this.state.targetType &&
        obj.targetModel === this.state.targetModel
      )
    })
    var upgrades = [],
      upgrades_ecu = [],
      upgrades_tcu = [],
      upgrades_bms = [],
      upgrades_mcu = []
    upgradesFiltered.forEach((upgrade) => {
      firmwares.forEach((firmw) => {
        if (
          firmw.hardwareType === upgrade.hardwareType &&
          firmw.version === upgrade.newVersion
        ) {
          upgrades.push(upgrade)
        }
      })
    })
    upgrades_ecu = upgrades.filter(
      (x) => x.hardwareType === 'ECU ' + this.state.targetType,
    )
    upgrades_tcu = upgrades.filter(
      (x) => x.hardwareType === 'TCU ' + this.state.targetType,
    )
    upgrades_bms = upgrades.filter((x) => x.hardwareType.includes('BMS'))
    upgrades_mcu = upgrades.filter((x) => x.hardwareType.includes('MCU'))
    newOldVersion.bms.upgrades = upgrades_bms
    newOldVersion.ecu.upgrades = upgrades_ecu
    newOldVersion.tcu.upgrades = upgrades_tcu
    newOldVersion.mcu.upgrades = upgrades_mcu
    this.setState({
      [name]: value,
      oldVersion: newOldVersion,
    })
  }

  validateForm() {
    let errors = this.state.errors
    errors.targetType = validations.required(this.state.targetType)
    errors.targetModel = validations.required(this.state.targetModel)
    errors.silSelected = validations.required(this.state.silSelected)
    errors.startDate = validations.required(
      this.state.startDate,
      validations.compareDate(this.state.startDate, this.state.endDate),
    )
    errors.endDate = validations.required(
      this.state.endDate,
      validations.compareDate(this.state.startDate, this.state.endDate),
    )
    let oldVersion_values = [
      this.state.oldVersion.tcu.selected,
      this.state.oldVersion.ecu.selected,
      this.state.oldVersion.bms.selected,
      this.state.oldVersion.mcu.selected,
    ]
    errors.oldVersion.ecu = validations.alMostOne(oldVersion_values)
    errors.oldVersion.bms = validations.alMostOne(oldVersion_values)
    errors.oldVersion.tcu = validations.alMostOne(oldVersion_values)
    errors.oldVersion.mcu = validations.alMostOne(oldVersion_values)
    this.setState({ errors })
  }

  handleChangeOldVersion(event) {
    const { name, value } = event.target
    let new_oldVersion = this.state.oldVersion
    new_oldVersion[name].selected = value
    this.setState({
      oldVersion: { ...this.state.oldVersion, new_oldVersion },
    })
  }

  isValidForm(errors) {
    let valid = true
    var objectKeys = Object.keys(errors)
    Object.values(objectKeys).forEach(
      // if we have an error string set valid to false
      (val) => {
        if (val === 'oldVersion') {
          Object.values(errors[val]).forEach((key) => {
            valid = key.result === false ? false : valid
          })
        } else {
          valid = errors[val].result === false ? false : valid
        }
      },
    )
    return valid
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.validateForm()
    if (this.isValidForm(this.state.errors)) {
      delete this.state.oldVersion.new_oldVersion
      const filtered = Object.values(this.state.oldVersion).filter(
        (value) => value.selected !== '',
      )
      const oldVersions = []
      Object.values(filtered).forEach((item) => {
        oldVersions.push(item.selected)
      })
      this.props.getScooters()
      if (!this.props.scooterReducer.loadingResults) {
        this.setState({ openModal: true })
      }
    }
  }

  startSubmit() {
    this.setState({ submitted: true })
  }

  render() {
    const classes = this.props.styles
    const { scooterReducer, t } = this.props
    return (
      <React.Fragment>
        <Spinner loading={this.props.fotaReducer.loadingResults} />
        <h2 className={classes.title}>{this.props.title}</h2>
        <div className={classes.divContainer}>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid className={classes.root} container spacing={3}>
              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2} xs={6} sm={3}>
                <FormControl>
                  <InputLabel shrink id="targetTypeLabel">
                    {t('fota.fota.form.page.target_type')}
                  </InputLabel>
                  <Select
                    labelId="targetTypeLabel"
                    id="targetType"
                    name="targetType"
                    value={this.state.targetType || ''}
                    onChange={this.onClickRefreshSelects}
                    error={
                      this.state.errors.targetType.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.props.targetTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.targetType.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6} sm={3}>
                <FormControl>
                  <Autocomplete
                    value={this.state.targetModel || ''}
                    id="targetModelAutocomplete"
                    onSelect={this.onChangeAutocompleteTarget}
                    getOptionLabel={this.getOptionLabel}
                    renderOption={(option) => {
                      return option
                    }}
                    name="targetModel"
                    options={this.state.targetModels}
                    disabled={
                      this.props.readOnly || this.state.targetType === ''
                        ? true
                        : false
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fota.fota.form.page.target_model')}
                        helperText={this.state.errors.targetModel.message}
                        name="targetModel"
                        error={
                          this.state.errors.targetModel.message.length === 0
                            ? false
                            : true
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={6} sm={3}>
                <FormControl>
                  <TextField
                    label={t('fota.fota.form.page.start_process')}
                    value={this.state.startDate || ''}
                    name="startDate"
                    onChange={this.handleChange}
                    error={
                      this.state.errors.startDate.message.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errors.startDate.message}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: this.props.readOnly,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={6} sm={3}>
                <FormControl>
                  <TextField
                    label={t('fota.fota.form.page.expire_process')}
                    value={this.state.endDate || ''}
                    name="endDate"
                    onChange={this.handleChange}
                    error={
                      this.state.errors.endDate.message.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errors.endDate.message}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: this.props.readOnly,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={2}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={7} sm={10} xs={12}>
                <FormControl>
                  <InputLabel shrink id="sil_label">
                    {t('fota.fota.form.page.new_sil')}
                  </InputLabel>
                  <Select
                    labelId="sil_label"
                    id="sil"
                    name="silSelected"
                    value={this.state.silSelected}
                    onChange={this.handleChangeSil}
                    error={
                      this.state.errors.silSelected.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.state.silsFiltered.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.fileName +
                          ' - ' +
                          item.containedFirmwaresVersions +
                          ' - ' +
                          item.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.silSelected.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <FormControl>
                  <FormLabel>
                    {t('fota.fota.form.page.skipConfirmation_title')}
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.skipCustomerConfirmation}
                        onChange={this.handleChange}
                        name="skipCustomerConfirmation"
                      />
                    }
                    label={t('fota.fota.form.page.skipConfirmation')}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item md={2} sm={2} implementation="css" smDown component={Hidden} />
               */}
              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2} xs={6} sm={4}>
                <FormControl>
                  <InputLabel shrink id="oldVersion_tcu_label">
                    {t('fota.fota.form.page.oldVersion_tcu')}
                  </InputLabel>
                  <Select
                    labelId="oldVersion_tcu_label"
                    id="oldVersion_tcu"
                    name="tcu"
                    value={this.state.oldVersion.tcu.selected}
                    onChange={this.handleChangeOldVersion}
                    error={
                      this.state.errors.oldVersion.tcu.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.state.oldVersion.tcu.upgrades.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.hardwareModel + ' ' + item.oldVersion}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.oldVersion.tcu.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <FormControl>
                  <InputLabel shrink id="oldVersion_ecu_label">
                    {t('fota.fota.form.page.oldVersion_ecu')}
                  </InputLabel>
                  <Select
                    labelId="oldVersion_ecu_label"
                    id="oldVersion_ecu"
                    name="ecu"
                    value={this.state.oldVersion.ecu.selected}
                    onChange={this.handleChangeOldVersion}
                    error={
                      this.state.errors.oldVersion.ecu.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.state.oldVersion.ecu.upgrades.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.hardwareModel + ' ' + item.oldVersion}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.oldVersion.ecu.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid
                item
                md={7}
                sm={4}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2} xs={6} sm={4}>
                <FormControl>
                  <InputLabel shrink id="oldVersion_bms_label">
                    {t('fota.fota.form.page.oldVersion_bms')}
                  </InputLabel>
                  <Select
                    labelId="oldVersion_bms_label"
                    id="oldVersion_bms"
                    name="bms"
                    value={this.state.oldVersion.bms.selected}
                    onChange={this.handleChangeOldVersion}
                    error={
                      this.state.errors.oldVersion.bms.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.state.oldVersion.bms.upgrades.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.hardwareModel + ' ' + item.oldVersion}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.oldVersion.bms.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <FormControl>
                  <InputLabel shrink id="oldVersion_mcu_label">
                    {t('fota.fota.form.page.oldVersion_mcu')}
                  </InputLabel>
                  <Select
                    labelId="oldVersion_mcu_label"
                    id="oldVersion_mcu"
                    name="mcu"
                    disabled={
                      this.state.targetType === 'SWAP STATION' ? false : true
                    }
                    value={this.state.oldVersion.mcu.selected}
                    onChange={this.handleChangeOldVersion}
                    error={
                      this.state.errors.oldVersion.mcu.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.state.oldVersion.mcu.upgrades.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.hardwareModel + ' ' + item.oldVersion}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.oldVersion.mcu.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid
                item
                md={7}
                sm={4}
                implementation="css"
                smDown
                component={Hidden}
              />
            </Grid>
            <Grid container justify="center" alignItems="center">
              <FooterFormButtons
                onClickLeftButton={this.props.onClickLeftButton}
                leftIcon={this.props.leftIcon}
                leftButtonValue={this.props.leftButtonValue}
                typeButton={this.props.typeButton}
                onClickRightButton={this.props.onClickRightButton}
                rightIcon={this.props.rightIcon}
                rightButtonValue={this.props.rightButtonValue}
                submitted={this.props.fotaReducer.loadingResults}
              />
            </Grid>
          </form>
        </div>
        <TargetsFotaSelection
          open={this.state.openModal}
          dates={this.state}
          startProcess={this.props.startProcess}
          handleClose={this.handleCloseModal}
          results={scooterReducer.results}
          loadingResults={scooterReducer.loadingResults}
          startSubmit={this.startSubmit}
          t={t}
        />
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const classes = useStyles()
  return <FotaFormView styles={classes} {...props} />
}

export default Hook
