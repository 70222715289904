import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import { notificationsActions } from '../../redux/actions/notifications_actions'

import { useTranslation } from 'react-i18next'

import { isManager } from '../../helpers/roles'

import CreateNotification from './createNotification'
import Targets from './targets'
import UpdateNotification from './updateNotification'

import CustomMaterialTable from '../shared/customMaterialTable'

import { formatDateToDisplay } from '../shared/formatDate'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const NotificationsManagement = ({
  isOpen,
  onClose,
  usersData,
  notificationsReducer,
  operatorReducer,
  getAllNotifications,
  insertNotification,
  updateNotification,
  getById,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [insertConfig, setinsertConfig] = useState(true)
  const [isCreateNotificationsOpen, setisCreateNotificationsOpen] =
    useState(false)
  const [isEditNotificationOpen, setisEditNotificationOpen] = useState(false)
  const [isTargetsDialogOpen, setisTargetsDialogOpen] = useState(false)
  const [targets, settargets] = useState([])
  const [intervalState, setintervalState] = useState()

  const handleEnter = () => {
    getAllNotifications(!isManager() && operatorReducer.dataOperatorLogged.id)
    const interval = setInterval(() => {
      getAllNotifications(!isManager() && operatorReducer.dataOperatorLogged.id)
    }, 30000) //30000   10000
    setintervalState(interval)
  }

  useEffect(() => {
    const intervalById = setInterval(() => {
      getById(targets?.id)
    }, 30000)

    return () => {
      clearInterval(intervalById)
    }
  }, [targets])

  const handleClose = () => {
    onClose()
    clearInterval(intervalState)
  }

  const handleCreateNotification = (body) => {
    if (insertConfig) {
      insertNotification(body)
    } else {
      const updatebody = {
        notificationId: targets.id,
        ...body,
      }

      updateNotification(updatebody)
    }
  }

  const handleUpdateNotifications = (params) => {}

  const handleAction = (rowData, action) => {
    switch (action) {
      case 'add':
        setinsertConfig(true)
        setisCreateNotificationsOpen(true)
        break

      case 'details':
        settargets(rowData)
        setisTargetsDialogOpen(true)
        getById(rowData.id)
        break

      case 'edit':
        setinsertConfig(false)
        settargets(rowData)
        setisCreateNotificationsOpen(true)
        // setisEditNotificationOpen(true);
        break

      default:
        break
    }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        onEnter={handleEnter}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('notifications.index.customertoolbarText')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <CustomMaterialTable
            saveFilters={true}
            tab={'notificationManager'}
            data={notificationsReducer.results} //targetType filtrar
            columns={[
              {
                title: t('notifications.index.table.columns.name'),
                field: 'name',
              },
              {
                title: t('notifications.index.table.columns.title'),
                field: 'title',
              },
              {
                title: t('notifications.index.table.columns.subTitle'),
                field: 'subTitle',
              },
              {
                title: t('notifications.index.table.columns.body'),
                field: 'body',
                cellStyle: {
                  maxWidth: 100,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },
              },
              {
                title: t('notifications.index.table.columns.startDate'),
                field: 'scheduledStartDate',
                render: (rowData) =>
                  formatDateToDisplay(rowData.scheduledStartDate, true),
              },
              {
                title: t('notifications.index.table.columns.endDate'),
                field: 'scheduledEndDate',
                render: (rowData) =>
                  formatDateToDisplay(rowData.scheduledEndDate, true),
              },
              {
                title: t('notifications.index.table.columns.isPending'),
                field: 'isPending',
              },
            ]}
            title={t('notifications.index.table.title')}
            actions={[
              {
                icon: 'add',
                tooltip: t('notifications.index.table.actions.add'),
                isFreeAction: true,
                onClick: (event) => handleAction(null, 'add'),
              },
              (rowData) => ({
                icon: 'dehaze',
                tooltip: t('notifications.index.table.actions.add'),
                onClick: (event) => handleAction(rowData, 'details'),
              }),
              (rowData) => ({
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event) => handleAction(rowData, 'edit'),
              }),
            ]}
          />
        </div>
      </Dialog>
      <Targets
        isOpen={isTargetsDialogOpen}
        onClose={() => setisTargetsDialogOpen(false)}
        targetData={notificationsReducer.notification}
      />
      <CreateNotification
        targets={targets}
        insertConfig={insertConfig}
        isOpen={isCreateNotificationsOpen}
        onClose={() => setisCreateNotificationsOpen(false)}
        onConfirm={handleCreateNotification}
        targetData={usersData}
      />
      <UpdateNotification
        isOpen={isEditNotificationOpen}
        onClose={() => setisEditNotificationOpen(false)}
        onConfirm={handleUpdateNotifications}
        targetData={usersData}
        notificationData={targets}
      />
    </div>
  )
}

function mapState(state) {
  const { notificationsReducer, operatorReducer } = state
  return { notificationsReducer, operatorReducer }
}

const actionCreators = {
  getAllNotifications: notificationsActions.getAll,
  getById: notificationsActions.getById,
  insertNotification: notificationsActions.insert,
  updateNotification: notificationsActions.update,
}

export default connect(mapState, actionCreators)(NotificationsManagement)
