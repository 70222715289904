import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import CustomMaterialTable from '../../shared/customMaterialTable'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CommandQueue = ({ isOpen, onClose, scooterReducer }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }
  //commandSended
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        {/* <DialogTitle i>{"Comandos enviados"}</DialogTitle> */}
        <DialogContent>
          <CustomMaterialTable
            // saveFilters={true}
            title={t('scooters.details.command.queue.title')}
            columns={[
              {
                title: t('scooters.details.command.queue.columns.state'),
                field: 'status',
              },
              {
                title: t('scooters.details.command.queue.columns.command'),
                field: 'command.command',
              },
              {
                title: t('scooters.details.command.queue.columns.msg'),
                render: (rowData) =>
                  rowData.status === 'success'
                    ? rowData.msgSuccess
                    : rowData.command.args && rowData.command.args[0],
              },
              {
                title: t('scooters.details.command.queue.columns.msgTime'),
                render: (rowData) =>
                  rowData.command.args && rowData.command.args[1] + ' minutos',
              },
              {
                title: t('scooters.details.command.queue.columns.imei'),
                field: 'imei',
              },
              {
                title: t('scooters.details.command.queue.columns.error'),
                field: 'error',
              },
              {
                title: t('scooters.details.command.queue.columns.date'),
                field: 'date',
              },
            ]}
            data={scooterReducer.commandSended.reverse()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('scooters.details.command.queue.columns.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

export default connect(mapState, null)(CommandQueue)
