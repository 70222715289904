import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { customerActions } from '../../../redux/actions/customers_actions'
import { oemActions } from '../../../redux/actions/oem_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import Dashboard from '../../dashboard/index'
import ScootersLocationView from './page'

// import DetailsOperator from '../../operator/details'

const ScootersLocation = ({
  getAllDynamics,
  operatorReducer,
  scooterReducer,
  getId,
  getAllOperators,
  getAllCustomers,
  getAllOems,
  getImage,
  saveCenterMap,
}) => {
  const [intervalState, setintervalState] = useState()
  useEffect(() => {
    fetchGroup()
    const interval = setInterval(() => {
      fetchGroup()
    }, 60000) //5000 60000 120000
    setintervalState(interval)

    const colors = ['black', 'white', 'green', 'gray', 'caqui']
    colors.map((c) => {
      getImage({ model: 'S01', color: c })
      getImage({ model: 'S02', color: c })
    })
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleGetIdScooter = (scooterId) => {
    getId(scooterId)
    clearInterval(intervalState)
  }

  const fetchGroup = () => {
    getAllDynamics()
    // getAllOperators();
    // getAllCustomers();
    // getAllOems();
  }
  return (
    <Fragment>
      <Dashboard
        component={
          // !scooterReducer.loadingResults ? (
          <ScootersLocationView
            operatorData={operatorReducer.results}
            scooterReducer={scooterReducer}
            ongetId={handleGetIdScooter}
            saveCenterMap={saveCenterMap}
          />
          // ) : (
          //   <Spinner loading={scooterReducer.loadingResults} />
          // )
        }
      />
      {/* {operatorSelected && 
                <DetailsOperator
                    isOpen={showOperatorDetails}
                    onClose={()=>setshowOperatorDetails(false)}
                    data={operatorSelected}
                />
            } */}
    </Fragment>
  )
}

function mapState(state) {
  const { operatorReducer, scooterReducer } = state
  return { operatorReducer, scooterReducer }
}

const actionCreators = {
  getAllDynamics: scooterActions.getAllDynamics,
  getAllDynamicByOperator: scooterActions.getAllDynamicByOperator,
  getId: scooterActions.getId,
  getAllOperators: operatorActions.getAll,
  getAllCustomers: customerActions.getAll,
  getAllOems: oemActions.getAll,
  getImage: scooterActions.getImage,
  saveCenterMap: scooterActions.saveCenterMap,
}

export default connect(mapState, actionCreators)(ScootersLocation)
