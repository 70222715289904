import { handleCall } from './handleResponse'

export const oemService = {
  insert,
  getAll,
  update,
}

function update(body) {
  const id = body.id
  delete body.id
  return handleCall(`/oems/v1/Oems/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getAll() {
  return handleCall(`/oems/v1/Oems`, {
    method: 'GET',
    body: null,
  })
}

function insert(body) {
  return handleCall(`/oems/v1/Oems/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}
