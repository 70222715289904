import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import { oemActions } from '../../../redux/actions/oem_actions'
import { scooterActions } from '../../../redux/actions/scooter_actions'

import ScooterListOEM from '../../OEM/dialog/scooterList'

import { useTranslation } from 'react-i18next'

import { WEB_VERSION } from '../../../constants/global_constants'
import CustomMaterialTable from '../../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexGrow: 1,
    // height: '100vh',
  },
  container: {
    maxWidth: '90%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    marginTop: '10%',
    borderRadius: '5px',
    boxShadow: '0 0 10px #d4d4d4',
    overflowY: 'scroll',
    padding: '2% 5%',
  },
  demo: {
    // overflowY: 'scroll',
    maxHeight: '200px',
  },
  headerCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1%',
  },
  spamOk: {
    color: 'green',
    cursor: 'pointer',
  },
  spamKo: {
    color: 'red',
    cursor: 'pointer',
  },
  percentSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '20%',
  },
  bottomText: {
    marginTop: '5%',
  },
}))

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />
}

const Dashboard = ({
  getAllScooters,
  getAllOEMs,
  customerReducer,
  scooterReducer,
  oemReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [scooterListWithDynamicData, setscooterListWithDynamicData] = useState(
    [],
  )
  const [scooterConnected, setscooterConnected] = useState(0)
  const [scooterNotConnected, setscooterNotConnected] = useState(0)
  const [scooterAssigned, setscooterAssigned] = useState(0)
  const [scooterNotAssigned, setscooterNotAssigned] = useState(0)
  const [selectedRow, setselectedRow] = useState({})
  const [isScooterListDialogOpen, setisScooterListDialogOpen] = useState(false)

  useEffect(() => {
    getAllOEMs()
    getAllScooters()
  }, [])

  const assignedScooterToOem = (rowData) => {
    return scooterReducer.results.filter((s) => s.oem?.oemOid === rowData.oid)
      .length
  }

  const handleActionShowScooterList = (rowData) => {
    setisScooterListDialogOpen(true)
    setselectedRow(rowData)
  }

  return (
    <main className={classes.mainContainer}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={4} lg={4}>
            <GenericCardPieChard
              headerText={t(
                'home.dashboard.cardPieChard.connectedScooter.headerText',
              )}
              data={[
                {
                  name: t(
                    'home.dashboard.cardPieChard.connectedScooter.legend.text1',
                  ),
                  value: scooterConnected,
                },
                {
                  name: t(
                    'home.dashboard.cardPieChard.connectedScooter.legend.text2',
                  ),
                  value: scooterNotConnected,
                },
              ]}
              colors={['#76a900', '#ff431f']}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <GenericCardPieChard
              headerText={t(
                'home.dashboard.cardPieChard.scooterFactory.headerText',
              )}
              data={[
                {
                  name: t(
                    'home.dashboard.cardPieChard.scooterFactory.legend.text1',
                  ),
                  value: scooterAssigned,
                },
                {
                  name: t(
                    'home.dashboard.cardPieChard.scooterFactory.legend.text2',
                  ),
                  value: scooterNotAssigned,
                },
              ]}
              colors={['#76a900', '#ff431f']}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <GenericCardPieChard
              headerText={t(
                'home.dashboard.cardPieChard.silenceUserConnected.headerText',
              )}
              data={[
                {
                  name: t(
                    'home.dashboard.cardPieChard.silenceUserConnected.legend.text1',
                  ),
                  value: customerReducer.results.length,
                },
                {
                  name: t(
                    'home.dashboard.cardPieChard.silenceUserConnected.legend.text2',
                  ),
                  value: 0,
                },
              ]}
              colors={['#76a900', '#ff431f']}
            />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <CustomMaterialTable
              saveFilters={true}
              title={t('home.dashboard.table.title')}
              columns={[
                {
                  title: t('home.dashboard.table.columns.name'),
                  field: 'name',
                },
                {
                  title: t('home.dashboard.table.columns.assignedScooters'),
                  field: 'assignedScooters',
                  render: (rowData) => assignedScooterToOem(rowData),
                },
                {
                  title:
                    t('home.dashboard.table.columns.operativeScooters') + ' %',
                  field: 'percent',
                  render: () => 0,
                  // render: (rowData) => scooterConnectedFromFleet(rowData),
                },
              ]}
              actions={[
                {
                  tooltip: 'Vehicle',
                  icon: 'directions_bike',
                  onClick: (evt, data) => handleActionShowScooterList(data),
                },
              ]}
              data={oemReducer.results}
            />
          </Grid>
        </Grid>
        <Box pt={4} className={classes.bottomText}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.silence.eco/">
              {t('home.dashboard.copyrightText')}
            </Link>{' '}
            {new Date().getFullYear()}
            {`. - v${WEB_VERSION}`}
          </Typography>
        </Box>
      </Container>
      <ScooterListOEM
        isOpen={isScooterListDialogOpen}
        onClose={() => setisScooterListDialogOpen(false)}
        data={selectedRow}
      />
    </main>
  )
}

function mapState(state) {
  const { scooterReducer, customerReducer, oemReducer } = state
  return { scooterReducer, customerReducer, oemReducer }
}

const actionCreators = {
  getAllOEMs: oemActions.getAll,
  getAllScooters: scooterActions.getAll,
}

export default connect(mapState, actionCreators)(Dashboard)
