import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'

import { formatDateToDisplay } from '../shared/formatDate'

const Targets = ({ isOpen, onClose, targetData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('notifications.index.targets.title')}
        </DialogTitle>
        <DialogContent>
          <CustomMaterialTable
            saveFilters={true}
            data={targetData.targets} //targetType filtrar
            columns={[
              {
                title: t('notifications.index.targets.email'),
                field: 'email',
              },
              {
                title: t('notifications.index.targets.attemptsCount'),
                field: 'attemptsCount',
              },
              {
                title: t('notifications.index.targets.lastAttemptTimestamp'),
                field: 'lastAttemptTimestamp',
                render: (rowData) =>
                  formatDateToDisplay(rowData.lastAttemptTimestamp, true),
              },
              {
                title: t('notifications.index.targets.lastAttemptError'),
                field: 'lastAttemptError',
              },
              {
                title: t('notifications.index.targets.status'),
                field: 'status',
              },
            ]}
            title={''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('notifications.createNotification.closeBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Targets
