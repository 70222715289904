import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MaterialTable from 'material-table'
import React from 'react'
import { factory } from '../../../../helpers/factory'
import { validations } from '../../../../helpers/validation'
import Spinner from '../../../shared/Spinner'
import FooterFormButtons from '../../../shared/footerFormButtons'
import useStyles from './styles'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

class FileFormView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targetType: '',
      targetModel: '',
      filePath: '',
      fileName: '',
      description: '',
      fileBinary: '',
      newVersions: '',
      firmwares: props.firmwares,
      errors: {
        targetType: { result: false, message: '' },
        targetModel: { result: false, message: '' },
        filePath: { result: false, message: '' },
        fileName: { result: false, message: '' },
        description: { result: true, message: '' },
      },
      targetModels: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChangeAutocompleteTarget = this.onChangeAutocompleteTarget.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
    this.onClickRefreshSelects = this.onClickRefreshSelects.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target
    let errors = this.state.errors
    errors[name].message = ''
    this.setState({ [name]: value })
  }

  onClickRefreshSelects(event) {
    let { name, value } = event.target
    let errors = this.state.errors
    name = name == null ? 'targetModel' : name
    errors[name].message = ''
    let targetModels = this.state.targetModels
    const firmwares = this.props.firmwares
    // refresh models about target type
    if (name === 'targetType') {
      targetModels = [
        ...new Set(
          firmwares
            .filter((x) => x.targetType === value)
            .map((x) => x.targetModel),
        ),
      ]
    }
    this.setState({ [name]: value, targetModels })
  }

  validateForm() {
    let errors = this.state.errors
    errors.targetType = validations.required(this.state.targetType)
    errors.targetModel = validations.required(this.state.targetModel)
    errors.filePath = validations.required(this.state.filePath)
    errors.fileName = validations.required(
      this.state.fileName,
      validations.checkExtensionFile(this.state.fileName, 'sil'),
    )
    this.setState({ errors })
  }

  async handleChangeFile() {
    const file = document.querySelector('#FileBinary').files[0]
    if (file != null) {
      this.setState({ fileName: file.name, fileBinary: await toBase64(file) })
      this.props.getFirmwaresVersions(await toBase64(file))
    }
  }

  isValidForm(errors) {
    let valid = true
    var objectKeys = Object.keys(errors)
    Object.values(objectKeys).forEach(
      // if we have an error string set valid to false
      (val) => {
        if (val === 'firmware') {
          Object.values(errors[val]).forEach((key) => {
            valid = key.result === false ? false : valid
          })
        } else {
          valid = errors[val].result === false ? false : valid
        }
      },
    )
    return valid
  }

  onChangeAutocompleteTarget = (event, newValue) => {
    this.setState({
      targetModel: newValue,
    })
    this.onClickRefreshSelects(event)
  }

  getOptionLabel = (option) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    return option
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.validateForm()
    if (this.isValidForm(this.state.errors)) {
      const firmwares = this.props.fileReducer.firmwaresVersions
      const file = factory.createFile(
        this.state.targetType,
        this.state.targetModel,
        firmwares,
        this.state.description,
        this.state.filePath,
        this.state.fileName,
        this.state.fileBinary,
      )
      this.props.onClickRightButton(file)
      this.setState({ submitted: true })
    }
  }

  render() {
    const classes = this.props.styles
    const { t } = this.props
    const translations = t('material_table.tableStrings', {
      returnObjects: true,
    })
    return (
      <React.Fragment>
        <Spinner loading={this.props.fileReducer.loadingResults} />
        <h2 className={classes.title}>{this.props.title}</h2>
        <div className={classes.divContainer}>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid className={classes.root} container spacing={3}>
              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <InputLabel shrink id="targetTypeLabel">
                    {t('fota.file.form.page.target_type')}
                  </InputLabel>
                  <Select
                    labelId="targetTypeLabel"
                    id="targetType"
                    name="targetType"
                    value={this.state.targetType || ''}
                    onChange={this.onClickRefreshSelects}
                    error={
                      this.state.errors.targetType.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{ readOnly: this.props.readOnly }}
                  >
                    {this.props.targetTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.targetType.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <Autocomplete
                    value={this.state.targetModel || ''}
                    id="targetModelAutocomplete"
                    onSelect={this.onChangeAutocompleteTarget}
                    getOptionLabel={this.getOptionLabel}
                    renderOption={(option) => {
                      return option
                    }}
                    name="targetModel"
                    options={this.state.targetModels}
                    disabled={
                      this.props.readOnly || this.state.targetType === ''
                        ? true
                        : false
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fota.file.form.page.target_model')}
                        helperText={this.state.errors.targetModel.message}
                        name="targetModel"
                        error={
                          this.state.errors.targetModel.message.length === 0
                            ? false
                            : true
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={3}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <TextField
                    label={t('fota.file.form.page.file_path')}
                    value={this.state.filePath || ''}
                    name="filePath"
                    onChange={this.handleChange}
                    error={
                      this.state.errors.filePath.message.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errors.filePath.message}
                    InputProps={{ readOnly: this.props.readOnly }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <Button
                    className={classes.buttonFile}
                    component="label"
                    disabled={this.props.readOnly ? true : false}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    <input
                      type="file"
                      name="FileBinary"
                      id="FileBinary"
                      onChange={this.handleChangeFile}
                      className={classes.inputFile}
                      accept=".sil"
                    />
                    {t('fota.file.form.page.upload_file')}
                  </Button>
                </FormControl>
              </Grid>
              <Grid
                item
                md={3}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <TextField
                    label={t('fota.file.form.page.file_name')}
                    value={this.state.fileName || ''}
                    name="fileName"
                    onChange={this.handleChange}
                    error={
                      this.state.errors.fileName.message.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errors.fileName.message}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={4} xs={7} sm={5}>
                <FormControl>
                  <TextField
                    label={t('fota.file.form.page.description')}
                    value={this.state.description || ''}
                    name="description"
                    onChange={this.handleChange}
                    InputProps={{ readOnly: this.props.readOnly }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={4}>
                <MaterialTable
                  localization={translations}
                  components={{
                    Toolbar: (props) => (
                      <React.Fragment>
                        <InputLabel>
                          {t('fota.file.detail.page.fws')}
                        </InputLabel>
                      </React.Fragment>
                    ),
                    Pagination: (props) => <React.Fragment />,
                  }}
                  options={{ padding: 'dense', pageSize: 2 }}
                  columns={[
                    {
                      title: t('fota.file.detail.page.columns.hw_type'),
                      field: 'hardwareType',
                    },
                    {
                      title: t('fota.file.detail.page.columns.version'),
                      field: 'version',
                    },
                  ]}
                  data={this.props.fileReducer.firmwaresVersions}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid container justify="center" alignItems="center">
                <FooterFormButtons
                  onClickLeftButton={this.props.onClickLeftButton}
                  leftIcon={this.props.leftIcon}
                  leftButtonValue={this.props.leftButtonValue}
                  typeButton={this.props.typeButton}
                  onClickRightButton={this.props.onClickRightButton}
                  rightIcon={this.props.rightIcon}
                  rightButtonValue={this.props.rightButtonValue}
                  submitted={this.props.fileReducer.loadingResults}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const classes = useStyles()
  return <FileFormView styles={classes} {...props} />
}

export default Hook
