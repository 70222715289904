import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const notificationsService = {
  getAll,
  insert,
  update,
  deleteNotification,
  getById,
}
function getById(id) {
  return handleCall(`/customers/v1/Notifications/${id}`, {
    method: 'GET',
    body: null,
  })
}

function getAll() {
  return handleCall(`/customers/v1/Notifications/GetAll`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Notifications/GetAll`,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(body) {
  return handleCall(`/customers/v1/Notifications/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Notifications/New`,
  //   requestOptions
  // ).then(handleResponse);
}

function update(body) {
  const notificationId = body.notificationId
  delete body.notificationId
  return handleCall(`/customers/v1/Notifications/${notificationId}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Notifications/${notificationId}/Update`,
  //   requestOptions
  // ).then(handleResponse);
}

function deleteNotification(body) {
  return handleCall(`/customers/v1/Users/DeleteByRole`, {
    method: 'DELETE',
    body: null,
  })
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Users/DeleteByRole`,
  //   requestOptions
  // ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
