import i18next from 'i18next'
import { oemConstants } from '../../constants/oem_constants'
import { oemService } from '../../services/oemServices'
import { alertActions } from './alert_actions'

export const oemActions = {
  insert,
  getAll,
  update,
}

function update(body) {
  return (dispatch) => {
    oemService.update(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(alertActions.success(i18next.t('services.oem.success.update')))
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.oem.error.update')))
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    oemService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: oemConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: oemConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: oemConstants.GET_ALL_FAILURE, error }
  }
}

function insert(body) {
  return (dispatch) => {
    dispatch(request())
    oemService.insert(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(getAll())
        dispatch(alertActions.success(i18next.t('services.oem.success.insert')))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.oem.error.insert')))
      },
    )
  }

  function request() {
    return { type: oemConstants.INSERT_REQUEST }
  }

  function success(results) {
    return { type: oemConstants.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: oemConstants.INSERT_FAILURE, error }
  }
}
