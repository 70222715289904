import stringConstants from '../constants/strings'
import { authHeader } from '../helpers/authHeader'
import { loginService } from './loginService'

export const handleCall = (
  endpoint,
  options,
  apiUrl = stringConstants.URL_ApiGateway,
) => {
  const { method, body } = options
  let requestOptions = {
    method,
    headers: authHeader(),
    redirect: 'follow',
    body,
  }

  body === 'GET' && delete requestOptions.body

  return fetch(`${apiUrl}${endpoint}`, requestOptions).then((response) => {
    if (response.status === 401) {
      return handleResponse(response, endpoint, options).then(
        () => {},
        () => handleCall(endpoint, options),
      )
    } else {
      return handleResponse(response, endpoint, options)
    }
  })
}

export const handleResponse = (response, endpoint, options) => {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }

    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          const { access_token, expires_in } = token
          loginService.setWithExpiry('mngmt_token', access_token, expires_in)
          // handleCall(endpoint, options);
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
