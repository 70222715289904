import { oemConstants } from '../../constants/oem_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
}

export function oemReducer(state = initialState, action) {
  switch (action.type) {
    case oemConstants.GET_ALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case oemConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        results: action.results,
      }
    case oemConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case oemConstants.INSERT_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case oemConstants.INSERT_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
      }
    case oemConstants.INSERT_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }

    default:
      return state
  }
}
