import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CancelIcon from '@material-ui/icons/Cancel'
import i18next from 'i18next'
import React from 'react'
import { useParams } from 'react-router-dom'
import { factory } from '../../../../helpers/factory'
import { history } from '../../../../helpers/history'
import AlertDialog from '../../../alert-dialog'
import MaterialTableExtend from '../../../material-table/index'
import useStyles from './styles'

class UpgradeCreateView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: {},
      select: false,
      openModal: false,
      details: '',
    }
    this.onSelectionChange = this.onSelectionChange.bind(this)
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.onClickOpenModal = this.onClickOpenModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
  }

  componentDidMount() {
    this.props.getFirmwaresCompatible(this.props.id)
  }

  onSelectionChange(rows) {
    if (rows.length === 1) {
      this.setState({ selectedItem: rows[0], select: true })
    }
    if (rows.length === 0) {
      this.setState({ selectedItem: {}, select: false })
    }
    if (rows.length > 1) {
      rows.forEach((element) => {
        if (element.id !== this.state.selectedItem.id) {
          element.tableData.checked = false
          element.tableData.disabled = true
        }
      })
      this.onSelectionChange(rows.splice(-1, 1))
    }
  }

  onClickOpenModal(data) {
    this.setState({
      openModal: true,
      item: data[0],
      details:
        '  ' +
        i18next.t('fota.upgrade.create.page.alert_dialog.initial_version') +
        this.props.firmwareReducer.firmware.version +
        ' ' +
        i18next.t('fota.upgrade.create.page.alert_dialog.final_version') +
        ' ' +
        data[0].version,
    })
  }

  onClickAcceptModal() {
    const firmwareNew = this.state.item
    const firmwareOld = this.props.firmwareReducer.firmware
    const upgrade = factory.createUpgrade(
      null,
      firmwareOld.targetType,
      firmwareOld.targetModel,
      firmwareOld.hardwareType,
      firmwareOld.hardwareModel,
      firmwareOld.version,
      firmwareNew.version,
    )
    console.log(JSON.stringify(upgrade))
    this.props.insert(upgrade)
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  onClickCancel() {
    history.goBack()
  }

  render() {
    const { firmwareReducer, t } = this.props
    const classes = this.props.styles
    const Wrapper = () => (
      <React.Fragment>
        <Tooltip title={t('fota.upgrade.create.page.wrapper.cancel')}>
          <IconButton onClick={this.onClickCancel}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {!firmwareReducer.loadingResults ? (
          <div className={classes.results}>
            <h2>{t('fota.upgrade.create.index.title')}</h2>
            <MaterialTableExtend
              buttons={<Wrapper />}
              title={t('fota.upgrade.create.page.title')}
              columns={[
                {
                  title: t('fota.upgrade.create.page.columns.target_type'),
                  field: 'targetType',
                },
                {
                  title: t('fota.upgrade.create.page.columns.target_model'),
                  field: 'targetModel',
                },
                { title: 'Id', field: 'id', hidden: true },
                {
                  title: t('fota.upgrade.create.page.columns.initial_frame'),
                  field: 'initialFrameNo',
                },
                {
                  title: t('fota.upgrade.create.page.columns.final_frame'),
                  field: 'finalFrameNo',
                },
                {
                  title: t('fota.upgrade.create.page.columns.hw_type'),
                  field: 'hardwareType',
                },
                {
                  title: t('fota.upgrade.create.page.columns.hw_model'),
                  field: 'hardwareModel',
                },
                {
                  title: t('fota.upgrade.create.page.columns.fw_version'),
                  field: 'version',
                },
                {
                  title: t('fota.upgrade.create.page.columns.responsable'),
                  field: 'responsible',
                },
              ]}
              data={firmwareReducer.results}
              actionsColumnIndex={10}
              selection={true}
              onSelectionChange={this.onSelectionChange}
              actions={[
                {
                  tooltip: t('fota.upgrade.create.page.actions.create'),
                  icon: 'check',
                  onClick: (evt, data) => this.onClickOpenModal(data),
                },
              ]}
            />
            <AlertDialog
              open={this.state.openModal}
              title={t('fota.upgrade.create.page.alert_dialog.title')}
              content={this.state.details}
              onClickCancel={this.onClickCancelModal}
              onClickAccept={this.onClickAcceptModal}
            />
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const { id } = useParams()
  const styles = useStyles()
  return <UpgradeCreateView id={id} styles={styles} {...props} />
}

export default Hook
