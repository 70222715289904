import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    paddingBottom: '5%',
  },
  endRow: {
    marginBottom: 15,
  },
  title: {
    padding: 25,
  },
  label: {
    paddingBottom: 5,
  },
  row: {},
}))

export default useStyles
