import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import { history } from '../../../../helpers/history'
import FirmwareForm from '../form/index'

class FirmwareEditView extends React.Component {
  constructor(props) {
    super(props)
    this.onClickSave = this.onClickSave.bind(this)
  }

  onClickComeBack() {
    history.goBack()
  }

  onClickSave(firmware) {
    if (firmware.type !== 'click') {
      console.log(JSON.stringify(firmware))
      this.props.update(firmware)
    }
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <FirmwareForm
          {...this.props}
          onClickLeftButton={this.onClickComeBack}
          onClickRightButton={this.onClickSave}
          leftIcon={<KeyboardBackspaceIcon />}
          rightIcon={<SaveIcon />}
          leftButtonValue={t('fota.firmware.edit.page.leftButtonValue')}
          rightButtonValue={t('fota.firmware.edit.page.rightButtonValue')}
          typeButton="submit"
        />
      </React.Fragment>
    )
  }
}

export default FirmwareEditView
