import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={6}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const ReportView = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t('reports.page.timeZone')} {...a11yProps(0)} />
          <Tab label={t('reports.page.usage')} {...a11yProps(1)} />
          <Tab label={t('reports.page.routes')} {...a11yProps(2)} />
          <Tab label={t('reports.page.reportGenerator')} {...a11yProps(3)} />
          <Tab label={t('reports.page.microSharing')} {...a11yProps(4)} />
          <Tab label={t('reports.page.userReport')} {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          Informe de actividad de la flota por franja horaria
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Informe de estadísticas de uso de scooters por áreas/operador
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Informes trayectos/estados de baterías/Kilometraje/actividad
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          Configuración del periodo de generación de cada informe:
          diarios/semanales/periodo definido por usuario
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          Informe de operadores de micro-sharing (habilitado únicamente a los
          gestores de Silence), y luego cada operador tendrá el informe de su
          propia flota
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          Informe de usuarios
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default ReportView
