import EditIcon from '@material-ui/icons/Edit'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { history } from '../../../../helpers/history'
import FirmwareForm from '../form'

class FirmwareDetailsView extends React.Component {
  onClickEdit(id) {
    history.push('/firmwares/edit/' + id)
  }

  onClickComeBack() {
    history.goBack()
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <FirmwareForm
          {...this.props}
          onClickLeftButton={this.onClickComeBack}
          onClickRightButton={() => this.onClickEdit(this.props.id)}
          leftIcon={<KeyboardBackspaceIcon />}
          rightIcon={<EditIcon />}
          leftButtonValue={t('fota.firmware.details.page.leftButtonValue')}
          rightButtonValue={t('fota.firmware.details.page.rightButtonValue')}
          typeButton="button"
        />
      </React.Fragment>
    )
  }
}

export default FirmwareDetailsView
