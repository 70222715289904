import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fileActions } from '../../../../redux/actions/fota/file_actions'
import { fotaActions } from '../../../../redux/actions/fota/fota_actions'
import { upgradeActions } from '../../../../redux/actions/fota/upgrade_actions'
import { scooterActions } from '../../../../redux/actions/scooter_actions'
import Dashboard from '../../../dashboard'
import FotaCreateView from './page'

class FotaCreate extends React.Component {
  componentDidMount() {
    this.props.getFiles()
    this.props.getUpgrades()
  }

  render() {
    const {
      firmwareReducer,
      fileReducer,
      fotaReducer,
      upgradeReducer,
      scooterReducer,
      t,
    } = this.props

    return (
      <Dashboard
        component={
          !fileReducer.loadingResults && !upgradeReducer.loadingResults ? (
            <FotaCreateView
              title={t('fota.fota.create.index.title')}
              startProcess={this.props.startProcess}
              targetTypes={firmwareReducer.targetTypes}
              targetModels={firmwareReducer.targetModels}
              sils={fileReducer.results}
              firmwares={firmwareReducer.results}
              fotaReducer={fotaReducer}
              scooterReducer={scooterReducer}
              upgrades={upgradeReducer.results}
              getScooters={this.props.getScooters}
              t={t}
            />
          ) : null
        }
      />
    )
  }
}

function mapState(state) {
  const {
    firmwareReducer,
    fileReducer,
    fotaReducer,
    upgradeReducer,
    scooterReducer,
  } = state
  return {
    firmwareReducer,
    fileReducer,
    fotaReducer,
    upgradeReducer,
    scooterReducer,
  }
}

const actionCreators = {
  startProcess: fotaActions.insert,
  getScooters: scooterActions.getAll,
  getFiles: fileActions.getAll,
  getUpgrades: upgradeActions.getAll,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FotaCreate)
