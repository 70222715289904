import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fotaActions } from '../../../redux/actions/fota/fota_actions'
import Dashboard from '../../dashboard'
import FotasView from './page'

class Fotas extends React.Component {
  componentDidMount() {
    this.props.clearResults()
  }

  render() {
    return <Dashboard component={<FotasView {...this.props} />} />
  }
}

function mapState(state) {
  const { fotaReducer } = state
  return { fotaReducer }
}

const actionCreators = {
  getProcessIds: fotaActions.getAllProcessIds,
  filter: fotaActions.filter,
  getById: fotaActions.getById,
  clearResults: fotaActions.clearResults,
  cancel: fotaActions.cancel,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Fotas)
