import { operatorCustomerConstant } from '../../constants/operatorCustomer_constant'

const initialState = {
  results: false,
  loadingResults: false,
  results: [],
}

export function operatorCustomerReducer(state = initialState, action) {
  switch (action.type) {
    case operatorCustomerConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorCustomerConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorCustomerConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
