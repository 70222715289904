import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { Grid } from '@material-ui/core'

import LineChartGraph from '../../home/dashboard/LineaChard'

import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexGrow: 1,
    // height: '100vh',
  },
  container: {
    maxWidth: '90%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    marginTop: '10%',
    borderRadius: '5px',
    boxShadow: '0 0 10px #d4d4d4',
    overflowY: 'scroll',
    padding: '2% 5%',
  },
  demo: {
    // overflowY: 'scroll',
    maxHeight: '200px',
  },
  headerCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1%',
  },
  spamOk: {
    color: 'green',
    cursor: 'pointer',
  },
  spamKo: {
    color: 'red',
    cursor: 'pointer',
  },
  percentSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '20%',
  },
}))
const OemView = () => {
  const classes = useStyles()

  const handleSelectData = (value) => {
    // console.log("handleSelectData value", value);
  }
  return (
    <main className={classes.mainContainer}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <LineChartGraph onSelectColumn={handleSelectData} />
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={classes.card}>
              <div className={classes.headerCard}>
                <p>Comandas OEM</p>
                <div className={classes.percentSection}>
                  <Tooltip title="Exito" placement="top" arrow>
                    <span className={classes.spamOk}>35%</span>
                  </Tooltip>
                  <p>/</p>
                  <Tooltip title="Error" placement="top" arrow>
                    <span className={classes.spamKo}>65%</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={classes.card}>
              <p>Errores Autentificación</p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}

export default OemView

// const useStyles = makeStyles((theme) => ({
//   mainContainer: {
//     display: 'flex',
//     flexGrow: 1,
//     // height: '100vh',
//   },
//   container: {
//     maxWidth: '90%',
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
//   fixedHeight: {
//     height: 240,
//   },
//   card:{
//     marginTop: '10%',
//     borderRadius:'5px',
//     boxShadow: '0 0 10px #d4d4d4',
//     overflowY: 'scroll',
//     padding: '2% 5%'
//   },
//   demo:{
//     // overflowY: 'scroll',
//     maxHeight: '200px'
//   },
//   headerCard:{
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     marginBottom: '1%'
//   },
//   spamOk:{
//     color: 'green',
//     cursor: 'pointer'
//   },
//   spamKo: {
//     color :'red',
//     cursor: 'pointer'
//   },
//   percentSection: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-evenly',
//     width: '20%'
//   }
// }))
