import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'

import {
  formatDateToInsertIntoComponent,
  formatToUtc,
} from '../shared/formatDate'

const CreateNotification = ({
  isOpen,
  onClose,
  onConfirm,
  targetData,
  insertConfig,
  targets,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()

    setconfirmBtnDisabled(true)
    settitle('')
    setmsg('')
    setstartDate('')
    setendDate('')
    setname('')
    setselectedTargets([])
    setpreSelectedRows([])
  }

  const [confirmBtnDisabled, setconfirmBtnDisabled] = useState(true)
  const [title, settitle] = useState('')
  const [msg, setmsg] = useState('')
  const [startDate, setstartDate] = useState('')
  const [endDate, setendDate] = useState('')
  const [name, setname] = useState('')
  const [selectedTargets, setselectedTargets] = useState([])
  const [preSelectedRows, setpreSelectedRows] = useState([])

  useEffect(() => {
    setconfirmBtnDisabled(
      !msg ||
        !startDate ||
        !endDate ||
        !name ||
        !title ||
        selectedTargets.length === 0,
    )
  }, [msg, startDate, endDate, name, selectedTargets, title])

  const handleConfirm = () => {
    const body = {
      targetType: 'User',
      targetIds: selectedTargets.map((i) => i.id),
      title, //titulo de la notificación
      body: msg,
      ScheduledStartDate: formatToUtc(startDate), //new Date(startDate).toISOString(),
      ScheduledEndDate: formatToUtc(endDate), //new Date(endDate).toISOString(),
      name, //nombre de la campaña de notificaciones (para agruparlo visualmente)
    }

    onConfirm(body)
    handleClose()
  }

  const handleSelectionChange = (rowData) => {
    setselectedTargets(rowData)
  }

  useEffect(() => {
    handleEnter()
  }, [insertConfig])

  const handleEnter = () => {
    if (!insertConfig) {
      const {
        body,
        name,
        scheduledEndDate,
        scheduledStartDate,
        targets: targetsProps,
        title,
      } = targets

      settitle(title)
      setmsg(body)
      setstartDate(formatDateToInsertIntoComponent(scheduledStartDate, true))
      setendDate(formatDateToInsertIntoComponent(scheduledEndDate, true))
      setname(name)
      setselectedTargets([...targetsProps])
      setpreSelectedRows([...targetsProps])
    }
  }

  const customerColumns = [
    {
      title: t('customers.page.columns.name'),
      field: 'firstName',
    },
    {
      title: t('customers.page.columns.surname'),
      field: 'lastName',
    },
    {
      title: t('customers.page.columns.phone'),
      field: 'telephone',
    },
    {
      title: t('customers.page.columns.email'),
      field: 'email',
    },
  ]

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={isOpen}
        onClose={handleClose}
        onEnter={handleEnter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {insertConfig
            ? t('notifications.createNotification.title')
            : t('notifications.createNotification.titleUpdate')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <CustomMaterialTable
                preSelectedRows={preSelectedRows}
                saveFilters={true}
                tab={
                  targets
                    ? 'createNotificationClient'
                    : 'updateNotificationClient'
                }
                data={targetData}
                columns={customerColumns}
                title={t(
                  'notifications.createNotification.table.titleCustomers',
                )}
                selection={true}
                onSelectionChange={(rowData) => handleSelectionChange(rowData)}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container alignContent={'center'}>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.name')}
                    fullWidth
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.title')}
                    fullWidth
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label={t(
                      'notifications.createNotification.labels.startDate',
                    )}
                    fullWidth
                    type="datetime-local"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.endDate')}
                    fullWidth
                    type="datetime-local"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.msg')}
                    fullWidth
                    value={msg}
                    onChange={(e) => setmsg(e.target.value)}
                    multiline={true}
                    rowsMax={4}
                    inputProps={{ maxLength: 384 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('notifications.createNotification.closeBtn')}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={confirmBtnDisabled}
          >
            {t('notifications.createNotification.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateNotification
