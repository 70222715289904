import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { customerActions } from '../../redux/actions/customers_actions'
import CustomMaterialTable from '../shared/customMaterialTable'
import DeletedUserDetails from './details'

const DeletedUsersView = ({ getDeletedCustomers, customerReducer }) => {
  const { t } = useTranslation()

  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    getDeletedCustomers()
  }, [])

  const MOCKED_DATA = [
    {
      id: null,
      sharingId: null,
      bsId: null,
      mgmtId: null,
      email: 'todelete1@account.com',
      name: 'To',
      surnames: 'Delete',
      birthday: '1980-07-18T07:16:05.875Z',
      gender: 'male',
      country: 'ES',
      city: 'Barcelona',
      address: 'Calle Ficticia, 128 17 3',
      postalCode: '08126',
      balance: 0,
      activeServiceId: '',
      hasTripStarted: false,
      hasTripPaused: false,
      servicesCount: 0,
      kmsCount: 0,
      ecoCount: 0,
      emailVerified: false,
      canOperate: false,
      fullName: 'To Delete',
      drivingLicense: null,
      role: null,
      firebaseUid: 'nzcpMfyiGeZZsl88NuBKQNb6wyj2',
      stripeUid: 'cus_M51TWNYUf3o7Km',
      expoPushToken: '',
      idDocType: 'NIF',
      idDocCode: 'nie',
      phone: '+34600000009',
      defaultZoneId: null,
      defaultSwapBatteriesNumber: 1,
      phoneVerified: false,
      documentsUploaded: false,
      showPendingValidate: false,
      isTrusted: true,
      currentSwapId: null,
      cannotOperate: true,
      cannotOperateReasons: [''],
      cannotOperateSince: null,
      commercialCommunicationsAccepted: false,
      fullAddress: 'Calle Ficticia, 128 17 3 08126 Barcelona',
      favouriteStationId: null,
      created: '2022-07-18T10:44:22.468Z',
      userCreate: null,
      modified: null,
      userModify: '',
      deleted: '0001-01-01T00:00:00Z',
      userDelete: '',
      isDeleted: true,
    },
    {
      id: null,
      sharingId: null,
      bsId: null,
      mgmtId: null,
      email: 'todelete@account.com',
      name: 'To',
      surnames: 'Delete',
      birthday: '1980-07-18T07:16:05.875Z',
      gender: 'male',
      country: 'ES',
      city: 'Barcelona',
      address: 'Calle Ficticia, 128 17 3',
      postalCode: '08126',
      balance: 0,
      activeServiceId: '',
      hasTripStarted: false,
      hasTripPaused: false,
      servicesCount: 0,
      kmsCount: 0,
      ecoCount: 0,
      emailVerified: false,
      canOperate: false,
      fullName: 'To Delete',
      drivingLicense: null,
      role: null,
      firebaseUid: 'iIHgdcgnOzgjk8J0qklD3iKdsph2',
      stripeUid: 'cus_M4yHM8rKDAjHNt',
      expoPushToken: '',
      idDocType: 'NIF',
      idDocCode: 'nie',
      phone: '+34600000009',
      defaultZoneId: null,
      defaultSwapBatteriesNumber: 1,
      phoneVerified: false,
      documentsUploaded: false,
      showPendingValidate: false,
      isTrusted: true,
      currentSwapId: null,
      cannotOperate: true,
      cannotOperateReasons: [''],
      cannotOperateSince: null,
      commercialCommunicationsAccepted: false,
      fullAddress: 'Calle Ficticia, 128 17 3 08126 Barcelona',
      favouriteStationId: '000000000000000000000000',
      created: '2022-07-18T11:03:44.411Z',
      userCreate: null,
      modified: null,
      userModify: '',
      deleted: '0001-01-01T00:00:00Z',
      userDelete: '',
      isDeleted: true,
    },
  ]

  return (
    <div>
      <CustomMaterialTable
        title="Deleted Users"
        data={customerReducer.deletedCustomers}
        columns={[
          { title: 'Nombre', field: 'name' },
          { title: 'Apellido', field: 'surnames' },
          { title: 'Correo', field: 'email' },
        ]}
        actions={[
          {
            icon: 'view_list',
            tooltip: 'Vew detalles',
            onClick: (event, rowData) => {
              console.log('rowData =>', rowData)
              setIsDetailsModalOpened(true)
              setSelectedUser(rowData)
            },
          },
        ]}
      />

      <DeletedUserDetails
        open={isDetailsModalOpened}
        handleClose={() => setIsDetailsModalOpened(false)}
        selectedRow={selectedUser}
      />
    </div>
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  getDeletedCustomers: customerActions.getDeletedCustomers,
}

export default connect(mapState, actionCreators)(DeletedUsersView)
