import i18next from 'i18next'
import { fotaConstants } from '../../../constants/fota/fota_constants'
import { factory } from '../../../helpers/factory'
import { history } from '../../../helpers/history'
import { fotaService } from '../../../services/fotaService'
import { alertActions } from '../alert_actions'

export const fotaActions = {
  getAll,
  getById,
  insert,
  update,
  cancel,
  clearResults,
  getAllProcessIds,
  filter,
  getMasterData,
}

function getAllProcessIds() {
  return (dispatch) => {
    dispatch(request())
    fotaService.getAll().then(
      (results) => {
        var processIds = {}
        var result = results.filter(function (entry) {
          if (processIds[entry.processId]) {
            return false
          }
          processIds[entry.processId] = true
          return true
        })
        result.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.processStartDate) - new Date(b.processStartDate)
        })
        dispatch(success(result))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: fotaConstants.GETALL_PROCESSID_REQUEST }
  }

  function success(result) {
    return { type: fotaConstants.GETALL_PROCESSID_SUCCESS, result }
  }

  function failure(error) {
    return { type: fotaConstants.GETALL_PROCESSID_FAILURE, error }
  }
}

function filter(data) {
  return (dispatch) => {
    dispatch(request())
    fotaService.filter(data).then(
      (results) => {
        results.forEach((element) => {
          element.processStartDate = factory.formatDate(
            element.processStartDate,
            'DD/MM/YYYY HH:mm',
          )
          element.processExpireDate = factory.formatDate(
            element.processExpireDate,
            'DD/MM/YYYY HH:mm',
          )
          element.startDate = factory.formatDate(
            element.startDate,
            'DD/MM/YYYY HH:mm',
          )
          element.downloadDate = factory.formatDate(
            element.downloadDate,
            'DD/MM/YYYY HH:mm',
          )
          element.endDate = factory.formatDate(
            element.endDate,
            'DD/MM/YYYY HH:mm',
          )
          element.createDate = factory.formatDate(
            element.createDate,
            'DD/MM/YYYY HH:mm',
          )
          element.lastActionTimestamp = factory.formatDate(
            element.lastActionTimestamp,
            'DD/MM/YYYY HH:mm',
          )
          element.nextActionTimestamp = factory.formatDate(
            element.nextActionTimestamp,
            'DD/MM/YYYY HH:mm',
          )
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: fotaConstants.FILTER_REQUEST }
  }

  function success(results) {
    return { type: fotaConstants.FILTER_SUCCESS, results }
  }

  function failure(error) {
    return { type: fotaConstants.FILTER_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    fotaService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.fotas.getAllError')))
      },
    )
  }

  function request() {
    return { type: fotaConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: fotaConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: fotaConstants.GETALL_FAILURE, error }
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id))
    fotaService.getById(id).then(
      (fota) => {
        fota.processStartDate = factory.formatDate(
          fota.processStartDate,
          'DD/MM/YYYY HH:mm',
        )
        fota.processExpireDate = factory.formatDate(
          fota.processExpireDate,
          'DD/MM/YYYY HH:mm',
        )
        fota.startDate = factory.formatDate(fota.startDate, 'DD/MM/YYYY HH:mm')
        fota.downloadDate = factory.formatDate(
          fota.downloadDate,
          'DD/MM/YYYY HH:mm',
        )
        fota.endDate = factory.formatDate(fota.endDate, 'DD/MM/YYYY HH:mm')
        fota.createDate = factory.formatDate(
          fota.createDate,
          'DD/MM/YYYY HH:mm',
        )
        fota.lastActionTimestamp = factory.formatDate(
          fota.lastActionTimestamp,
          'DD/MM/YYYY HH:mm',
        )
        fota.nextActionTimestamp = factory.formatDate(
          fota.nextActionTimestamp,
          'DD/MM/YYYY HH:mm',
        )
        dispatch(success(fota))
        history.push('/fotas/' + id)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.fotas.getByIdError')))
      },
    )
  }

  function request(id) {
    return { type: fotaConstants.DETAILS_REQUEST, id }
  }

  function success(fota) {
    return { type: fotaConstants.DETAILS_SUCCESS, fota }
  }

  function failure(error) {
    return { type: fotaConstants.DETAILS_FAILURE, error }
  }
}

function update(fota) {
  return (dispatch) => {
    dispatch(request())
    fotaService.update(fota).then(
      (fota) => {
        dispatch(success(fota))
        dispatch(
          alertActions.success(i18next.t('services.fotas.updateSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.fotas.updateError')))
      },
    )
  }

  function request() {
    return { type: fotaConstants.EDIT_REQUEST }
  }

  function success(fota) {
    return { type: fotaConstants.EDIT_SUCCESS, fota }
  }

  function failure(error) {
    return { type: fotaConstants.EDIT_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: fotaConstants.CLEAR_RESULTS }
  }
}

function insert(fota) {
  return (dispatch) => {
    dispatch(request())
    fotaService.startProcess(fota).then(
      (fota) => {
        dispatch(success(fota))
        dispatch(
          alertActions.success(i18next.t('services.fotas.insertSuccess')),
        )
        history.push('/fotas')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.toString()))
      },
    )
  }

  function request() {
    return { type: fotaConstants.ADD_REQUEST }
  }

  function success(fota) {
    return { type: fotaConstants.ADD_SUCCESS, fota }
  }

  function failure(error) {
    return { type: fotaConstants.ADD_FAILURE, error }
  }
}

function cancel(id) {
  return (dispatch) => {
    dispatch(request())
    fotaService.cancel(id).then(
      (fota) => {
        dispatch(success(id))
        dispatch(
          alertActions.success(i18next.t('services.fotas.cancelSuccess')),
        )
        const data = { field: 'ProcessId', value: fota.processId }
        dispatch(filter(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.toString()))
      },
    )
  }

  function request() {
    return { type: fotaConstants.CANCEL_REQUEST }
  }

  function success(id) {
    return { type: fotaConstants.CANCEL_SUCCESS, id }
  }

  function failure(error) {
    return { type: fotaConstants.CANCEL_FAILURE, error }
  }
}

function getMasterData() {
  return (dispatch) => {
    dispatch(request())
    fotaService.getMasterData().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: fotaConstants.GET_MASTER_DATA_REQUEST }
  }

  function success(result) {
    return { type: fotaConstants.GET_MASTER_DATA_SUCCESS, result }
  }

  function failure(error) {
    return { type: fotaConstants.GET_MASTER_DATA_FAILURE, error }
  }
}
