import React from 'react'
import { withTranslation } from 'react-i18next'
import TripsScooterView from './page'

class TripsScooter extends React.Component {
  render() {
    return <TripsScooterView {...this.props} />
  }
}

export default withTranslation('common')(TripsScooter)
