export const customerConstants = {
  FILTER_REQUEST: 'CUSTOMERS_FILTER_REQUEST',
  FILTER_SUCCESS: 'CUSTOMERS_FILTER_SUCCESS',
  FILTER_FAILURE: 'CUSTOMERS_FILTER_FAILURE',

  DETAILS_REQUEST: 'CUSTOMERS_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'CUSTOMERS_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'CUSTOMERS_DETAILS_FAILURE',

  CLEAR_RESULTS: 'CUSTOMERS_CLEAR_RESULTS',

  GETALL_REQUEST: 'CUSTOMERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CUSTOMERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CUSTOMERS_GETALL_FAIL',

  DELETE_REQUEST: 'CUSTOMERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CUSTOMERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CUSTOMERS_DELETE_FAILURE',

  FRIEND_SHARING_BY_SCOOTER_ID_REQUEST:
    'CUSTOMERS_FRIEND_SHARING_BY_SCOOTER_ID_REQUEST',
  FRIEND_SHARING_BY_SCOOTER_ID_SUCCESS:
    'CUSTOMERS_FRIEND_SHARING_BY_SCOOTER_ID_SUCCESS',
  FRIEND_SHARING_BY_SCOOTER_ID_FAILURE:
    'CUSTOMERS_FRIEND_SHARING_BY_SCOOTER_ID_FAILURE',

  GET_ALL_FIREBASE_USERS_REQUEST: 'CUSTOMERS_GET_ALL_FIREBASE_USERS_REQUEST',
  GET_ALL_FIREBASE_USERS_SUCCESS: 'CUSTOMERS_GET_ALL_FIREBASE_USERS_SUCCESS',
  GET_ALL_FIREBASE_USERS_FAILURE: 'CUSTOMERS_GET_ALL_FIREBASE_USERS_FAIL',

  GET_DELETED_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_DELETED_REQUEST',
  GET_DELETED_CUSTOMERS_SUCCESS: 'GET_DELETED_CUSTOMERS_SUCCESS',
  GET_DELETED_CUSTOMERS_FAILURE: 'GET_DELETED_CUSTOMERS_FAILURE',
}
