import React, { Fragment, useEffect, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import { customerActions } from '../../redux/actions/customers_actions'
import { oemActions } from '../../redux/actions/oem_actions'
import { operatorActions } from '../../redux/actions/operator_action'
import { scooterActions } from '../../redux/actions/scooter_actions'
import SendOptions from './dialgos/SendOptions'

import IconButton from '@material-ui/core/IconButton'

import Tooltip from '@material-ui/core/Tooltip'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'

import ScooterDetails from './dialgos/scooterDetails'
import ScooterTrips from './dialgos/scooterTrips'

import ExploreIcon from '@material-ui/icons/Explore'

import { useTranslation } from 'react-i18next'

import AssignScooterManager from '../assignScooter/dialog/assignScooterManager'
import AssignScooterManualyToUser from '../assignScooter/dialog/assignScooterManualyToUser'
import CustomMaterialTable from '../shared/customMaterialTable'
import RsdkDetails from './dialgos/rsdkDetails'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}))

const ScootersView = ({
  getAllScooters,
  scooterReducer,
  operatorReducer,
  customerReducer,
  oemReducer,
  resetRsdk,
  getRsdkByVehicleId,
  clearResults,
  assignToSharingOperator,
  assignToPrivateCustomer,
  assignToOEM,
  unassignToSharingOperator,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isDialogSendCommandsOpen, setisDialogSendCommandsOpen] =
    useState(false)
  const [isDialogScooterTripsOpen, setisDialogScooterTripsOpen] =
    useState(false)
  const [isDialogScooterDetailsOpen, setisDialogScooterDetailsOpen] =
    useState(false)
  const [isDialogResetRsdkOpen, setIsDialogResetRsdkOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [itemsSelected, setitemsSelected] = useState([])

  const [isDialogAssignScooterOpen, setisDialogAssignScooterOpen] =
    useState(false)
  const [
    isDialogAssignManagerScooterOpen,
    setisDialogAssignManagerScooterOpen,
  ] = useState(false)

  useEffect(() => {
    getAllScooters()
  }, [getAllScooters])

  useEffect(() => {
    if (scooterReducer.successResetRsdk) {
      setIsDialogResetRsdkOpen(false)
    }
  }, [scooterReducer.successResetRsdk])

  const handleSendCommandsToSelected = (data) => {
    setselectedRow(data)
    setisDialogSendCommandsOpen(true)
  }

  const handleActionDisplayDetails = (rowData) => {
    setselectedRow(rowData)
    setisDialogScooterDetailsOpen(true)
  }

  const handleActionDisplayTrips = (rowData) => {
    setselectedRow(rowData)
    setisDialogScooterTripsOpen(true)
  }

  const handleActionWithSelectedScooters = (data) => {
    setitemsSelected(data)
    setisDialogAssignManagerScooterOpen(true)
  }

  const handleSelectToAssign = () => {
    setisDialogAssignManagerScooterOpen(false)
    setisDialogAssignScooterOpen(true)
  }

  const handleAssignScooterSelected = (
    userSelected,
    userType,
    noOptionMail,
  ) => {
    let body
    const exist = noOptionMail === ''

    const itemsSelectedWithoutOwner = itemsSelected.filter((s) => !s.hasOwner)

    switch (userType) {
      case t('assignScooter.page.operator'):
        body = {
          vehicleIds: itemsSelectedWithoutOwner.map((i) => i.id),
          sharingOperatorEmail: exist
            ? userSelected.managerEmail
            : noOptionMail,
          onlyValidate: false,
        }

        assignToSharingOperator(body)
        break
      case t('assignScooter.page.customer'):
        body = {
          vehicleIds: itemsSelectedWithoutOwner.map((i) => i.id),
          customerEmail: exist ? userSelected.email : noOptionMail,
          onlyValidate: false,
        }

        assignToPrivateCustomer(body)
        break
      case t('assignScooter.page.oem'):
        body = {
          vehicleIds: itemsSelectedWithoutOwner.map((i) => i.id),
          oemEmail: exist ? userSelected.email : noOptionMail,
          onlyValidate: false,
        }

        assignToOEM(body)
        break
      default:
        break
    }
  }

  const handleUnassignScootersSelected = () => {
    setisDialogAssignManagerScooterOpen(false)
    const body = itemsSelected
      .filter((s) => s.hasOwner)
      .map((s) => {
        return s.id
      })
    unassignToSharingOperator(body)
  }

  const handleOnResetRsdk = (rowData) => {
    if (!rowData.id) return
    setselectedRow({ ...rowData, now: Date.now() })
    setIsDialogResetRsdkOpen(true)
  }

  const ownerTypeDataOnTable = (rowData) => {
    switch (rowData.ownerType) {
      case 'operator':
        return t('scooters.page.operator')
      case 'customer':
        return t('scooters.page.customer')
      case 'oem':
        return t('scooters.page.oem')
      case 'unowned':
        return t('scooters.page.withoutOwner')
      default:
        break
    }
  }

  return (
    <div>
      {scooterReducer.loadingResults &&
      operatorReducer.loadingResults &&
      customerReducer.loadingResults &&
      oemReducer.loadingResults ? (
        <div className={classes.progressBarContainer}>
          <CircularProgress />
        </div>
      ) : (
        <CustomMaterialTable
          saveFilters={true}
          selection={true}
          tab={'scooterPageMain'}
          onSelectionChange={(rowData) => setselectedRow(rowData)}
          title={t('scooters.page.title')}
          columns={[
            { title: t('scooters.page.columns.name'), field: 'name' },
            {
              title: t('scooters.page.columns.ownerType'),
              render: (rowData) => ownerTypeDataOnTable(rowData),
              customFilterAndSearch: (term, rowData) =>
                ownerTypeDataOnTable(rowData)
                  .toLowerCase()
                  .includes(term.toLowerCase()),
            },
            {
              title: t('scooters.page.columns.ownerMail'),
              field: 'ownerEmail',
            },
            { title: t('scooters.page.columns.imei'), field: 'imei' },
            { title: t('scooters.page.columns.model'), field: 'model' },
            { title: t('scooters.page.columns.vin'), field: 'frameNo' },
            { title: t('scooters.page.columns.plate'), field: 'plate' },
            {
              title: 'Actions',
              filtering: false,
              render: (rowData) => (
                <Fragment>
                  <Tooltip
                    title={t('scooters.page.columns.resetRsdk')}
                    placement="bottom"
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleOnResetRsdk(rowData)}
                      style={{ color: 'black' }}
                    >
                      <SyncProblemIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('scooters.page.columns.details')}
                    placement="bottom"
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleActionDisplayDetails(rowData)}
                      style={{ color: 'black' }}
                    >
                      <ChromeReaderModeIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('scooters.page.columns.trips')}
                    placement="bottom"
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleActionDisplayTrips(rowData)}
                      style={{ color: 'black' }}
                    >
                      <ExploreIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              ),
            },
          ]}
          data={scooterReducer.results}
          options={{
            padding: 'dense',
            selection: true,
            filtering: true,
            pageSize: 10,
            rowStyle: {
              padding: '0% !important',
            },
          }}
          localization={{
            toolbar: {
              nRowsSelected: '{0} Fila(s) Seleccionada(s)',
            },
          }}
          actions={[
            {
              tooltip: t('scooters.page.actions.sendCommand'),
              icon: 'wifi_calling',
              onClick: (evt, data) => handleSendCommandsToSelected(data),
            },
            {
              tooltip: t('assignScooter.tableScooterSelect.tooltip'),
              icon: 'gavel',
              // isFreeAction: true,
              onClick: (evt, data) => handleActionWithSelectedScooters(data),
            },

            // {
            //   tooltip: t("scooters.page.actions.addNotification"),
            //   icon: "add_alert",
            //   onClick: (evt, data) => handleAddScootersToNotification(data),
            // },
          ]}
        />
      )}

      <AssignScooterManager
        isOpen={isDialogAssignManagerScooterOpen}
        onClose={() => {
          setisDialogAssignManagerScooterOpen(false)
        }}
        scooterSelected={itemsSelected}
        onAssign={handleSelectToAssign}
        onUnassign={handleUnassignScootersSelected}
      />

      <AssignScooterManualyToUser
        isOpen={isDialogAssignScooterOpen}
        onClose={() => setisDialogAssignScooterOpen(false)}
        onConfirm={handleAssignScooterSelected}
        data={itemsSelected}
      />

      <SendOptions
        isOpen={isDialogSendCommandsOpen}
        onClose={() => setisDialogSendCommandsOpen(false)}
        data={selectedRow}
      />
      <ScooterDetails
        isOpen={isDialogScooterDetailsOpen}
        onClose={() => setisDialogScooterDetailsOpen(false)}
        data={selectedRow}
      />
      <ScooterTrips
        isOpen={isDialogScooterTripsOpen}
        onClose={() => setisDialogScooterTripsOpen()}
        data={selectedRow}
      />
      <RsdkDetails
        isOpen={isDialogResetRsdkOpen}
        onClose={() => setIsDialogResetRsdkOpen(false)}
        resetRsdk={resetRsdk}
        getRsdkByVehicleId={getRsdkByVehicleId}
        data={selectedRow}
        scooterReducer={scooterReducer}
      />
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer, customerReducer, oemReducer } = state
  return { scooterReducer, operatorReducer, customerReducer, oemReducer }
}

const actionCreators = {
  getAllScooters: scooterActions.getAll,
  getAllOperators: operatorActions.getAll,
  getAllCustomers: customerActions.getAll,
  getAllOems: oemActions.getAll,
  getTrips: scooterActions.getTripsByScooterId,
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
  createNotification: scooterActions.createNotification,
  getRsdkByVehicleId: scooterActions.getRsdkByVehicleId,
  resetRsdk: scooterActions.resetRsdk,
  clearResults: scooterActions.clearResults,

  assignToSharingOperator: scooterActions.assignToSharingOperator,
  assignToPrivateCustomer: scooterActions.assignToPrivateCustomer,
  assignToOEM: scooterActions.assignToOEM,
  unassignToSharingOperator: scooterActions.unassignToSharingOperator,
}

export default connect(mapState, actionCreators)(ScootersView)
