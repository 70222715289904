import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { history } from '../../../../helpers/history'
import Dashboard from '../../../dashboard'
import FileDetailView from './page'

class FileDetail extends React.Component {
  onClickComeBack() {
    history.goBack()
  }

  render() {
    const { t } = this.props
    return (
      <Dashboard
        component={
          <FileDetailView
            {...this.props}
            title={t('fota.file.detail.index.title')}
            onClickLeftButton={this.onClickComeBack}
            leftIcon={<KeyboardBackspaceIcon />}
            leftButtonValue={t('fota.file.detail.index.leftButtonValue')}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { fileReducer } = state
  return { fileReducer }
}

const actionCreators = {}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FileDetail)
