import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firmwareActions } from '../../../../redux/actions/fota/firmware_actions'
import { upgradeActions } from '../../../../redux/actions/fota/upgrade_actions'
import Dashboard from '../../../dashboard'
import UpgradeCreateView from './page'

class UpgradeCreate extends React.Component {
  render() {
    const { t } = this.props
    return (
      <Dashboard
        component={
          <UpgradeCreateView
            title={t('fota.upgrade.create.index.title')}
            insert={this.props.insert}
            getFirmwaresCompatible={this.props.getFirmwaresCompatible}
            firmwareReducer={this.props.firmwareReducer}
            upgradeReducer={this.props.upgradeReducer}
            t={t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { firmwareReducer, upgradeReducer } = state
  return { firmwareReducer, upgradeReducer }
}

const actionCreators = {
  insert: upgradeActions.insert,
  getFirmwaresCompatible: firmwareActions.getFirmwaresCompatible,
  getAllFirmwares: firmwareActions.getAll,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(UpgradeCreate)
