import GoogleMapReact from 'google-map-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import stringConstants from '../../../../constants/strings'

const AppUrl = process.env.PUBLIC_URL

const ModalScooterOperator = ({
  dynamic = { gpsData: { latitude: null, longitude: null } },
}) => {
  const { t } = useTranslation()

  const { devicePower, motoState, gpsData, scutum, model } = dynamic || {}

  const defaultProps = {
    center: {
      lat: gpsData ? gpsData.latitude : 0,
      lng: gpsData ? gpsData.longitude : 0,
    },
    zoom: 11,
  }

  const renderMarker = (map, maps) => {
    var icon1 = {
      url: AppUrl + '/content/icons/newIcons/pin_1_r.png',
      scaledSize: new maps.Size(50, 50),
    }
    var icon2 = {
      url: AppUrl + '/content/icons/newIcons/pin_2_r.png',
      scaledSize: new maps.Size(50, 50),
    }

    new maps.Marker({
      position: new maps.LatLng(gpsData.latitude, gpsData.longitude),
      animation: maps.Animation.DROP,
      icon: model === 'S01' ? icon1 : icon2,
      map,
    })
  }

  return gpsData && gpsData.latitude && gpsData.longitude ? (
    <div style={{ width: '100%', height: '70vh' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: stringConstants.GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
      ></GoogleMapReact>
    </div>
  ) : (
    <div>
      <p>GPS data not found</p>
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, ticketsReducer } = state
  return { operatorReducer, ticketsReducer }
}

const actionCreators = {}

export default connect(mapState, null)(ModalScooterOperator)
