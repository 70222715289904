import React, { useEffect, useState } from 'react'
import useStyles from './styles'

import moment from 'moment'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const AppUrl = process.env.PUBLIC_URL

const InfoWindow = ({ scooterData, getDetailsScooter, scooterReducer }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [scooterFiltered, setscooterFiltered] = useState({})

  const [imageSelected, setimageSelected] = useState('')

  const {
    activeServiceId,
    analogues,
    autonomyKms,
    outOfService,
    data, //{version: "generic", battery1Voltage: 0, battery2Voltage: 0, battery1Soc: 31, battery2Soc: 0, …}
    devicePower, //{externalInputVoltage: 12, internalBackupBatteryLevel: 100}
    digitals,
    es4,
    frameNo,
    gps, //{latitude: 41.380666, longitude: 2.100348}
    imei,
    isHelmetIncluded,
    lastConnectionTimestamp,
    model,
    motostate,
    plate,
    scooterId,
    scutum, //{batterySoc: 31, status: 4}
    selectToOperate,
    sharingOperatorId,
  } = scooterData

  const handleFilterScooter = () => {
    setscooterFiltered(
      scooterReducer.results.filter((s) => s.id === scooterData.scooterId)[0],
    )
  }

  useEffect(() => {
    handleFilterScooter()
  }, [scooterData])

  useEffect(() => {
    setimageSelected(
      scooterReducer.image.filter(
        (i) =>
          i.color === scooterFiltered.color &&
          i.model === scooterFiltered.model,
      )[0],
    )
  }, [scooterFiltered])

  return (
    <div className={classes.infoWindow}>
      <div className={classes.infoWindowContent}>
        <Typography variant="h6">
          {t('scooters.location.infoWindow.location')} {plate}
        </Typography>
        <div className={classes.infoBox}>
          <div>
            <p>
              {t('scooters.location.infoWindow.lastConnection')}:{' '}
              {moment(lastConnectionTimestamp).format('DD/MM/YYYY HH:mm')}
            </p>
            <p>
              {t('scooters.location.infoWindow.batteryLvl')}:{' '}
              {scutum && scutum.batterySoc}%
            </p>
            <p>
              {t('scooters.location.infoWindow.scooterId')}: {scooterId}
            </p>
            <p>
              {t('scooters.location.infoWindow.imei')}: {imei}
            </p>
            <p>
              {t('scooters.location.infoWindow.frameN')}: {frameNo}
            </p>
            {/* <p>Servicio activo: {activeServiceId ? "Si" : "No"}</p>
        <p>Fuera de servicio: {outOfService ? "Si" : "No"}</p> */}
            <Link onClick={() => getDetailsScooter()} className={classes.link}>
              {t('scooters.location.infoWindow.moreInfo')}
            </Link>
          </div>

          {imageSelected && imageSelected.img ? (
            <div className={classes.imgContainer}>
              <img
                className={classes.img}
                src={`data:image/jpeg;base64,${
                  imageSelected && imageSelected.img
                }`}
              />
            </div>
          ) : (
            <div className={classes.imgContainerImgNotFound}>
              <img
                className={classes.img}
                src={`${AppUrl}/content/img/image-not-found.png`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoWindow
