import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import SpecificationScooter from './spec'
import useStyles from './style'
import TripsScooter from './trips'

import Command from './command'

const HeaderTitle = ({ scooter = { plate: null }, t }) => {
  const { plate } = scooter
  return (
    <h3 style={{ textAlign: 'center' }}>
      {plate != null && t('scooters.details.page.plate') + ': ' + plate}
    </h3>
  )
}
class DetailsViewScooter extends React.Component {
  render() {
    const classes = this.props.styles
    const { value, t } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.props.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={t('scooters.details.page.spec')} {...a11yProps(0)} />
            <Tab
              label={t('scooters.details.page.daily_news')}
              {...a11yProps(1)}
            />
            <Tab
              label={t('scooters.details.page.commands')}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <HeaderTitle scooter={this.props.scooterReducer.scooter} t={t} />
          <SpecificationScooter {...this.props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HeaderTitle scooter={this.props.scooterReducer.scooter} t={t} />
          <TripsScooter {...this.props} isDailyTrips={true} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <HeaderTitle scooter={this.props.scooterReducer.scooter} t={t} />
          <Command {...this.props} />
        </TabPanel>
      </div>
    )
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function Hook(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <DetailsViewScooter
      styles={classes}
      value={value}
      setValue={setValue}
      handleChange={handleChange}
      {...props}
    />
  )
}

export default Hook
