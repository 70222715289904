import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { useTranslation } from 'react-i18next'

import { userActions } from '../../../redux/actions/user_actions'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SilenceUser = ({ insertSilenceUser }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [contactPhone, setcontactPhone] = useState('')
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')
  const [isAdmin, setisAdmin] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    const body = {
      name,
      email,
      mobilePhone: contactPhone.includes('+34')
        ? contactPhone
        : `+34${contactPhone}`,
      username,
      password,
      isAdmin,
    }
    // console.log("body", body);
    insertSilenceUser(body)

    setname('')
    setemail('')
    setcontactPhone('')
    setusername('')
    setpassword('')
    setisAdmin(false)
  }

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('users.signSilence.title')}
        </Typography>
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                // variant='standard'
                required
                fullWidth
                label={t('users.signSilence.labels.name')}
                autoFocus
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={t('users.signSilence.labels.phone')}
                value={contactPhone}
                onChange={(e) => setcontactPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={t('users.signSilence.labels.userName')}
                value={username}
                onChange={(e) => setusername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={t('users.signSilence.labels.email')}
                type={'email'}
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={t('users.signSilence.labels.password')}
                type="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t('users.signSilence.labels.role')}</InputLabel>
                <Select
                  value={isAdmin}
                  onChange={(e) => setisAdmin(e.target.value)}
                >
                  <MenuItem value={true}>
                    {t('users.signSilence.labels.admin')}
                  </MenuItem>
                  <MenuItem value={false}>
                    {t('users.signSilence.labels.manager')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('users.signSilence.btnSign')}
          </Button>
        </form>
      </div>
    </Container>
  )
}

function mapState(state) {
  const { servicesReducer } = state
  return { servicesReducer }
}

const actionCreators = {
  insertSilenceUser: userActions.insertSilenceUser,
}

export default connect(null, actionCreators)(SilenceUser)
