import { userConstants } from '../../constants/user_constants'
import { history } from '../../helpers/history'
import { allowAccessRole, isRoleAllowedToAccess } from '../../helpers/roles'
import { loginService } from '../../services/loginService'
import { userService } from '../../services/userService'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const userActions = {
  login,
  getUserInfo,
  refreshToken,
  logout,
  checkToken,
  selectRole,
  insertSilenceUser,
  changePassword,
}

function changePassword(body) {
  return (dispatch) => {
    userService.changePassword(body).then(
      (user) => {
        dispatch(
          alertActions.success(
            i18next.t('services.user.changePasswordSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.user.changePasswordError') + error,
          ),
        )
      },
    )
  }
}

function insertSilenceUser(body) {
  return (dispatch) => {
    userService.insertSilenceUser(body).then(
      (user) => {
        dispatch(
          alertActions.success(
            i18next.t('services.user.insertSilenceUserSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.user.insertSilenceUserError'),
            error,
          ),
        )
      },
    )
  }
}
function selectRole(userSelected) {
  return (dispatch) => {
    if (isRoleAllowedToAccess(userSelected.role)) {
      dispatch(selectedSuccess(userSelected))
    } else {
      dispatch(
        alertActions.error(i18next.t('services.user.selectRoleNotAllowed')),
      )
      dispatch(selectedSuccess({ role: null }))
      dispatch(logout())
    }

    function selectedSuccess() {
      return { type: userConstants.SELECTED_USER_SUCCESS, userSelected }
    }
  }
}
function checkToken() {
  return (dispatch) => {
    const istokenPresent =
      JSON.parse(localStorage.getItem('mngmt_token')) != null
    const isfirebaseUIDPresent =
      JSON.parse(localStorage.getItem('mngmt_user')) != null
    const token = JSON.parse(localStorage.getItem('mngmt_token'))
    const firebaseUID = JSON.parse(localStorage.getItem('mngmt_user'))

    if (!istokenPresent || !isfirebaseUIDPresent) {
      history.push('/login')
    } else {
      dispatch(successToken(token.value))
      dispatch(getUserInfo()) //firebaseUID.value.firebaseUID
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
  }
}

function login(email, password, isRememberPasswordChecked) {
  return (dispatch) => {
    dispatch(requestLogin())
    var locale = findGetParameter('mngmt_locale')
    localStorage.setItem('mngmt_locale', locale)
    dispatch(requestToken)
    loginService.token(email, password).then(
      (token) => {
        if (token && token.hasOwnProperty('error')) {
          dispatch(
            alertActions.error(
              i18next.t('services.user.loginIncorrectPasswdMail'),
              token.error.message,
            ),
          )
          dispatch(failureToken(token.error))
        } else {
          dispatch(successToken(token))
          // userInfo();
          dispatch(getUserInfo())
          // history.push("/home");
        }
      },
      (error) => {
        dispatch(alertActions.error('Error Login: ', error))
        dispatch(failureToken(error))
      },
    )

    function requestLogin() {
      return { type: userConstants.LOGIN_REQUEST }
    }
    function requestToken() {
      return { type: userConstants.TOKEN_REQUEST }
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
    function failureToken(error) {
      return { type: userConstants.TOKEN_FAILURE, error }
    }
  }
}

function findGetParameter(parameterName) {
  var result = null,
    tmp = []
  var items = window.location.search.substr(1).split('&')
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request(token))
    loginService.refreshToken(token).then(
      (token) => {
        dispatch(success(token.access_token))
      },
      (error) => {
        dispatch(failure(error.toString()))
      },
    )
  }

  function request(token) {
    return { type: userConstants.REFRESH_REQUEST, token }
  }

  function success(token) {
    return { type: userConstants.REFRESH_SUCCESS, token }
  }

  function failure(error) {
    return { type: userConstants.REFRESH_FAILURE, error }
  }
}

function getUserInfo() {
  return (dispatch) => {
    dispatch(requestUserInfo())

    loginService.userInfo().then(
      (user) => {
        const rolesFiltered = allowAccessRole(user)
        console.log('rolesFiltered', rolesFiltered)
        if (rolesFiltered.length > 0) {
          dispatch(successUserInfo(rolesFiltered))

          // if (rolesFiltered.length === 1) {
          //   dispatch(selectRole(rolesFiltered[0]));
          // } else {
          //   dispatch(successLogin());
          // }

          ////////////////////////////////////
          if (user.length === 1) {
            dispatch(selectRole(user[0]))
          } else {
            const allowaccesRolesDetected = allowAccessRole(user)
            if (allowaccesRolesDetected.length > 0) {
              dispatch(selectRole(allowaccesRolesDetected[0]))
            } else {
              dispatch(selectRole(user[0]))
            }
          }
          /////////////////////////////////////////
          history.push('/home')
        } else {
          dispatch(
            alertActions.error(i18next.t('services.user.selectRoleNotAllowed')),
          )
        }
      },
      (error) => {
        // dispatch(alertActions.error("Error get info user: " + error));
      },
    )

    function requestUserInfo(token) {
      return { type: userConstants.INFO_REQUEST, token }
    }
    function successUserInfo(user) {
      return { type: userConstants.INFO_SUCCESS, user }
    }
    function successLogin() {
      return { type: userConstants.LOGIN_SUCCESS }
    }
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request())
    loginService.logout()
    history.push('/login')
  }

  function request() {
    return { type: userConstants.LOGOUT }
  }
}
