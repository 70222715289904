import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Oem from './components/OEM'
import OemAnalytics from './components/OEM/analytics'
import BatteryCheck from './components/batteryCheck'
import Customers from './components/customers'
import DeletedUsers from './components/deletedUsers'
import Files from './components/fota/file'
import FilesCreate from './components/fota/file/create'
import FileDetail from './components/fota/file/detail/index'
import Firmware from './components/fota/firmware'
import FirmwareCreate from './components/fota/firmware/create'
import FirmwareDetails from './components/fota/firmware/details'
import FirmwareEdit from './components/fota/firmware/edit'
import Fotas from './components/fota/fota'
import FotaCreate from './components/fota/fota/create'
import FotaDetails from './components/fota/fota/details/index'
import Upgrades from './components/fota/upgrade'
import UpgradeCreate from './components/fota/upgrade/create'
import Home from './components/home'
import Login from './components/login'
import MonthlyDetails from './components/monthlyDetails'
import Notification from './components/notifications'
import Report from './components/reports'
import Scooters from './components/scooters'
import ScooterDetails from './components/scooters/details'
import ScootersLocation from './components/scooters/location'
import Statistics from './components/statistics'
import User from './components/users/'
import NewSilenceUser from './components/users/newUser'
import { history } from './helpers/history'
import { userActions } from './redux/actions/user_actions'

const Root = ({ authenticationReducer, checkToken }) => {
  const { auth } = authenticationReducer

  useEffect(() => {
    // !auth ? history.push('/login') : history.push('/home')
    checkToken()
  }, [auth])

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/home" component={Home} auth={auth}></PrivateRoute>
        <Route path="/login" component={Login}></Route>
        <PrivateRoute
          path="/scooters/locations"
          component={ScootersLocation}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/scooters/:id"
          component={ScooterDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/scooters"
          component={Scooters}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/statistics"
          component={Statistics}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/customers"
          component={Customers}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/fotas/create"
          component={FotaCreate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/fotas/:id"
          component={FotaDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/fotas"
          component={Fotas}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/files/create"
          component={FilesCreate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/files/:id"
          component={FileDetail}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/files"
          component={Files}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/upgrades/create/:id"
          component={UpgradeCreate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/upgrades"
          component={Upgrades}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/firmwares/create"
          component={FirmwareCreate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/firmwares/edit/:id"
          component={FirmwareEdit}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/firmwares/:id"
          component={FirmwareDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/firmwares"
          component={Firmware}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/reports"
          component={Report}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/notifications"
          component={Notification}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute path="/OEM" component={Oem} auth={auth}></PrivateRoute>
        <PrivateRoute
          path="/OemAnalytics"
          component={OemAnalytics}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute path="/users" component={User} auth={auth}></PrivateRoute>
        <PrivateRoute
          path="/newSilence"
          component={NewSilenceUser}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/monthlyDetails"
          component={MonthlyDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/batteryCheck"
          component={BatteryCheck}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/deletedUsers"
          component={DeletedUsers}
          auth={auth}
        />
        <Route path="/" render={() => <Redirect to="/home" />} />
      </Switch>
    </Router>
  )
}

function mapState(state) {
  const { authenticationReducer } = state
  return { authenticationReducer }
}

const actionCreators = {
  checkToken: userActions.checkToken,
}

export default connect(mapState, actionCreators)(Root)
