import { Button, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'
import AlertDialog from '../alert-dialog'
import AboutDialog from '../shared/about-dialog/index'

class MenuUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      openUserMenu: false,
      openModalLogout: false,
      openModalLanguage: false,
      openModalAbout: false,
    }
  }
  handleClickMenuUser = (event) => {
    this.setState({ anchorEl: event.currentTarget, openUserMenu: true })
  }

  handleCloseMenuUser = () => {
    this.setState({ openUserMenu: false })
  }

  handleCloseModal = () => {
    this.setState({ openModalLanguage: false })
  }

  handleopenModalLanguage = () => {
    this.setState({ openModalLanguage: true })
  }

  handleopenModalLogout = () => {
    this.setState({ openModalLogout: true })
  }

  onClickCancelModalLogout = () => {
    this.setState({ openModalLogout: false })
  }

  onClickAcceptModalLogout = () => {
    this.setState({ openModalLogout: false })
    this.props.logout()
  }

  onClickLanguage(lng) {
    this.setState({ openModalLanguage: false })
    this.props.changeLanguage(lng)
    this.handleCloseMenuUser()
  }

  onClickAboutModal = () => {
    this.setState({ openModalAbout: true })
    this.handleCloseMenuUser()
  }

  render() {
    return (
      <React.Fragment>
        <IconButton color="inherit" onClick={this.handleClickMenuUser}>
          <PersonIcon />
          <span className={this.props.cssClass}>{this.props.name}</span>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.openUserMenu}
          onClose={this.handleCloseMenuUser}
        >
          <MenuItem onClick={this.handleopenModalLanguage}>
            {this.props.t('dashboard.menu.changeLanguaje')}
          </MenuItem>
          <MenuItem onClick={this.props.onChangeRole}>
            {this.props.t('dashboard.menu.rols')}
          </MenuItem>
          <MenuItem onClick={this.props.onOpenPersonalData}>
            {this.props.t('dashboard.menu.personalData')}
          </MenuItem>
          <MenuItem onClick={this.onClickAboutModal}>
            {this.props.t('dashboard.menu.about')}
          </MenuItem>
          <MenuItem onClick={this.handleopenModalLogout}>
            {this.props.t('dashboard.menu.logout')}
          </MenuItem>
        </Menu>

        <AlertDialog
          open={this.state.openModalLogout}
          title={this.props.t('dashboard.menu.alert_dialog.title')}
          onClickAccept={this.onClickAcceptModalLogout}
          onClickCancel={this.onClickCancelModalLogout}
        />
        <AboutDialog
          open={this.state.openModalAbout}
          onClose={() => this.setState({ openModalAbout: false })}
        />
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
          }}
          open={this.state.openModalLanguage}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          onClose={this.handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid container alignItems="center" justify="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={() => this.onClickLanguage('es')}
            >
              Español
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={() => this.onClickLanguage('cat')}
            >
              Catalán
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={() => this.onClickLanguage('en')}
            >
              English
            </Button>
          </Grid>
        </Modal>
      </React.Fragment>
    )
  }
}

export default MenuUser
