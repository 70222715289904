import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import React, { Fragment, useState } from 'react'

import EventSeatIcon from '@material-ui/icons/EventSeat'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import MyLocationIcon from '@material-ui/icons/MyLocation'

import { connect } from 'react-redux'
import { commandString } from '../../../helpers/scooterCommands'
import { scooterActions } from '../../../redux/actions/scooter_actions'

import { useTranslation } from 'react-i18next'
import { formatDateToDisplay } from '../../shared/formatDate'
import CommandQueue from './comandqueue'

const useStyles = makeStyles((theme) => ({}))

const Command = ({
  scooterReducer,
  sendCommand,
  multipleScooters = false,
  data = [],
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [msg, setmsg] = useState('')
  const [freeCommand, setfreeCommand] = useState('')
  const [isCommandQueueDialogOpen, setisCommandQueueDialogOpen] =
    useState(false)
  const [msgTime, setmsgTime] = useState(0)
  const { scooter } = scooterReducer

  const handleSendCommand = (command, msg = null) => {
    const currentDate = new Date() //2018, 11, 24, 10, 3, 30, 0
    // console.log("currentDate", currentDate);
    // console.log("formatDateToDisplay", formatDateToDisplay(currentDate, true));
    if (multipleScooters) {
      data.map((s) => {
        const bodyLoopSendCommand = {
          imei: s.imei,
          command: {
            command: command,
            args: msg ? [msg, msgTime] : null,
          },
          id: `${Math.random()}-${command}-${s.imei}`,
          date: formatDateToDisplay(currentDate, true),
        }
        sendCommand(bodyLoopSendCommand)
      })
    } else {
      const body = {
        imei: scooter.imei,
        command: {
          command: command,
          args: msg ? [msg, msgTime] : null,
        },
        id: `${Math.random()}-${command}-${scooter.imei}`,
        date: formatDateToDisplay(currentDate, true),
      }
      sendCommand(body)
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <Link
            style={{ cursor: 'pointer' }}
            color="primary"
            onClick={() => setisCommandQueueDialogOpen(true)}
          >
            {t('scooters.details.command.commandLink')}
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p>{t('scooters.details.command.onOf')}</p>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tooltip
            title={t('scooters.details.command.on')}
            arrow
            placement="top"
          >
            <IconButton
              color="primary"
              size="medium"
              component="span"
              onClick={() => {
                handleSendCommand(commandString.POWER_ON)
              }}
            >
              <LockOpenIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('scooters.details.command.off')}
            arrow
            placement="top"
          >
            <IconButton
              color="primary"
              size="medium"
              component="span"
              onClick={() => {
                handleSendCommand(commandString.POWER_OFF)
              }}
            >
              <LockIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4}>
          <p>{t('scooters.details.command.open')}</p>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tooltip
            title={t('scooters.details.command.openSeat')}
            arrow
            placement="top"
          >
            <IconButton
              color="primary"
              size="medium"
              component="span"
              onClick={() => {
                handleSendCommand(commandString.TOPCASE_OPEN)
              }}
            >
              <EventSeatIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4}>
          <p>{t('scooters.details.command.locate')}</p>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tooltip
            title={t('scooters.details.command.locateScooter')}
            arrow
            placement="top"
          >
            <IconButton
              color="primary"
              size="medium"
              component="span"
              onClick={() => {
                handleSendCommand(commandString.LOCATE)
              }}
            >
              <MyLocationIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4}>
          <p>{t('scooters.details.command.sendMsg')}</p>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container style={{ 'align-items': 'flex-end' }}>
            <Grid item xs={10} sm={10}>
              <div style={{ display: 'flex' }}>
                <TextField
                  label={t('scooters.details.command.msg')}
                  fullWidth
                  value={msg}
                  onChange={(e) => setmsg(e.target.value)}
                  inputProps={{ maxLength: 8 }}
                  helperText={t(
                    'scooters.details.command.helperTextMaxCharacter',
                  )}
                />

                <TextField
                  label={t('scooters.details.command.time')}
                  fullWidth
                  value={msgTime}
                  type={'number'}
                  style={{ margin: '0% 1%' }}
                  onChange={(e) => setmsgTime(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={msg === ''}
                onClick={() => {
                  handleSendCommand(commandString.MESSAGE_DISPLAY, msg)
                }}
              >
                {t('scooters.details.command.send')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <p>{t('scooters.details.command.sendCommand')}</p>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container style={{ 'align-items': 'flex-end' }}>
            <Grid item xs={10} sm={10}>
              <TextField
                label={t('scooters.details.command.command')}
                fullWidth
                value={freeCommand}
                onChange={(e) => setfreeCommand(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={freeCommand === ''}
                onClick={() => {
                  handleSendCommand(freeCommand)
                }}
              >
                {t('scooters.details.command.send')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommandQueue
        isOpen={isCommandQueueDialogOpen}
        onClose={() => setisCommandQueueDialogOpen(false)}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  sendCommand: scooterActions.sendCommand,
}

export default connect(mapState, actionCreators)(Command)
