import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

import { oemActions } from '../../redux/actions/oem_actions'

import { Fragment } from 'react'
import { connect } from 'react-redux'

import CreateOEM from './dialog/createOEM'

import CustomMaterialTable from '../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const OemView = ({ oemReducer, getAll, insert, update }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const [isRegisterDialogOpen, setisRegisterDialogOpen] = useState(false)
  const [isDialogSetupInsert, setisDialogSetupInsert] = useState(false)
  const [dataSelected, setdataSelected] = useState()

  useEffect(() => {
    getAll()
  }, [])

  const handleOpendialog = (setupInsert, rowData = null) => {
    setisDialogSetupInsert(setupInsert)
    !setupInsert && setdataSelected(rowData)
    setisRegisterDialogOpen(true)
  }

  return (
    <Fragment>
      <CustomMaterialTable
        saveFilters={true}
        title="OEM's List"
        columns={[
          { title: t('oem.register.label.name'), field: 'name' },
          { title: t('oem.register.label.email'), field: 'email' },
          { title: t('oem.register.label.endpoint'), field: 'endpoint' },
          { title: t('oem.register.label.phone'), field: 'contactPhone' },
        ]}
        data={oemReducer.results}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => handleOpendialog(false, rowData),
          },
          {
            icon: 'add',
            tooltip: 'Add OEM',
            isFreeAction: true,
            onClick: () => handleOpendialog(true),
          },
        ]}
      />
      <CreateOEM
        isOpen={isRegisterDialogOpen}
        onClose={() => setisRegisterDialogOpen(false)}
        onInsert={insert}
        isDialogSetupInsert={isDialogSetupInsert}
        dataSelected={dataSelected}
        onUpdate={update}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { oemReducer } = state
  return { oemReducer }
}

const actionCreators = {
  insert: oemActions.insert,
  update: oemActions.update,
  getAll: oemActions.getAll,
}

export default connect(mapState, actionCreators)(OemView)
