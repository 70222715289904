import { Button, FormControl, Grid, Hidden, TextField } from '@material-ui/core'
import React from 'react'
import useStyles from './style'

class FotaDetailsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fotaId: props.fota.id || '',
      silFilePath: props.fota.silFilePath || '',
      silFileName: props.fota.silFileName || '',
      oldVersion: props.fota.oldVersions || '',
      newVersion: props.fota.newVersions || '',
      processStartDate: props.fota.processStartDate || '',
      processExpireDate: props.fota.processExpireDate || '',
      targetId: props.fota.targetId || '',
      targetType: props.fota.targetType || '',
      targetModel: props.fota.targetModel || '',
      imei: props.fota.imei || '',
      vin: props.fota.vin || '',
      firmwareVersion: props.fota.tcuFirmwareVersion || '',
      ecuId: props.fota.ecuId || '',
      bpId: props.fota.bpId || '',
      bmsId: props.fota.bmsId || '',
      startDate: props.fota.startDate || '',
      downloadDate: props.fota.downloadDate || '',
      endDate: props.fota.endDate || '',
      notifications: props.fota.notifiedCount,
      status: props.fota.status || '',
      updateResult: props.fota.updateResult || '',
      createDate: props.fota.createDate || '',
      createUser: props.fota.createUser || '',
      lastActionTimestamp: props.fota.lastActionTimestamp || '',
      nextActionTimestamp: props.fota.nextActionTimestamp || '',
      cancel: props.fota.isCanceled,
      error: props.fota.isError,
      expired: props.fota.isExpired,
      skipCustomerConfirmation: props.fota.skipCustomerConfirmation,
    }
  }

  render() {
    const classes = this.props.styles
    const { t } = this.props
    return (
      <React.Fragment>
        <h2 className={classes.title}>{t('fota.fota.details.index.title')}</h2>
        <div>
          <Grid className={classes.root} container spacing={3}>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="processId"
                  name="processId"
                  label={t('fota.fota.details.page.columns.fotaId')}
                  value={this.state.fotaId}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="silFilePath"
                  name="silFilePath"
                  label={t('fota.fota.details.page.columns.filePath')}
                  value={this.state.silFilePath}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="silFileName"
                  name="silFileName"
                  label={t('fota.fota.details.page.columns.fileName')}
                  value={this.state.silFileName}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="targetId"
                  name="targetId"
                  label={t('fota.fota.details.page.columns.targetId')}
                  value={this.state.targetId}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />

            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            <Grid item md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="oldVersion"
                  name="oldVersion"
                  label={t('fota.fota.details.page.columns.fw_oldVersion')}
                  value={this.state.oldVersion}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="newVersion"
                  name="newVersion"
                  label={t('fota.fota.details.page.columns.fw_newVersion')}
                  value={this.state.newVersion}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="processStartDate"
                  name="processStartDate"
                  label={t('fota.fota.details.page.columns.startDateProcess')}
                  value={this.state.processStartDate}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />

            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="processEndDate"
                  name="processEndDate"
                  label={t('fota.fota.details.page.columns.expireDateProcess')}
                  value={this.state.processExpireDate}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="targetType"
                  name="targetType"
                  label={t('fota.fota.details.page.columns.target_type')}
                  value={this.state.targetType}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="targetModel"
                  name="targetModel"
                  label={t('fota.fota.details.page.columns.target_model')}
                  value={this.state.targetModel}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="imei"
                  name="imei"
                  label={t('fota.fota.details.page.columns.imei')}
                  value={this.state.imei}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="vin"
                  name="vin"
                  label={t('fota.fota.details.page.columns.vin')}
                  value={this.state.vin}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />

            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="firmwareVersion"
                  name="firmwareVersion"
                  label={t('fota.fota.details.page.columns.TCUFirmwareVersion')}
                  value={this.state.firmwareVersion}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="ecuId"
                  name="ecuId"
                  label={t('fota.fota.details.page.columns.ECUId')}
                  value={this.state.ecuId}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="bpId"
                  name="bpId"
                  label={t('fota.fota.details.page.columns.BPId')}
                  value={this.state.bpId}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="bmsId"
                  name="bmsId"
                  label={t('fota.fota.details.page.columns.BMSId')}
                  value={this.state.bmsId}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="startDate"
                  name="startDate"
                  label={t('fota.fota.details.page.columns.startDate')}
                  value={this.state.startDate}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />

            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            {/* <Grid item md={2}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="downloadDate"
                                    name="downloadDate"
                                    label={t('fota.fota.details.page.columns.downloadDate')}
                                    value={this.state.downloadDate}
                                    InputProps={
                                        { readOnly: this.props.readOnly }
                                    }
                                />
                            </FormControl>
                        </Grid> */}
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="endDate"
                  name="endDate"
                  label={t('fota.fota.details.page.columns.endDate')}
                  value={this.state.endDate}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="notifications"
                  name="notifications"
                  label={t('fota.fota.details.page.columns.notifiedCount')}
                  value={this.state.notifications}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="status"
                  name="status"
                  label={t('fota.fota.details.page.columns.status')}
                  value={this.state.status}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="updateResult"
                  name="updateResult"
                  label={t('fota.fota.details.page.columns.updateResult')}
                  value={this.state.updateResult}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="createDate"
                  name="createDate"
                  label={t('fota.fota.details.page.columns.createDate')}
                  value={this.state.createDate}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />

            <Grid
              item
              md={1}
              xs={1}
              implementation="css"
              smDown
              component={Hidden}
            />
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="createUser"
                  name="createUser"
                  label={t('fota.fota.details.page.columns.user')}
                  value={this.state.createUser}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="lastAction"
                  name="lastAction"
                  label={t(
                    'fota.fota.details.page.columns.lastActionTimestamp',
                  )}
                  value={this.state.lastActionTimestamp}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="nextAction"
                  name="nextAction"
                  label={t(
                    'fota.fota.details.page.columns.nextActionTimestamp',
                  )}
                  value={this.state.nextActionTimestamp}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="cancel"
                  name="cancel"
                  label={t('fota.fota.details.page.columns.cancel')}
                  value={this.state.cancel}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="error"
                  name="error"
                  label={t('fota.fota.details.page.columns.error')}
                  value={this.state.error}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="expired"
                  name="expired"
                  label={t('fota.fota.details.page.columns.expire')}
                  value={this.state.expired}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="expired"
                  name="expired"
                  label={t(
                    'fota.fota.details.page.columns.skipCustomerConfirmation',
                  )}
                  value={this.state.skipCustomerConfirmation}
                  InputProps={{ readOnly: this.props.readOnly }}
                />
              </FormControl>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid container item md={2} justify="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.props.onClickLeftButton}
                  style={{ margin: 15 }}
                  startIcon={this.props.leftIcon}
                >
                  {this.props.leftButtonValue}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const styles = useStyles()
  return <FotaDetailsView styles={styles} {...props} />
}

export default Hook
