import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import ChangePassword from './changePassword'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { userActions } from '../../../../redux/actions/user_actions'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const PersonalDataTabs = ({ authenticationReducer, changePassword }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangePassword = (oldPassword, newPassword) => {
    const body = {
      oldPassword,
      newPassword,
    }
    changePassword(body)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          label={t('dashboard.personalData.tabs.password')}
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ChangePassword
          onConfirm={handleChangePassword}
          data={authenticationReducer.user}
        />
      </TabPanel>
    </div>
  )
}

function mapState(state) {
  const { authenticationReducer } = state
  return { authenticationReducer }
}

const actionCreators = {
  changePassword: userActions.changePassword,
}

export default connect(mapState, actionCreators)(PersonalDataTabs)
