import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'

import { useTranslation } from 'react-i18next'
import { rolesFirebase } from '../../../helpers/roles'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const UpdateRoles = ({ isOpen, onClose, data, onConfirm }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
    insertDataToState()
  }
  const [roles, setroles] = useState({})

  useEffect(() => {
    rolesFirebase.map((r) =>
      setroles((prev) => {
        return { ...prev, [r]: false }
      }),
    )
  }, [])

  useEffect(() => {
    if (isOpen) {
      insertDataToState()
    }
  }, [data])

  const insertDataToState = () => {
    data.roles.map((r) => {
      setroles((prev) => {
        return { ...prev, [r]: true }
      })
    })
  }

  const handleChange = (target) => {
    const { name, checked } = target

    setroles((prev) => {
      return { ...prev, [name]: checked }
    })
  }

  // console.log("roles", roles);
  // console.log("rolesFirebase", rolesFirebase);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('users.updateRole.title')}
        </DialogTitle>
        <DialogContent dividers>
          <p style={{ color: 'red' }}>{t('users.updateRole.contentText')}</p>
          <FormControl component="fieldset">
            <FormGroup>
              {rolesFirebase.map((r) => {
                return (
                  roles[r] && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={roles[r]}
                          onChange={(e) => {
                            handleChange(e.target)
                          }}
                          name={r}
                        />
                      }
                      label={r}
                    />
                  )
                )
              })}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            {t('users.updateRole.btnCancel')}
          </Button>
          <Button autoFocus onClick={() => onConfirm(roles)} color="primary">
            {t('users.updateRole.btnConfirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default UpdateRoles
