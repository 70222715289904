import moment from 'moment'

export const factory = {
  createFirmware,
  createUpgrade,
  createFile,
  createStartProcessFota,
  formatDate,
}

function createFirmware(
  id,
  targetType,
  targetModel,
  initialFrameNo,
  finalFrameNo,
  hardwareType,
  hardwareModel,
  version,
  responsible,
  description,
) {
  return {
    Id: id,
    TargetType: targetType,
    TargetModel: targetModel,
    InitialFrameNo: parseInt(initialFrameNo),
    FinalFrameNo: parseInt(finalFrameNo),
    HardwareType: hardwareType,
    HardwareModel: hardwareModel,
    Version: version,
    Responsible: responsible,
    Description: description,
  }
}

function createStartProcessFota(
  silId,
  targetsIds,
  upgradesIds,
  startDate,
  expireDate,
  skipCustomerConfirmation,
) {
  return {
    silId: silId,
    upgradeIds: upgradesIds,
    targetIds: targetsIds,
    startDate: startDate,
    expireDate: expireDate,
    skipCustomerConfirmation: skipCustomerConfirmation,
  }
}

function createFile(
  targetType,
  targetModel,
  firmwares,
  description,
  filePath,
  fileName,
  fileBinary,
) {
  let firmwaresArray = []
  firmwares.forEach((element) => {
    let item = {
      HardwareType: element.hardwareType,
      HardwareModel: element.hardwareModel,
      Version: element.version,
    }
    firmwaresArray.push(item)
  })
  return {
    TargetType: targetType,
    TargetModel: targetModel,
    Firmwares: firmwaresArray,
    Description: description,
    FilePath: filePath,
    FileName: fileName,
    FileBinary: fileBinary,
  }
}

function createUpgrade(
  id,
  targetType,
  targetModel,
  hardwareType,
  hardwareModel,
  oldVersion,
  newVersion,
) {
  return {
    id: id,
    targetType: targetType,
    targetModel: targetModel,
    hardwareType: hardwareType,
    hardwareModel: hardwareModel,
    oldVersion: oldVersion,
    newVersion: newVersion,
  }
}

function formatDate(date, format) {
  var minDate = moment.utc('0001-01-01') // minimum value as per UTC

  var receiveDate = moment(date)
  if (moment.utc(receiveDate).isAfter(minDate)) {
    return receiveDate.format(format)
  } else {
    return ''
  }
}
