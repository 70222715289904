import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import Title from './Title'

const data = [
  {
    name: '01',
    Reportes: 4000,
    'Comandas OEM': 2400,
    'Errores Autentificación': 2400,
  },
  {
    name: '02',
    Reportes: 3000,
    'Comandas OEM': 1398,
    'Errores Autentificación': 2210,
  },
  {
    name: '03',
    Reportes: 2000,
    'Comandas OEM': 9800,
    'Errores Autentificación': 2290,
  },
  {
    name: '04',
    Reportes: 2780,
    'Comandas OEM': 3908,
    'Errores Autentificación': 2000,
  },
  {
    name: '05',
    Reportes: 1890,
    'Comandas OEM': 4800,
    'Errores Autentificación': 2181,
  },
  {
    name: '06',
    Reportes: 2390,
    'Comandas OEM': 3800,
    'Errores Autentificación': 2500,
  },
  {
    name: '07',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '08',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '09',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '10',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '11',
    Reportes: 4000,
    'Comandas OEM': 2400,
    'Errores Autentificación': 2400,
  },
  {
    name: '12',
    Reportes: 3000,
    'Comandas OEM': 1398,
    'Errores Autentificación': 2210,
  },
  {
    name: '13',
    Reportes: 2000,
    'Comandas OEM': 9800,
    'Errores Autentificación': 2290,
  },
  {
    name: '14',
    Reportes: 2780,
    'Comandas OEM': 3908,
    'Errores Autentificación': 2000,
  },
  {
    name: '15',
    Reportes: 1890,
    'Comandas OEM': 4800,
    'Errores Autentificación': 2181,
  },
  {
    name: '16',
    Reportes: 2390,
    'Comandas OEM': 3800,
    'Errores Autentificación': 2500,
  },
  {
    name: '17',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '18',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '19',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '20',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '21',
    Reportes: 4000,
    'Comandas OEM': 2400,
    'Errores Autentificación': 2400,
  },
  {
    name: '22',
    Reportes: 3000,
    'Comandas OEM': 1398,
    'Errores Autentificación': 2210,
  },
  {
    name: '23',
    Reportes: 2000,
    'Comandas OEM': 9800,
    'Errores Autentificación': 2290,
  },
  {
    name: '24',
    Reportes: 2780,
    'Comandas OEM': 3908,
    'Errores Autentificación': 2000,
  },
  {
    name: '25',
    Reportes: 1890,
    'Comandas OEM': 4800,
    'Errores Autentificación': 2181,
  },
  {
    name: '26',
    Reportes: 2390,
    'Comandas OEM': 3800,
    'Errores Autentificación': 2500,
  },
  {
    name: '27',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '28',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '29',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
  {
    name: '30',
    Reportes: 3490,
    'Comandas OEM': 4300,
    'Errores Autentificación': 2100,
  },
]

export default function LineChartGraph({ onSelectColumn }) {
  return (
    <div style={{ width: '100%', height: 300 }}>
      <Title>Reportes Diarios</Title>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          onClick={(data) => onSelectColumn(data)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="Reportes" fill="#8884d8" />
          <Bar dataKey="Comandas OEM" fill="#82ca9d" />
          <Bar dataKey="Errores Autentificación" fill="#d62728" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
