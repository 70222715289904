import {
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import React from 'react'
import { factory } from '../../../../helpers/factory'
import { validations } from '../../../../helpers/validation'
import Spinner from '../../../shared/Spinner'
import FooterFormButtons from '../../../shared/footerFormButtons'
import useStyles from './styles'

const filter = createFilterOptions()

class FirmwareFormView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id || '',
      targetType: props.targetType || '',
      targetModel: props.targetModel || '',
      initialFrameNo: props.initialFrameNo || '0',
      finalFrameNo: props.finalFrameNo || '0',
      hardwareType: props.hardwareType || '',
      hardwareModel: props.hardwareModel || '',
      version: props.version || '',
      responsible: props.responsible || '',
      description: props.description || '',
      createDate: props.createDate || '',
      createUser: props.createUser || '',
      errors: {
        targetType: { result: false, message: '' },
        targetModel: { result: false, message: '' },
        initialFrameNo: { result: true, message: '' },
        finalFrameNo: { result: true, message: '' },
        hardwareType: { result: false, message: '' },
        hardwareModel: { result: false, message: '' },
        version: { result: false, message: '' },
        responsible: { result: false, message: '' },
        description: { result: true, message: '' },
      },
      targetModels: [],
      hardwareModels: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChangeAutocompleteHardware =
      this.onChangeAutocompleteHardware.bind(this)
    this.onChangeAutocompleteTarget = this.onChangeAutocompleteTarget.bind(this)
  }

  handleChange(event) {
    const { name, value } = event.target
    let errors = this.state.errors
    errors[name].message = ''
    let targetModels = this.state.targetModels
    let hardwareModels = this.state.hardwareModels
    const firmwares = this.props.firmwares
    // refresh models about target type
    if (name === 'targetType') {
      targetModels = [
        ...new Set(
          firmwares
            .filter((x) => x.targetType === value)
            .map((x) => x.targetModel),
        ),
      ]
    }
    // refresh models about hw type
    if (name === 'hardwareType') {
      hardwareModels = [
        ...new Set(
          firmwares
            .filter((x) => x.hardwareType === value)
            .map((x) => x.hardwareModel),
        ),
      ]
    }
    this.setState({ [name]: value, targetModels, hardwareModels })
  }

  validateForm() {
    let errors = this.state.errors
    errors.targetType = validations.required(this.state.targetType)
    errors.targetModel = validations.required(this.state.targetModel)
    errors.hardwareType = validations.required(this.state.hardwareType)
    errors.hardwareModel = validations.required(this.state.hardwareModel)
    errors.version = validations.required(this.state.version)
    errors.responsible = validations.required(this.state.responsible)
    this.setState({ errors })
  }

  isValidForm(errors) {
    let valid = true
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => !val.result && (valid = false),
    )

    return valid
  }

  onChangeAutocompleteTarget = (event, newValue) => {
    let errors = this.state.errors
    errors.targetModel.message = ''
    if (newValue && newValue.inputValue) {
      this.setState({
        targetModel: newValue.inputValue,
      })
      return
    }
    this.setState({
      targetModel: newValue,
    })
  }

  onChangeAutocompleteHardware = (event, newValue) => {
    let errors = this.state.errors
    errors.hardwareModel.message = ''
    if (newValue && newValue.inputValue) {
      this.setState({
        hardwareModel: newValue.inputValue,
      })
      return
    }
    this.setState({
      hardwareModel: newValue,
    })
  }

  filterOptions(options, params) {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        title: `Agregar "${params.inputValue}"`,
      })
    }

    return filtered
  }

  getOptionLabel = (option) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    return option
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.validateForm()
    if (this.isValidForm(this.state.errors)) {
      const firmware = factory.createFirmware(
        this.state.id,
        this.state.targetType,
        this.state.targetModel,
        this.state.initialFrameNo,
        this.state.finalFrameNo,
        this.state.hardwareType,
        this.state.hardwareModel,
        this.state.version,
        this.state.responsible,
        this.state.description,
      )
      this.props.onClickRightButton(firmware)
    }
  }

  render() {
    const classes = this.props.styles
    const { t } = this.props
    return (
      <React.Fragment>
        <Spinner loading={this.props.firmwareReducer.loadingResults} />
        <h2 className={classes.title}>{this.props.title}</h2>
        <div className={classes.divContainer}>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid className={classes.root} container spacing={3}>
              <TextField
                style={{ display: 'none' }}
                value={this.state.id}
                type="hidden"
              />
              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2} xs={7} sm={5}>
                <FormControl>
                  <InputLabel shrink id="targetTypeLabel">
                    {t('fota.firmware.form.page.target_type')}
                  </InputLabel>
                  <Select
                    labelId="targetTypeLabel"
                    id="targetType"
                    name="targetType"
                    value={this.state.targetType || ''}
                    onChange={this.handleChange}
                    error={
                      this.state.errors.targetType.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{
                      readOnly: this.props.readOnly || this.props.readEdit,
                    }}
                  >
                    {this.props.targetTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.targetType.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <Autocomplete
                    value={this.state.targetModel || ''}
                    id="targetModelAutocomplete"
                    onChange={this.onChangeAutocompleteTarget}
                    filterOptions={this.filterOptions}
                    getOptionLabel={this.getOptionLabel}
                    renderOption={(option) => {
                      return option.title != null ? option.title : option
                    }}
                    name="targetModel"
                    options={this.state.targetModels}
                    disabled={
                      this.props.readOnly ||
                      this.props.readEdit ||
                      this.state.targetType === ''
                        ? true
                        : false
                    }
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fota.firmware.form.page.target_model')}
                        helperText={this.state.errors.targetModel.message}
                        name="targetModel"
                        error={
                          this.state.errors.targetModel.message.length === 0
                            ? false
                            : true
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={7} sm={5}>
                <FormControl>
                  <TextField
                    label={t('fota.firmware.form.page.initial_frame')}
                    value={this.state.initialFrameNo || ''}
                    name="initialFrameNo"
                    onChange={this.handleChange}
                    type="number"
                    error={
                      this.state.errors.initialFrameNo.message.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errors.initialFrameNo.message}
                    InputProps={{
                      readOnly: this.props.readOnly && this.props.readEdit,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={7} sm={5}>
                <TextField
                  label={t('fota.firmware.form.page.final_frame')}
                  value={this.state.finalFrameNo || ''}
                  name="finalFrameNo"
                  onChange={this.handleChange}
                  type="number"
                  error={
                    this.state.errors.finalFrameNo.message.length === 0
                      ? false
                      : true
                  }
                  helperText={this.state.errors.finalFrameNo.message}
                  InputProps={{
                    readOnly: this.props.readOnly && this.props.readEdit,
                  }}
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2} xs={7} sm={5}>
                <FormControl>
                  <InputLabel shrink id="hardwareTypeLabel">
                    {t('fota.firmware.form.page.hw_type')}
                  </InputLabel>
                  <Select
                    labelId="hardwareTypeLabel"
                    id="hardwareType"
                    name="hardwareType"
                    value={this.state.hardwareType || ''}
                    onChange={this.handleChange}
                    error={
                      this.state.errors.hardwareType.message.length === 0
                        ? false
                        : true
                    }
                    displayEmpty
                    inputProps={{
                      readOnly: this.props.readOnly || this.props.readEdit,
                    }}
                  >
                    {this.props.hardwareTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    <FormHelperText error>
                      {this.state.errors.hardwareType.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item md={3} xs={7} sm={5}>
                <FormControl>
                  <Autocomplete
                    value={this.state.hardwareModel || ''}
                    id="hardwareModelAutocomplete"
                    onChange={this.onChangeAutocompleteHardware}
                    filterOptions={this.filterOptions}
                    getOptionLabel={this.getOptionLabel}
                    renderOption={(option) => {
                      return option.title != null ? option.title : option
                    }}
                    name="targetModel"
                    options={this.state.hardwareModels}
                    freeSolo
                    disabled={
                      this.props.readOnly ||
                      this.props.readEdit ||
                      this.state.hardwareType === ''
                        ? true
                        : false
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={this.state.errors.hardwareModel.message}
                        label={t('fota.firmware.form.page.hw_model')}
                        name="targetModel"
                        error={
                          this.state.errors.hardwareModel.message.length === 0
                            ? false
                            : true
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={7} sm={6}>
                <TextField
                  label={t('fota.firmware.form.page.fw_version')}
                  value={this.state.version || ''}
                  name="version"
                  onChange={this.handleChange}
                  error={
                    this.state.errors.version.message.length === 0
                      ? false
                      : true
                  }
                  helperText={this.state.errors.version.message}
                  InputProps={{
                    readOnly: this.props.readOnly || this.props.readEdit,
                  }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3} xs={7} sm={5}>
                <TextField
                  label={t('fota.firmware.form.page.responsable')}
                  value={this.state.responsible || ''}
                  name="responsible"
                  onChange={this.handleChange}
                  error={
                    this.state.errors.responsible.message.length === 0
                      ? false
                      : true
                  }
                  helperText={this.state.errors.responsible.message}
                  InputProps={{
                    readOnly: this.props.readOnly || this.props.readEdit,
                  }}
                />
              </Grid>
              <Grid item md={5} xs={7} sm={5}>
                <TextField
                  label={t('fota.firmware.form.page.description')}
                  value={this.state.description || ''}
                  name="description"
                  onChange={this.handleChange}
                  InputProps={{
                    readOnly: this.props.readOnly && this.props.readEdit,
                  }}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              {this.props.detailMode ? (
                <React.Fragment>
                  <Grid
                    item
                    md={2}
                    xs={1}
                    implementation="css"
                    smDown
                    component={Hidden}
                  />
                  <Grid item md={2} xs={7} sm={4}>
                    <TextField
                      label={t('fota.firmware.form.page.createDate')}
                      value={this.state.createDate || ''}
                      name="createDate"
                      InputProps={{ readOnly: this.props.readOnly }}
                    />
                  </Grid>
                  <Grid item md={3} xs={7} sm={4}>
                    <TextField
                      label={t('fota.firmware.form.page.createUser')}
                      value={this.state.createUser || ''}
                      name="createUser"
                      InputProps={{ readOnly: this.props.readOnly }}
                    />
                  </Grid>
                </React.Fragment>
              ) : null}

              <Grid container justify="center" alignItems="center">
                <FooterFormButtons
                  onClickLeftButton={this.props.onClickLeftButton}
                  leftIcon={this.props.leftIcon}
                  leftButtonValue={this.props.leftButtonValue}
                  typeButton={this.props.typeButton}
                  onClickRightButton={this.props.onClickRightButton}
                  rightIcon={this.props.rightIcon}
                  rightButtonValue={this.props.rightButtonValue}
                  submitted={this.props.firmwareReducer.loadingResults}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const classes = useStyles()
  return <FirmwareFormView styles={classes} {...props} />
}

export default Hook
