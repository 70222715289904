import React, { Fragment, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { customerActions } from '../../redux/actions/customers_actions'

import { useTranslation } from 'react-i18next'
import DialogConfirmAction from '../shared/modalConfirmAction'
import UpdateRoles from './dialog/updateRoles'

import CustomMaterialTable from '../shared/customMaterialTable'

const UserView = ({ getAllFirebaseUser, customerReducer, deleteUser }) => {
  const { t } = useTranslation()
  const [isConfirmDeleteDialogOpen, setisConfirmDeleteDialogOpen] =
    useState(false)
  const [isUpdateRolesDialogOpen, setisUpdateRolesDialogOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState({})
  useEffect(() => {
    getAllFirebaseUser()
  }, [])

  const handleUpdateRow = (rowData) => {
    setselectedRow(rowData)
    setisUpdateRolesDialogOpen(true)
  }

  const handledeleteRow = (rowData) => {
    setselectedRow(rowData)
    setisConfirmDeleteDialogOpen(true)
  }

  const handleDeleteUser = () => {
    const bodyDelete = {
      email: selectedRow.email,
      deleteRoles: null,
      testOnly: false,
    }
    deleteUser(bodyDelete, 'delete')
  }
  const handleUpdateUser = (roles) => {
    setisUpdateRolesDialogOpen(false)
    let deleteRoles = []
    Object.entries(roles).map((r) => {
      const [role, bool] = r
      !bool && deleteRoles.push(role)
    })

    //comprobar que tiene >1 rol
    const bodyUpdate = {
      email: selectedRow.email,
      deleteRoles: deleteRoles,
      testOnly: false,
    }
    // console.log("bodyUpdate", bodyUpdate);
    deleteUser(bodyUpdate, 'remove')
  }

  return (
    <Fragment>
      <CustomMaterialTable
        saveFilters={true}
        title={t('users.page.table.title')}
        columns={[
          { title: t('users.page.table.columns.name'), field: 'displayName' },
          {
            title: t('users.page.table.columns.email'),
            field: 'email',
          },
          { title: t('users.page.table.columns.phone'), field: 'phoneNumber' },
        ]}
        data={customerReducer.firebaseUsers}
        actions={[
          {
            icon: 'edit',
            tooltip: t('users.page.table.actions.edit'),
            onClick: (event, rowData) => handleUpdateRow(rowData),
          },
          {
            icon: 'delete',
            tooltip: t('users.page.table.actions.remove'),
            onClick: (event, rowData) => handledeleteRow(rowData),
          },
        ]}
      />
      <DialogConfirmAction
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => setisConfirmDeleteDialogOpen(false)}
        onConfirm={handleDeleteUser}
        data={{
          header: t('users.page.dialogConfirmaActions.header'),
          body: t('users.page.dialogConfirmaActions.body'),
        }}
      />
      <UpdateRoles
        isOpen={isUpdateRolesDialogOpen}
        onClose={() => setisUpdateRolesDialogOpen(false)}
        data={selectedRow}
        onConfirm={handleUpdateUser}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  getAllFirebaseUser: customerActions.getAllFirebaseUser,
  deleteUser: customerActions.deleteUser,
}

export default connect(mapState, actionCreators)(UserView)
