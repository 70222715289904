import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import TextField from '@material-ui/core/TextField'

import { connect } from 'react-redux'

import TripsScooterView from '../details/trips/page'

import { scooterActions } from '../../../redux/actions/scooter_actions'

import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
    padding: '1%',
    marginBottom: '2%',
  },
  containerBody: {
    padding: '1%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ScooterTrips = ({
  isOpen,
  onClose,
  data,
  getTrips,
  getTripDetail,
  getDynamyc,
  scooterReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isSpinnerOn, setisSpinnerOn] = useState(false)
  const [since, setsince] = useState('2020-05-24')
  const [until, setuntil] = useState('2020-05-24')

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    isOpen && getTrips(data.id)
  }, [isOpen])

  const handleSearchTrips = (betweenDates) => {
    betweenDates ? getTrips(data.id, since, until) : getTrips(data.id)
    setisSpinnerOn(true)
  }

  useEffect(() => {
    setisSpinnerOn(false)
  }, [scooterReducer])
  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('scooters.dialogs.scooterTrips.toolbarText')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.containerBody}>
          <div className={classes.filterSection}>
            <TextField
              id="date"
              label={t('scooters.dialogs.scooterTrips.since')}
              type="date"
              value={since}
              onChange={(e) => setsince(e.target.value)}
              className={classes.textField}
            />
            <TextField
              id="date"
              label={t('scooters.dialogs.scooterTrips.until')}
              type="date"
              value={until}
              onChange={(e) => setuntil(e.target.value)}
              className={classes.textField}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSearchTrips(true)
              }}
            >
              {t('scooters.dialogs.scooterTrips.search')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSearchTrips()
              }}
            >
              {t('scooters.dialogs.scooterTrips.searchAll')}
            </Button>
          </div>
          {!isSpinnerOn ? (
            scooterReducer.trips.length > 0 ? (
              <TripsScooterView
                idScooter={data && data.id}
                getTripDetail={getTripDetail}
                scooterReducer={scooterReducer}
              />
            ) : (
              <p>{t('scooters.dialogs.scooterTrips.noResults')}</p>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getTrips: scooterActions.getTripsByScooterId,
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
}

export default connect(mapState, actionCreators)(ScooterTrips)
