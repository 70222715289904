import React from 'react'
import Dashboard from '../dashboard/index'

import DashboardHome from './dashboard/Dashboard'
export default class Home extends React.Component {
  render() {
    return (
      <Dashboard
        component={
          <DashboardHome />
          // <HomeView />
        }
      />
    )
  }
}
