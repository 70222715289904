import { operatorConstant } from '../../constants/operator_constant'
import { operatorService } from '../../services/operatorService'

import { alertActions } from './alert_actions'

import { loginService } from '../../services/loginService'

import i18next from 'i18next'
export const operatorActions = {
  getAll,
  insert,
  updateRestricted,
  update,
  getOperatorInfo,
  insertOperatorUsers,
  validateOperator,
  getAllToValidate,
  getAllPrivateOperators,
  getallValidatedOperators,
  getAllDiscounts,
  createDiscount,
  updateDiscount,
  getAffectedCustomersByDiscount,
  getDiscountsByCustomerId,
}

function getDiscountsByCustomerId(customerId) {
  return (dispatch) => {
    operatorService.getDiscountsByCustomerId(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.operators.getDiscountsByCustomerIdError') +
              error,
          ),
        )
      },
    )
  }
  function request() {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_SUCCESS, results }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_FAILURE,
      error,
    }
  }
}

function updateDiscount(body) {
  const sharingOperatorId = body.sharingOperatorId
  delete body.sharingOperatorId
  return (dispatch) => {
    operatorService.updateDiscount(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.operators.updateDiscountSuccess'),
          ),
        )
        dispatch(getAllDiscounts(sharingOperatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.operators.updateDiscountError') + error,
          ),
        )
      },
    )
  }
}

function getAffectedCustomersByDiscount(discountId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAffectedCustomersByDiscount(discountId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            i18next.t(
              'services.operators.getAffectedCustomersByDiscountError',
            ) + error,
          ),
        )
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE,
      error,
    }
  }
}

function createDiscount(body) {
  const sharingOperatorId = body.sharingOperatorId
  return (dispatch) => {
    operatorService.createDiscount(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.operators.createDiscountSuccess'),
          ),
        )
        dispatch(getAllDiscounts(sharingOperatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.operators.createDiscountError') + error,
          ),
        )
      },
    )
  }
}

function getAllDiscounts(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllDiscounts(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     i18next.t("services.operators.getAllDiscountsError") + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_FAILURE, error }
  }
}

function getallValidatedOperators() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getallValidatedOperators().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all VALIDATED operators action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_REQUEST }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_FAILURE,
      error,
    }
  }
}

function getAllPrivateOperators() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllPrivateOperators().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all private operator action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_FAILURE, error }
  }
}

function getAllToValidate() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllToValidate().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all operator to validate action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_FAILURE, error }
  }
}

function validateOperator(body) {
  return (dispatch) => {
    operatorService.validateOperator(body).then((results) => {
      let message = ''
      message = body.canOperate
        ? i18next.t('services.operators.validateOperatorValidate')
        : i18next.t('services.operators.validateOperatorNote')
      dispatch(alertActions.success(message))
      dispatch(getAllToValidate())
    })
  }
}

function getAll(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(alertActions.error('error get all operator action. Message:' + error))
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_FAILURE, error }
  }
}

function getOperatorInfo(id) {
  return (dispatch) => {
    dispatch(requestOperatorInfo())
    loginService.getOperatorData(id).then((user) => {
      dispatch(successOperatorInfo(user))
    })

    function successOperatorInfo(user) {
      return { type: operatorConstant.SUCCESS_OPERATOR_INFO, user }
    }
    function requestOperatorInfo() {
      return { type: operatorConstant.REQUEST_OPERATOR_INFO }
    }
  }
}

function updateRestricted(body) {
  const operatorId = body.id

  return (dispatch) => {
    dispatch(request())
    operatorService.updateRestricted(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operators.updateRestrictedSuccess'),
          ),
        )
        const obj = {
          sharingOperatorId: operatorId,
          canOperate: false,
          cannotOperateReason:
            'Datos del operador modificados pendientes de validar por Slience',
        }
        // dispatch(validateOperator(obj))
        // dispatch(userActions.logout())

        dispatch(getOperatorInfo(operatorId))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            i18next.t('services.operators.updateRestrictedError') + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.UPDATE_RESTRICTED_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.UPDATE_RESTRICTED_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.UPDATE_RESTRICTED_FAILURE, error }
  }
}

function insert(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.insert(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(i18next.t('services.operators.insertSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(getAll())
        dispatch(
          alertActions.error(
            i18next.t('services.operators.insertError') + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.INSERT_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.INSERT_FAILURE, error }
  }
}
function insertOperatorUsers(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.insertOperatorUsers(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operators.insertOperatorUsersSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(getAll())
        dispatch(
          alertActions.error(
            i18next.t('services.operators.insertOperatorUsersError') + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.INSERT_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.INSERT_FAILURE, error }
  }
}
function update(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.update(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operators.UpdateOperatorUsersSuccess'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            i18next.t('services.operators.UpdateOperatorUsersError') + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.UPDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.UPDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.UPDATE_FAILURE, error }
  }
}
