import stringConstants from '../constants/strings'
import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const customerService = {
  getAll,
  getById,
  insert,
  update,
  filter,
  getFriendSharingByScooterId,
  getAllFirebaseUser,
  _delete,
  deleteUser,
  getDeletedCustomers,
}
function deleteUser(body) {
  return handleCall(`/customers/v1/Users/DeleteByRole`, {
    method: 'DELETE',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Users/DeleteByRole`,
  //   requestOptions
  // ).then(handleResponse);
}
function getAllFirebaseUser() {
  return handleCall(`/customers/v1/Users/GetAllFirebaseUsers`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Users/GetAllFirebaseUsers`,
  //   requestOptions
  // ).then(handleResponse);
}

function getFriendSharingByScooterId(scooterId) {
  return handleCall(
    `/vehicles/v1/Vehicles/GetFriendSharing?VehicleId=${scooterId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/Vehicles/GetFriendSharing?VehicleId=" +
  //     scooterId,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll() {
  return handleCall(`/customers/v1/users`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/customers/v1/users",
  //   requestOptions
  // ).then(handleResponse);
}

function getById(id) {
  return handleCall(`/customers/v1/users/${id}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/customers/v1/users/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(upgrade) {
  return handleCall(`/customers/v1/customers`, {
    method: 'POST',
    body: JSON.stringify(upgrade),
  })
  // const requestOptions = {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/customers/v1/customers",
  //   requestOptions
  // ).then(handleResponse);
}

function update(upgrade) {
  const id = JSON.stringify(upgrade).id
  return handleCall(`/customers/v1/customers/${id}`, {
    method: 'PUT',
    body: JSON.stringify(upgrade),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // const id = JSON.stringify(upgrade).id;
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/customers/v1/customers/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function filter(data) {
  const querystring = data.field + '&value=' + data.value
  return handleCall(`/customers/v1/customers/?field=${querystring}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // const querystring = data.field + "&value=" + data.value;
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/customers/v1/customers/?field=" +
  //     querystring,
  //   requestOptions
  // ).then(handleResponse);
}

function _delete(id) {
  return handleCall(`/customers/v1/customers/${id}`, {
    method: 'DELETE',
    body: null,
  })
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/customers/v1/customers/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function getDeletedCustomers() {
  return handleCall(
    `/customers/v1/Customers/GetDeletedCustomers`,
    {
      method: 'GET',
      body: null,
    },
    stringConstants.URL_Fleet,
  )
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
