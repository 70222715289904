import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoWindowContent: {
    fontWeight: 'bold',
  },
  filterLayersInsideMap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '50px',
    // paddingTop: '10px'
  },
  btnFilter: {
    background: '#ffffff',
    color: '#666666',
    width: '40px',
    height: '40px',
    margin: '10px 10px',
  },
  btnFilter2: {
    background: '#ffffff',
    color: '#666666',
    width: '40px',
    height: '40px',
    margin: '-40px 10px',
  },
  btnActive: {
    color: '#88c100',
  },
  autocomplete: {
    width: '50%',
    margin: 'auto',
    paddingTop: '10px',
    borderColor: 'black',
  },
  inputFilterOperator: {
    background: '#ffffff',
  },
  cssOutlinedInput: {
    borderColor: `black !important`,
  },
  cardInfoOperatorArea: {
    right: '1%',
    marginTop: '2%',
    background: '#ffffff',
    minWidth: 275,
    position: 'absolute',
    boxShadow: '0px 0px 5px 0px #909090',
  },
  btnFilterDrawer: {
    // display: "flex",
    // justifyContent: "center",
    // marginTop: "-40px",
    // display: "flex",
    // marginTop: "-510px",
    // justifyContent: "center",
    // marginLeft: "965px",
    // height: "40px",
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '4px',
    marginTop: '-166px',
  },
  btnFilter: {
    background: '#fafafa',
  },
  clusterMarker: {
    // color: "#fff",
    // background: "#1978c8",
    // borderRadius: "50%",
    // padding: "10px",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    cursor: 'pointer',
  },
  pointCount: {
    position: 'absolute',
    top: '22px',
    left: '22px',
  },
  pointCountSingle: {
    position: 'absolute',
    top: '20px',
    left: '23px',
  },
  searchBtn: {
    // position: "absolute",
    // top: "106px",
    // zIndex: "10",
    // right: " 95px",
    // background: "#ffffff",
    // width: "40px",
    // height: "40px",
    // display: "flex",
    // justifyContent: "center",
    // cursor: "pointer",
    // alignItems: "center",
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '4px',
    marginTop: '-166px',
  },
  iconStyle: {
    color: '#666666',
    width: '40px',
    '&:hover': {
      color: '#111111',
    },
  },
  btn: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
}))

export default useStyles
