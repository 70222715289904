import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fileActions } from '../../../../redux/actions/fota/file_actions'
import Dashboard from '../../../dashboard'
import FileCreateView from './page'

class FileCreate extends React.Component {
  render() {
    const { firmwareReducer, fileReducer, t } = this.props
    return (
      <Dashboard
        component={
          <FileCreateView
            title={t('fota.file.create.index.title')}
            insert={this.props.insert}
            getFirmwaresVersions={this.props.getFirmwaresVersions}
            targetTypes={firmwareReducer.targetTypes}
            targetModels={firmwareReducer.targetModels}
            firmwares={firmwareReducer.results}
            fileReducer={fileReducer}
            t={t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { firmwareReducer, fileReducer } = state
  return { firmwareReducer, fileReducer }
}

const actionCreators = {
  insert: fileActions.insert,
  getFirmwaresVersions: fileActions.getFirmwaresVersions,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FileCreate)
