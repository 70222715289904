import React, { useEffect, useState } from 'react'
import Spinner from '../shared/Spinner'

import ScooterListFromClients from './dialog/scooterList'

import { useDispatch } from 'react-redux'
import ModalConfirmAction from '../../components/UI/modalConfirmAction/modalConfirmAction'
import { alertActions } from '../../redux/actions/alert_actions'
import { userService } from '../../services/userService'
import NotificationsManagement from '../notifications'
import CustomMaterialTable from '../shared/customMaterialTable'

const CustomerView = ({ customerReducer, t, getAll }) => {
  const [isScooterListDialogOpen, setisScooterListDialogOpen] = useState(false)
  const [rowSelected, setrowSelected] = useState({})
  const [selectedEmail, setSelectedEmail] = useState('')
  const [confirmDeleteUserModalOpen, setConfirmDeleteUserModalOpen] =
    useState(false)

  const [isNotificationsManagementOpen, setisNotificationsManagementOpen] =
    useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    getAll()
  }, [])

  const handleActionShowScooterList = (rowData) => {
    setrowSelected(rowData)
    setisScooterListDialogOpen(true)
  }

  const handleAddScootersToNotification = (data) => {
    setisNotificationsManagementOpen(true)
  }

  const handleDeleteUser = () => {
    userService.deleteUser(selectedEmail).then(
      (results) => {
        getAll()
        dispatch(alertActions.success('Usuario eliminado correctamente'))
      },
      (error) => {
        dispatch(
          alertActions.error('Ha habido un error al eliminar el usuario'),
        )
      },
    )
  }

  return (
    <React.Fragment>
      <Spinner loading={customerReducer.loadingResults} />
      {customerReducer.showResults ? (
        <CustomMaterialTable
          saveFilters={true}
          title={t('customers.page.title')}
          // actions={[
          //   {
          //     // icon: "save",
          //     icon: () => <MotorcycleIcon fontSize="large" />,
          //     tooltip: "Motos del cliente",
          //     onClick: (event, rowData) => handleActionShowScooterList(rowData),
          //   },
          // ]}
          columns={[
            { title: t('customers.page.columns.name'), field: 'name' },
            { title: t('customers.page.columns.surname'), field: 'lastName' },
            { title: t('customers.page.columns.birthday'), field: 'birthday' },
            { title: t('customers.page.columns.phone'), field: 'telephone' },
            { title: t('customers.page.columns.email'), field: 'email' },
            {
              title: t('customers.page.columns.country'),
              field:
                'country' /* , lookup : {1: 'España', 2: 'Francia', 3: 'Inglaterra'} */,
            },
          ]}
          actions={[
            {
              tooltip: t('scooters.page.actions.addNotification'),
              icon: 'add_alert',
              isFreeAction: true,
              onClick: (evt, data) => handleAddScootersToNotification(data),
            },
            {
              tooltip: 'Vehicle',
              icon: 'directions_bike',
              onClick: (evt, data) => handleActionShowScooterList(data),
            },
            {
              tooltip: 'Delete',
              icon: 'delete',
              onClick: (evt, data) => {
                setConfirmDeleteUserModalOpen(true)
                setSelectedEmail(data.email)
              },
            },
          ]}
          data={customerReducer.results}
        />
      ) : null}
      <ScooterListFromClients
        isOpen={isScooterListDialogOpen}
        onClose={() => setisScooterListDialogOpen(false)}
        data={rowSelected}
      />
      <NotificationsManagement
        isOpen={isNotificationsManagementOpen}
        onClose={() => setisNotificationsManagementOpen(false)}
        usersData={customerReducer.results}
        usersType={'Customer'}
      />
      <ModalConfirmAction
        headerText="Estás seguro de que quieres eliminar el usuario seleccionado?"
        isOpen={confirmDeleteUserModalOpen}
        onConfirm={() => {
          setConfirmDeleteUserModalOpen(false)
          handleDeleteUser()
        }}
        onClose={() => {
          setConfirmDeleteUserModalOpen(false)
          setSelectedEmail('')
        }}
      />
    </React.Fragment>
  )
}

export default CustomerView
