import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { dynamicActions } from '../../redux/actions/dynamic_actions'
import { displayWithTooltip } from '../shared/enshortenLargeData'

import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import CustomMaterialTable from '../shared/customMaterialTable'
import BatteryCheckDetails from './details'

const BatteryCheckView = ({ getAll, forceUpdate, dynamicsReducer }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [isDetailsModalOpened, setisDetailsModalOpened] = useState(false)
  const [isBpackSearchModalOpened, setIsBpacksearchModalOpened] =
    useState(false)
  const [selectedDynamic, setSelectedDynamic] = useState({})
  const [scooterId, setScooterId] = useState('')
  const [stationId, setStationId] = useState('')
  const [batteryPackId, setBatteryPackId] = useState('')
  const [batteryPackSearch, setBatteryPackSearch] = useState('')
  const [source, setSource] = useState('none')
  const [forcedError, setForcedError] = useState('')
  const [minSoc, setMinSoc] = useState('')
  const [maxSoc, setMaxSoc] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [since, setSince] = useState('')
  const [until, setUntil] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    getAll()
  }, [])

  const handleUnifiedFilter = () => {
    const body = {
      batteryPackId,
      stationId,
      scooterId,
      forcedError,
      until,
      since,
      source,
      minSoc,
      maxSoc,
    }

    getAll(body)
  }

  const handleForceUpdate = () => {
    forceUpdate(batteryPackSearch)
    setIsBpacksearchModalOpened(false)
  }

  return (
    <Container style={{ maxWidth: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('unifieds.filters.scooterId')}
            onChange={(e) => setScooterId(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={ t("unifieds.details.email") }
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid> */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('unifieds.filters.stationId')}
            onChange={(e) => setStationId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="BatteryPackId"
            onChange={(e) => setBatteryPackId(e.target.value)}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="source-label">
              {' '}
              {t('unifieds.filters.source')}{' '}
            </InputLabel>
            <Select
              labelId="source-label"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              <MenuItem value="none"> {t('unifieds.filters.all')} </MenuItem>
              <MenuItem value="Station">
                {' '}
                {t('unifieds.filters.station')}{' '}
              </MenuItem>
              <MenuItem value="Scooter">
                {' '}
                {t('unifieds.filters.scooter')}{' '}
              </MenuItem>
            </Select>
          </FormControl>
          {/* <TextField
                        fullWidth
                        label={ t("unifieds.filters.source") }
                        onChange={(e) => setSource(e.target.value)}
                    /> */}
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Min Soc"
            onChange={(e) => setMinSoc(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Max Soc"
            onChange={(e) => setMaxSoc(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('unifieds.details.since')}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setSince(
                moment(e.target.value).utc().format('YYYY-MM-DDTHH:mm:ss'),
              )
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('unifieds.details.until')}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            value={until}
            onChange={(e) =>
              setUntil(
                moment(e.target.value).utc().format('YYYY-MM-DDTHH:mm:ss'),
              )
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('unifieds.filters.forcedError')}
            onChange={(e) => setForcedError(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={handleUnifiedFilter}
            style={{ marginTop: 10 }}
          >
            {t('unifieds.table.search')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => setIsBpacksearchModalOpened(true)}
            style={{ marginTop: 10 }}
          >
            {t('unifieds.filters.manualUpdate')}
          </Button>
        </Grid>
      </Grid>

      <CustomMaterialTable
        title=""
        isLoading={dynamicsReducer.loadingResults}
        data={dynamicsReducer.results}
        saveFilters={true}
        filtering={false}
        columns={[
          {
            title: t('unifieds.table.batterypack'),
            field: 'batteryPackId',
          },
          {
            title: t('unifieds.table.soc'),
            field: 'lastBatterySoc',
          },
          {
            title: t('unifieds.table.lastUpdate'),
            field: 'lastConnectionTimestamp',
            render: (rowData) =>
              moment(new Date(rowData.lastUpdateSoc)).format(
                'DD/MM/YYYY HH:mm:ss',
              ),
          },
          {
            title: t('unifieds.details.status'),
            field: 'forcedError',
          },
          // {
          //     title: "VIN",
          //     field: "scooterVin",
          //     render: rowData => displayWithTooltip(rowData.scooterVin, 7)
          // },
          {
            title: t('unifieds.table.ownerId'),
            field: 'ownerId',
            render: (rowData) => displayWithTooltip(rowData.ownerId, 9),
          },
          // {
          //     title: t("unifieds.table.email"),
          //     field: "ownerEmail",
          // }
        ]}
        actions={[
          {
            icon: 'view_list',
            tooltip: 'Ver detalles',
            onClick: (event, rowData) => {
              setisDetailsModalOpened(true)
              setSelectedDynamic(rowData)
            },
          },
        ]}
      />
      <BatteryCheckDetails
        isOpen={isDetailsModalOpened}
        handleClose={() => setisDetailsModalOpened(false)}
        selectedRow={selectedDynamic}
      />

      <Dialog
        fullScreen={fullScreen}
        open={isBpackSearchModalOpened}
        onClose={() => {
          setIsBpacksearchModalOpened(false)
          setSelectAll(false)
        }}
      >
        <DialogTitle> {t('unifieds.table.selectBatteryTitle')} </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={() => setSelectAll(!selectAll)}
                  />
                }
                label="Seleccionar todos"
              />
              <TextField
                fullWidth
                disabled={selectAll}
                type="number"
                onChange={(e) => setBatteryPackSearch(e.target.value)}
                label={'batteryPackId'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={handleForceUpdate}
          >
            {t('unifieds.table.force')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

function mapState(state) {
  const { dynamicsReducer } = state
  return { dynamicsReducer }
}

const actionCreators = {
  getAll: dynamicActions.getAll,
  forceUpdate: dynamicActions.updateReports,
}

export default connect(mapState, actionCreators)(BatteryCheckView)
