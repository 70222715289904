import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddBox'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import UpdateIcon from '@material-ui/icons/Update'
import React from 'react'
import { history } from '../../../helpers/history'
import AlertDialog from '../../alert-dialog'
import Spinner from '../../shared/Spinner'

import CustomMaterialTable from '../../shared/customMaterialTable'
class FirmwareView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      item: {},
    }
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  onClickAcceptModal() {
    this.setState({ openModal: false })
    this.props._delete(this.state.item.id)
  }

  onClickOpenModal(data) {
    this.setState({ openModal: true, item: data })
  }

  componentDidMount() {
    this.props.getFirmwares()
  }

  addUpgradeClick(id) {
    history.push('/upgrades/create/' + id)
  }

  addOnClick() {
    history.push('/firmwares/create')
  }

  upgradesOnClick() {
    history.push('/upgrades')
  }

  filesOnClick() {
    history.push('/files')
  }

  fotasOnClick() {
    history.push('/fotas')
  }

  render() {
    const { firmwareReducer, t } = this.props
    const Wrapper = () => (
      <React.Fragment>
        <Tooltip title={t('fota.firmware.page.wrapper.add')}>
          <IconButton onClick={this.addOnClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('fota.firmware.page.wrapper.files')}>
          <IconButton onClick={this.filesOnClick}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('fota.firmware.page.wrapper.upgrades')}>
          <IconButton onClick={this.upgradesOnClick}>
            <UpdateIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('fota.firmware.page.wrapper.fotas')}>
          <IconButton onClick={this.fotasOnClick}>
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <Wrapper />
        {!firmwareReducer.loadingResults ? (
          <React.Fragment>
            <CustomMaterialTable
              saveFilters={true}
              title={t('fota.firmware.page.title')}
              columns={[
                {
                  title: t('fota.firmware.page.columns.target_type'),
                  field: 'targetType',
                },
                {
                  title: t('fota.firmware.page.columns.target_model'),
                  field: 'targetModel',
                },
                { title: 'Id', field: 'id', hidden: true },
                {
                  title: t('fota.firmware.page.columns.hw_type'),
                  field: 'hardwareType',
                },
                {
                  title: t('fota.firmware.page.columns.hw_model'),
                  field: 'hardwareModel',
                },
                {
                  title: t('fota.firmware.page.columns.fw_version'),
                  field: 'version',
                },
              ]}
              data={firmwareReducer.results}
              actions={[
                {
                  icon: 'view_list',
                  tooltip: t('fota.firmware.page.actions.details'),
                  onClick: (event, rowData) =>
                    this.props.getById(rowData.id, true),
                },
                {
                  icon: 'library_add',
                  tooltip: t('fota.firmware.page.actions.upgrade'),
                  onClick: (event, rowData) => this.addUpgradeClick(rowData.id),
                },
                {
                  icon: 'delete',
                  tooltip: t('fota.firmware.page.actions.delete'),
                  onClick: (event, rowData) => this.onClickOpenModal(rowData),
                },
              ]}
            />
            <AlertDialog
              open={this.state.openModal}
              title={t('fota.firmware.page.alert_dialog.title')}
              onClickAccept={this.onClickAcceptModal}
              onClickCancel={this.onClickCancelModal}
            />
          </React.Fragment>
        ) : (
          <Spinner loading={firmwareReducer.loadingResults} />
        )}
      </React.Fragment>
    )
  }
}

export default FirmwareView
