import { Tooltip, Typography } from '@material-ui/core'
import React from 'react'

export const displayWithTooltip = (column, length = 15) => {
  if (!column) return

  return column.length >= length ? (
    <Tooltip title={column}>
      <Typography variant="inherit">
        {column.substring(0, length) + '...'}
      </Typography>
    </Tooltip>
  ) : (
    column
  )
}
