import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const ChangePassword = ({ data, onConfirm }) => {
  const { t } = useTranslation()
  const [oldPassword, setoldPassword] = useState('')
  const [newPassword, setnewPassword] = useState('')
  const [newPasswordRepear, setnewPasswordRepear] = useState('')

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{ justifyContent: 'center', display: 'flex' }}
      >
        <Grid item xs={6} sm={6}>
          <TextField
            label={t(
              'dashboard.personalData.changePassword.label.currentPassword',
            )}
            type="password"
            autoFocus
            fullWidth
            value={oldPassword}
            onChange={(e) => setoldPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label={t('dashboard.personalData.changePassword.label.newPassword')}
            type="password"
            autoFocus
            fullWidth
            value={newPassword}
            onChange={(e) => setnewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label={t(
              'dashboard.personalData.changePassword.label.repeatPassword',
            )}
            type="password"
            fullWidth
            value={newPasswordRepear}
            onChange={(e) => setnewPasswordRepear(e.target.value)}
            error={newPassword != newPasswordRepear}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm(oldPassword, newPassword)}
            disabled={
              oldPassword === newPassword ||
              newPassword != newPasswordRepear ||
              oldPassword.length === 0 ||
              newPassword.length === 0 ||
              newPasswordRepear.length === 0
            }
          >
            {t('dashboard.personalData.update')}
          </Button>
        </Grid>
      </Grid>
      {newPassword != newPasswordRepear && newPassword != '' && (
        <p style={{ color: 'red' }}>
          {t('dashboard.personalData.passwordsNotEqual')}
        </p>
      )}
      {newPassword === oldPassword && newPassword != '' && (
        <p style={{ color: 'red' }}>
          {t('dashboard.personalData.passwordsEqual')}
        </p>
      )}
    </div>
  )
}

export default ChangePassword
