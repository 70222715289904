import i18next from 'i18next'
import moment from 'moment'
import { firmwareConstants } from '../../../constants/fota/firmware_constants'
import { factory } from '../../../helpers/factory'
import { history } from '../../../helpers/history'
import { firmwareService } from '../../../services/firmwareService'
import { alertActions } from '../alert_actions'

export const firmwareActions = {
  getAll,
  getById,
  getFirmwaresCompatible,
  insert,
  update,
  _delete,
  clearResults,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    firmwareService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: firmwareConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: firmwareConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: firmwareConstants.GETALL_FAILURE, error }
  }
}

function getById(id, redirect) {
  return (dispatch) => {
    dispatch(request(id))
    firmwareService.getById(id).then(
      (firmware) => {
        firmware.createDate = factory.formatDate(
          firmware.createDate,
          'DD/MM/YYYY HH:MM',
        )
        dispatch(success(firmware))
        if (redirect) {
          history.push('/firmwares/' + id)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.getByIdError')),
        )
      },
    )
  }

  function request(id) {
    return { type: firmwareConstants.DETAILS_REQUEST, id }
  }

  function success(firmware) {
    return { type: firmwareConstants.DETAILS_SUCCESS, firmware }
  }

  function failure(error) {
    return { type: firmwareConstants.DETAILS_FAILURE, error }
  }
}

function getFirmwaresCompatible(id) {
  return (dispatch) => {
    dispatch(request(id))
    firmwareService.getById(id).then(
      (firmware) => {
        firmwareService.getAll().then(
          (results) => {
            results.forEach(
              (element) =>
                (element.releasedDate = moment(element.releasedDate)
                  .utc(false)
                  .format('DD/MM/YYYY')),
            )
            results = results.filter(function (item) {
              return (
                item.targetType === firmware.targetType &&
                item.targetModel === firmware.targetModel &&
                item.hardwareType === firmware.hardwareType &&
                item.hardwareModel === firmware.hardwareModel &&
                item.id !== id
              )
            })
            dispatch(success(firmware, results))
          },
          (error) => {
            dispatch(failure(error))
            dispatch(
              alertActions.error(i18next.t('services.firmwares.getAllError')),
            )
          },
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.getAllError')),
        )
      },
    )
  }

  function request(id) {
    return { type: firmwareConstants.GETCOMPATIBLE_REQUEST, id }
  }

  function success(firmware, results) {
    return { type: firmwareConstants.GETCOMPATIBLE_SUCCESS, firmware, results }
  }

  function failure(error) {
    return { type: firmwareConstants.GETCOMPATIBLE_FAILURE, error }
  }
}

function update(firmware) {
  return (dispatch) => {
    dispatch(request())
    firmwareService.update(firmware).then(
      (firmware) => {
        dispatch(success(firmware))
        dispatch(
          alertActions.success(i18next.t('services.firmwares.updateSuccess')),
        )
        history.push('/firmwares')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.updateError')),
        )
      },
    )
  }

  function request() {
    return { type: firmwareConstants.EDIT_REQUEST }
  }

  function success(firmware) {
    return { type: firmwareConstants.EDIT_SUCCESS, firmware }
  }

  function failure(error) {
    return { type: firmwareConstants.EDIT_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: firmwareConstants.CLEAR_RESULTS }
  }
}

function insert(firmware) {
  return (dispatch) => {
    dispatch(request())
    firmwareService.insert(firmware).then(
      (firmware) => {
        dispatch(success(firmware))
        dispatch(
          alertActions.success(i18next.t('services.firmwares.insertSuccess')),
        )
        history.push('/firmwares')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.insertError')),
        )
      },
    )
  }

  function request() {
    return { type: firmwareConstants.ADD_REQUEST }
  }

  function success(firmware) {
    return { type: firmwareConstants.ADD_SUCCESS, firmware }
  }

  function failure(error) {
    return { type: firmwareConstants.ADD_FAILURE, error }
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request())
    firmwareService._delete(id).then(
      (firmware) => {
        dispatch(success(id))
        dispatch(
          alertActions.success(i18next.t('services.firmwares.deleteSuccess')),
        )
        history.push('/firmwares')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.firmwares.deleteError')),
        )
      },
    )
  }

  function request() {
    return { type: firmwareConstants.DELETE_REQUEST }
  }

  function success(id) {
    return { type: firmwareConstants.DELETE_SUCCESS, id }
  }

  function failure(error) {
    return { type: firmwareConstants.DELETE_FAILURE, error }
  }
}
