export const scooterConstants = {
  SAVE_CENTER_MAP: 'SCOOTERS_SAVE_CENTER_MAP',

  FILTER_REQUEST: 'SCOOTERS_FILTER_REQUEST',
  FILTER_SUCCESS: 'SCOOTERS_FILTER_SUCCESS',
  FILTER_FAILURE: 'SCOOTERS_FILTER_FAILURE',

  GET_DATA_SCOOTER_BY_ID_REQUEST: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_REQUEST',
  GET_DATA_SCOOTER_BY_ID_SUCCESS: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_SUCCESS',
  GET_DATA_SCOOTER_BY_ID_FAILURE: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_FAILURE',

  GETALL_REQUEST: 'SCOOTERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SCOOTERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SCOOTERS_GETALL_FAILURE',

  DETAILS_REQUEST: 'SCOOTERS_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'SCOOTERS_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'SCOOTERS_DETAILS_FAILURE',

  TRIPS_REQUEST: 'SCOOTERS_TRIPS_REQUEST',
  TRIPS_SUCCESS: 'SCOOTERS_TRIPS_SUCCESS',
  TRIPS_FAILURE: 'SCOOTERS_TRIPS_FAILURE',

  TRIPS_DETAIL_REQUEST: 'SCOOTERS_TRIPS_DETAIL_REQUEST',
  TRIPS_DETAIL_SUCCESS: 'SCOOTERS_TRIPS_DETAIL_SUCCESS',
  TRIPS_DETAIL_FAILURE: 'SCOOTERS_TRIPS_DETAIL_FAILURE',

  GETALL_DYNAMIC_REQUEST: 'SCOOTERS_GET_ALL_DYNAMIC_REQUEST',
  GETALL_DYNAMIC_SUCCESS: 'SCOOTERS_GET_ALL_DYNAMIC_SUCCESS',
  GETALL_DYNAMIC_FAILURE: 'SCOOTERS_GET_ALL_DYNAMIC_FAILURE',

  GET_DYNAMIC_REQUEST: 'SCOOTERS_GET_DYNAMIC_REQUEST',
  GET_DYNAMIC_SUCCESS: 'SCOOTERS_GET_DYNAMIC_SUCCESS',
  GET_DYNAMIC_FAILURE: 'SCOOTERS_GET_DYNAMIC_FAILURE',

  GET_ALL_MAP_BY_OPERATOR_ID_REQUEST:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_REQUEST',
  GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS',
  GET_ALL_MAP_BY_OPERATOR_ID_FAILURE:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_FAILURE',

  CLEAR_RESULTS: 'SCOOTERS_CLEAR_RESULTS',

  DELETE_REQUEST: 'SCOOTERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SCOOTERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SCOOTERS_DELETE_FAILURE',

  GET_MISSING_DYNAMICS_REQUEST: 'SCOOTERS_GET_MISSING_DYNAMICS_REQUEST',
  GET_MISSING_DYNAMICS_SUCCESS: 'SCOOTERS_GET_MISSING_DYNAMICS_SUCCESS',
  GET_MISSING_DYNAMICS_FAILURE: 'SCOOTERS_GET_MISSING_DYNAMICS_FAILURE',

  GET_SCOOTER_BY_OEM_ID_REQUEST: 'SCOOTERS_GET_SCOOTER_BY_OEM_ID_REQUEST',
  GET_SCOOTER_BY_OEM_ID_SUCCESS: 'SCOOTERS_GET_SCOOTER_BY_OEM_ID_SUCCESS',
  GET_SCOOTER_BY_OEM_ID_FAILURE: 'SCOOTERS_GET_SCOOTER_BY_OEM_ID_FAILURE',

  SEND_COMMAND_REQUEST: 'SCOOTERS_SEND_COMMAND_REQUEST',
  SEND_COMMAND_SUCCESS: 'SCOOTERS_SEND_COMMAND_SUCCESS',
  SEND_COMMAND_FAILURE: 'SCOOTERS_SEND_COMMAND_FAILURE',

  SHOW_MESSAGES_ASSIGN_SCOOTER: 'SCOOTERS_SHOW_MESSAGES_ASSIGN_SCOOTER',
  HIDDE_MESSAGES_ASSIGN_SCOOTER: 'SCOOTERS_HIDDE_MESSAGES_ASSIGN_SCOOTER',

  GET_IMAGE_SUCCESS: 'SCOOTERS_GET_IMAGE_SUCCESS',

  GET_RSDK_BY_VEHICLE_ID_REQUEST: 'GET_RSDK_BY_VEHICLE_ID_REQUEST',
  GET_RSDK_BY_VEHICLE_ID_SUCCESS: 'GET_RSDK_BY_VEHICLE_ID_SUCCESS',
  GET_RSDK_BY_VEHICLE_ID_FAILURE: 'GET_RSDK_BY_VEHICLE_ID_FAILURE',

  RESET_RSDK_REQUEST: 'RESET_RSDK_REQUEST',
  RESET_RSDK_SUCCESS: 'RESET_RSDK_SUCCESS',
  RESET_RSDK_FAILURE: 'RESET_RSDK_FAILURE',
}
