import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Snackbar,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import MuiAlert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { alertActions } from '../../redux/actions/alert_actions'
import { userActions } from '../../redux/actions/user_actions'
import ListItems from './listItems'
import MenuUser from './menu'
import useStyles from './styles'

import PersonalData from './dialog/personalData'
import SelectRole from './dialog/selectRole'

const Dashboardview = (props) => {
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const classes = props.styles
  const {
    alertReducer,
    authenticationReducer,
    isCollapseUsersMenuOpen,
    isCollapseSubscriptionsMenuOpen,
    setIsCollapseUsersMenuOpen,
    setIsCollapseSubscriptionsMenuOpen,
  } = props
  const { t } = props
  const [showDialogSelectorRoles, setshowDialogSelectorRoles] = useState(false)
  const [isDialogPersonalDataOpen, setisDialogPersonalDataOpen] =
    useState(false)

  const handleChangeRole = () => {
    setshowDialogSelectorRoles(true)
  }
  const handleOpenPersonalData = () => {
    setisDialogPersonalDataOpen(true)
  }

  const handleSelected = (selected) => {
    setshowDialogSelectorRoles(false)
    props.selectRole(selected)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, props.open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              props.open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <ListItem button component={Link} to="/home">
              My Silence
            </ListItem>
          </Typography>

          <MenuUser
            t={t}
            onChangeRole={handleChangeRole}
            onOpenPersonalData={handleOpenPersonalData}
            logout={props.logout}
            // name={authenticationReducer.user.name || authenticationReducer.user.email}
            name={authenticationReducer.token.email}
            cssClass={classes.username}
            changeLanguage={props.changeLanguage}
          />
        </Toolbar>
      </AppBar>
      <SelectRole
        isOpen={showDialogSelectorRoles}
        data={authenticationReducer.usersGroup} //
        onSelected={handleSelected}
      />
      <PersonalData
        isOpen={isDialogPersonalDataOpen}
        onClose={() => setisDialogPersonalDataOpen(false)}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !props.open && classes.drawerPaperClose,
          ),
        }}
        open={props.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeftIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItems
            t={t}
            isCollapseUsersMenuOpen={isCollapseUsersMenuOpen}
            isCollapseSubscriptionsMenuOpen={isCollapseSubscriptionsMenuOpen}
            setIsCollapseUsersMenuOpen={setIsCollapseUsersMenuOpen}
            setIsCollapseSubscriptionsMenuOpen={
              setIsCollapseSubscriptionsMenuOpen
            }
          />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper elevation={0}>
                {alertReducer.message && (
                  <Snackbar
                    open={alertReducer.message ? true : false}
                    autoHideDuration={3000}
                    onClose={props.handleCloseAlert}
                  >
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      onClose={props.handleCloseAlert}
                      severity={alertReducer.type}
                    >
                      {alertReducer.message}
                    </MuiAlert>
                  </Snackbar>
                )}
                {props.component}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>{/* <Copyright /> */}</Box>
        </Container>
      </main>
    </div>
  )
}

function Hook(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const [isCollapseUsersMenuOpen, setIsCollapseUsersMenuOpen] = useState(true)
  const [isCollapseSubscriptionsMenuOpen, setIsCollapseSubscriptionsMenuOpen] =
    useState(true)
  const [openAlert] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
    setIsCollapseUsersMenuOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
    setIsCollapseUsersMenuOpen(false)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.clear()
  }

  return (
    <Dashboardview
      styles={classes}
      component={props.component}
      open={open}
      logout={props.logout}
      alertReducer={props.alertReducer}
      authenticationReducer={props.authenticationReducer}
      isCollapseUsersMenuOpen={isCollapseUsersMenuOpen}
      isCollapseSubscriptionsMenuOpen={isCollapseSubscriptionsMenuOpen}
      setIsCollapseUsersMenuOpen={setIsCollapseUsersMenuOpen}
      setIsCollapseSubscriptionsMenuOpen={setIsCollapseSubscriptionsMenuOpen}
      setOpen={setOpen}
      openAlert={openAlert}
      {...props}
      handleDrawerOpen={handleDrawerOpen}
      handleDrawerClose={handleDrawerClose}
      handleCloseAlert={handleCloseAlert}
    />
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer } = state
  return { alertReducer, authenticationReducer }
}

const actionCreators = {
  clear: alertActions.clear,
  logout: userActions.logout,
  selectRole: userActions.selectRole,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
