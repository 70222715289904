import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddBox'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { history } from '../../../helpers/history'
import AlertDialog from '../../alert-dialog'
import Spinner from '../../shared/Spinner'

import CustomMaterialTable from '../../shared/customMaterialTable'
class FileView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      item: {},
    }
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
  }

  onClickComeBack() {
    history.push('/firmwares')
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  onClickAcceptModal() {
    this.setState({ openModal: false })
    this.props._delete(this.state.item.id)
  }

  onClickOpenModal(data) {
    this.setState({ openModal: true, item: data })
  }

  componentDidMount() {
    this.props.getFiles()
  }

  addOnClick() {
    history.push('/files/create')
  }

  render() {
    const { fileReducer, t } = this.props
    const Wrapper = () => (
      <React.Fragment>
        <Tooltip title={t('fota.file.page.wrapper.back')}>
          <IconButton onClick={this.onClickComeBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('fota.file.page.wrapper.add')}>
          <IconButton onClick={this.addOnClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <Wrapper />
        {!fileReducer.loadingResults ? (
          <React.Fragment>
            <CustomMaterialTable
              saveFilters={true}
              title={t('fota.file.page.title')}
              columns={[
                {
                  title: t('fota.file.page.columns.target_type'),
                  field: 'targetType',
                },
                {
                  title: t('fota.file.page.columns.target_model'),
                  field: 'targetModel',
                },
                { title: 'Id', field: 'id', hidden: true },
                {
                  title: t('fota.file.page.columns.new_version'),
                  field: 'containedFirmwaresVersions',
                },
                {
                  title: t('fota.file.page.columns.types'),
                  field: 'containedFirmwaresTypes',
                },
                {
                  title: t('fota.file.page.columns.file_name'),
                  field: 'fileName',
                },
              ]}
              data={fileReducer.results}
              actions={[
                {
                  icon: 'view_list',
                  tooltip: t('fota.file.page.actions.detail'),
                  onClick: (event, rowData) => this.props.getById(rowData.id),
                },
                {
                  icon: 'delete',
                  tooltip: t('fota.file.page.actions.delete'),
                  onClick: (event, rowData) => this.onClickOpenModal(rowData),
                },
              ]}
            />
            <AlertDialog
              open={this.state.openModal}
              title={t('fota.file.page.alert_dialog.title')}
              onClickAccept={this.onClickAcceptModal}
              onClickCancel={this.onClickCancelModal}
            />
          </React.Fragment>
        ) : (
          <Spinner loading={fileReducer.loadingResults} />
        )}
      </React.Fragment>
    )
  }
}

export default FileView
