import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { upgradeActions } from '../../../redux/actions/fota/upgrade_actions'
import Dashboard from '../../dashboard'
import UpgradeView from './page'

class Upgrades extends React.Component {
  render() {
    return (
      <Dashboard
        component={
          <UpgradeView
            getUpgrades={this.props.getUpgrades}
            getById={this.props.getById}
            _delete={this.props._delete}
            upgradeReducer={this.props.upgradeReducer}
            t={this.props.t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { upgradeReducer } = state
  return { upgradeReducer }
}

const actionCreators = {
  getUpgrades: upgradeActions.getAll,
  getById: upgradeActions.getById,
  _delete: upgradeActions._delete,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Upgrades)
