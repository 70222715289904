import React from 'react'
import FileFormView from './page'

class FileForm extends React.Component {
  render() {
    return <FileFormView {...this.props} />
  }
}

export default FileForm
