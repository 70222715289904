import React, { Fragment, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

// import { servicesActions } from "../../redux/actions/services_actions";
// import { connect } from "react-redux";

import CustomMaterialTable from '../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const MonthlyDetailsView = ({ servicesReducer, getMonthlySummary }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // useEffect(() => {
  //   getMonthlySummary();
  // }, []);
  const [selectedRow, setselectedRow] = useState({})

  return (
    <Fragment>
      <CustomMaterialTable
        addSummaryRow={true}
        title={t('monthlyDetails.page.table.title')}
        data={[]}
        saveFilters={true}
        columns={[
          {
            title: t('monthlyDetails.page.table.columns.month'),
            field: 'month',
          },
          {
            title: t('monthlyDetails.page.table.columns.nServices'),
            field: 'totalCount',
          },
          {
            title: t('monthlyDetails.page.table.columns.totalDistance'),
            field: 'totalDistanceKms',
          },
          {
            title: t('monthlyDetails.page.table.columns.totalTime'),
            field: 'totalDrivenMinutes',
          },
          {
            title: t('monthlyDetails.page.table.columns.totalPrice'),
            field: 'totalPrice',
          },
        ]}
        actions={[
          {
            icon: 'picture_as_pdf',
            tooltip: t('monthlyDetails.page.table.action.pdf'),
            // onClick: (event) => setisNewTechnicianFromOpen(true),
          },
        ]}
      />
    </Fragment>
  )
}

// function mapState(state) {
//   const { servicesReducer } = state;
//   return { servicesReducer };
// }

// const actionCreators = {
//   getMonthlySummary: servicesActions.getMonthlySummary,
// };
export default MonthlyDetailsView //connect(mapState, actionCreators)(MonthlyDetailsView);
