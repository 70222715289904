import { dynamicConstants } from '../../constants'
import { dynamicsService } from '../../services/dynamicsServices'
import { alertActions } from '../actions/alert_actions'

export const dynamicActions = {
  getAll,
  updateReports,
}

function getAll(body = {}) {
  return (dispatch) => {
    dispatch(request())
    dynamicsService.getAll(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: dynamicConstants.GET_UNIFIED_REQUEST }
  }

  function success(results) {
    return { type: dynamicConstants.GET_UNIFIED_SUCCESS, results }
  }

  function failure(error) {
    return { type: dynamicConstants.GET_UNIFIED_FAILURE, error }
  }
}

function updateReports(bpackId = '') {
  return (dispatch) => {
    dispatch(request())
    dynamicsService.updateReport(bpackId).then(
      (results) => {
        dispatch(success(results))
        dispatch(alertActions.success('Dynamics updated successfully'))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error('Some error ocurres while updating dynamics'),
        )
      },
    )
  }

  function request() {
    return { type: dynamicConstants.UPDATE_REPORT_REQUEST }
  }

  function success(results) {
    return { type: dynamicConstants.UPDATE_REPORT_SUCCESS, results }
  }

  function failure(error) {
    return { type: dynamicConstants.UPDATE_REPORT_FAILURE, error }
  }
}
