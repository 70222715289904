import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import { history } from '../../../../helpers/history'
import FirmwareForm from '../form/index'

class FirmwareCreateView extends React.Component {
  constructor(props) {
    super(props)
    this.onClickCreate = this.onClickCreate.bind(this)
  }

  onClickComeBack() {
    history.goBack()
  }

  onClickCreate = (firmware) => {
    if (firmware.type !== 'click') {
      console.log(JSON.stringify(firmware))
      this.props.insert(firmware)
    }
  }

  render() {
    const { t } = this.props
    return (
      <FirmwareForm
        readOnly={false}
        {...this.props}
        onClickLeftButton={this.onClickComeBack}
        onClickRightButton={this.onClickCreate}
        leftIcon={<KeyboardBackspaceIcon />}
        rightIcon={<SaveIcon />}
        leftButtonValue={t('fota.firmware.create.page.leftButtonValue')}
        rightButtonValue={t('fota.firmware.create.page.rightButtonValue')}
        typeButton="submit"
      />
    )
  }
}

export default FirmwareCreateView
