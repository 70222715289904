import React from 'react'
import Dashboard from '../../dashboard'
import SilenceUserView from './silenceUser'

class NewSilenceUser extends React.Component {
  render() {
    return <Dashboard component={<SilenceUserView />} />
  }
}

export default NewSilenceUser
