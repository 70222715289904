import cat from './default.cat.json'
import en from './default.en.json'
import es from './default.json'

import oem_cat from '../../components/OEM/locales/default.cat.json'
import oem_en from '../../components/OEM/locales/default.en.json'
import oem_es from '../../components/OEM/locales/default.json'
import ui_cat from '../../components/UI/locales/default.cat.json'
import ui_en from '../../components/UI/locales/default.en.json'
import ui_es from '../../components/UI/locales/default.json'
import alert_dialog_cat from '../../components/alert-dialog/locales/default.cat.json'
import alert_dialog_en from '../../components/alert-dialog/locales/default.en.json'
import alert_dialog_es from '../../components/alert-dialog/locales/default.json'
import assignScooter_cat from '../../components/assignScooter/locales/default.cat.json'
import assignScooter_en from '../../components/assignScooter/locales/default.en.json'
import assignScooter_es from '../../components/assignScooter/locales/default.json'
import unifieds_cat from '../../components/batteryCheck/locales/default.cat.json'
import unifieds_en from '../../components/batteryCheck/locales/default.en.json'
import unifieds_es from '../../components/batteryCheck/locales/default.json'
import customers_cat from '../../components/customers/locales/default.cat.json'
import customers_en from '../../components/customers/locales/default.en.json'
import customers_es from '../../components/customers/locales/default.json'
import dashboard_cat from '../../components/dashboard/locales/default.cat.json'
import dashboard_en from '../../components/dashboard/locales/default.en.json'
import dashboard_es from '../../components/dashboard/locales/default.json'
import deletedusers_cat from '../../components/deletedUsers/locales/default.cat.json'
import deletedusers_en from '../../components/deletedUsers/locales/default.en.json'
import deletedusers_es from '../../components/deletedUsers/locales/default.json'
import fota_file_cat from '../../components/fota/file/locales/default.cat.json'
import fota_file_en from '../../components/fota/file/locales/default.en.json'
import fota_file_es from '../../components/fota/file/locales/default.json'
import fota_firmware_cat from '../../components/fota/firmware/locales/default.cat.json'
import fota_firmware_en from '../../components/fota/firmware/locales/default.en.json'
import fota_firmware_es from '../../components/fota/firmware/locales/default.json'
import fota_fota_cat from '../../components/fota/fota/locales/default.cat.json'
import fota_fota_en from '../../components/fota/fota/locales/default.en.json'
import fota_fota_es from '../../components/fota/fota/locales/default.json'
import fota_upgrade_cat from '../../components/fota/upgrade/locales/default.cat.json'
import fota_upgrade_en from '../../components/fota/upgrade/locales/default.en.json'
import fota_upgrade_es from '../../components/fota/upgrade/locales/default.json'
import home_cat from '../../components/home/locales/default.cat.json'
import home_en from '../../components/home/locales/default.en.json'
import home_es from '../../components/home/locales/default.json'
import incidents_cat from '../../components/incidents/locales/default.cat.json'
import incidents_en from '../../components/incidents/locales/default.en.json'
import incidents_es from '../../components/incidents/locales/default.json'
import login_cat from '../../components/login/locales/default.cat.json'
import login_en from '../../components/login/locales/default.en.json'
import login_es from '../../components/login/locales/default.json'
import monthlyDetails_cat from '../../components/monthlyDetails/locales/default.cat.json'
import monthlyDetails_en from '../../components/monthlyDetails/locales/default.en.json'
import monthlyDetails_es from '../../components/monthlyDetails/locales/default.json'
import notifications_cat from '../../components/notifications/locales/default.cat.json'
import notifications_en from '../../components/notifications/locales/default.en.json'
import notifications_es from '../../components/notifications/locales/default.json'
import operator_cat from '../../components/operator/locales/default.cat.json'
import operator_en from '../../components/operator/locales/default.en.json'
import operator_es from '../../components/operator/locales/default.json'
import reports_cat from '../../components/reports/locales/default.cat.json'
import reports_en from '../../components/reports/locales/default.en.json'
import reports_es from '../../components/reports/locales/default.json'
import scooters_cat from '../../components/scooters/locales/default.cat.json'
import scooters_en from '../../components/scooters/locales/default.en.json'
import scooters_es from '../../components/scooters/locales/default.json'
import statistics_cat from '../../components/statistics/locales/default.cat.json'
import statistics_en from '../../components/statistics/locales/default.en.json'
import statistics_es from '../../components/statistics/locales/default.json'
import users_cat from '../../components/users/locales/default.cat.json'
import users_en from '../../components/users/locales/default.en.json'
import users_es from '../../components/users/locales/default.json'
import constansts_cat from '../../constants/locales/default.cat.json'
import constansts_en from '../../constants/locales/default.en.json'
import constansts_es from '../../constants/locales/default.json'
import services_cat from '../../services/locales/default.cat.json'
import services_en from '../../services/locales/default.en.json'
import services_es from '../../services/locales/default.json'

es['es']['alert-dialog'] = alert_dialog_es['es']
en['en']['alert-dialog'] = alert_dialog_en['en']
cat['cat']['alert-dialog'] = alert_dialog_cat['cat']
es['es'].dashboard = dashboard_es['es']
en['en'].dashboard = dashboard_en['en']
cat['cat'].dashboard = dashboard_cat['cat']
es['es'].customers = customers_es['es']
en['en'].customers = customers_en['en']
cat['cat'].customers = customers_cat['cat']
es['es'].fota.file = fota_file_es['es']
en['en'].fota.file = fota_file_en['en']
cat['cat'].fota.file = fota_file_cat['cat']
es['es'].fota.firmware = fota_firmware_es['es']
en['en'].fota.firmware = fota_firmware_en['en']
cat['cat'].fota.firmware = fota_firmware_cat['cat']
es['es'].fota.fota = fota_fota_es['es']
en['en'].fota.fota = fota_fota_en['en']
cat['cat'].fota.fota = fota_fota_cat['cat']
es['es'].fota.upgrade = fota_upgrade_es['es']
en['en'].fota.upgrade = fota_upgrade_en['en']
cat['cat'].fota.upgrade = fota_upgrade_cat['cat']
es['es'].scooters = scooters_es['es']
en['en'].scooters = scooters_en['en']
cat['cat'].scooters = scooters_cat['cat']
es['es'].statistics = statistics_es['es']
en['en'].statistics = statistics_en['en']
cat['cat'].statistics = statistics_cat['cat']
es['es'].material_table = constansts_es['es']
en['en'].material_table = constansts_en['en']
cat['cat'].material_table = constansts_cat['cat']
es['es'].validations = constansts_es['es'].validations
en['en'].validations = constansts_en['en'].validations
cat['cat'].validations = constansts_cat['cat'].validations
es['es'].services = services_es['es'].services
en['en'].services = services_en['en'].services
cat['cat'].services = services_cat['cat'].services
es['es'].incidents = incidents_es['es']
en['en'].incidents = incidents_en['en']
cat['cat'].incidents = incidents_cat['cat']
es['es'].operator = operator_es['es']
en['en'].operator = operator_en['en']
cat['cat'].operator = operator_cat['cat']
es['es'].reports = reports_es['es']
en['en'].reports = reports_en['en']
cat['cat'].reports = reports_cat['cat']
es['es'].assignScooter = assignScooter_es['es']
en['en'].assignScooter = assignScooter_en['en']
cat['cat'].assignScooter = assignScooter_cat['cat']
es['es'].ui = ui_es['es']
en['en'].ui = ui_en['en']
cat['cat'].ui = ui_cat['cat']
es['es'].login = login_es['es']
en['en'].login = login_en['en']
cat['cat'].login = login_cat['cat']
es['es'].oem = oem_es['es']
en['en'].oem = oem_en['en']
cat['cat'].oem = oem_cat['cat']
es['es'].users = users_es['es']
en['en'].users = users_en['en']
cat['cat'].users = users_cat['cat']
es['es'].home = home_es['es']
en['en'].home = home_en['en']
cat['cat'].home = home_cat['cat']
es['es'].notifications = notifications_es['es']
en['en'].notifications = notifications_en['en']
cat['cat'].notifications = notifications_cat['cat']
es['es'].monthlyDetails = monthlyDetails_es['es']
en['en'].monthlyDetails = monthlyDetails_en['en']
cat['cat'].monthlyDetails = monthlyDetails_cat['cat']
es['es'].unifieds = unifieds_es['es']
en['en'].unifieds = unifieds_en['en']
cat['cat'].unifieds = unifieds_cat['cat']
es['es'].deletedUsers = deletedusers_es['es']
en['en'].deletedUsers = deletedusers_en['en']
cat['cat'].deletedUsers = deletedusers_cat['cat']

export { cat, en, es }
