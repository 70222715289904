import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import ExploreIcon from '@material-ui/icons/Explore'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import stringConstants from '../../../../constants/strings'
import { formatDateToDisplay } from '../../../shared/formatDate'
import useStyles from './styles'

import { useTranslation } from 'react-i18next'

const TripsScooterView = ({
  idScooter,
  getTripDetail,
  scooterReducer,
  isDailyTrips = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [filterTrips, setfilterTrips] = useState([])
  const defaultProps = {
    center: {
      lat: 41.390205,
      lng: 2.154007,
    },
    zoom: 11,
  }

  const trimString = (string, length) => {
    var trimmedString = string
      ? string.length > length
        ? string.substring(0, length - 3) + '...'
        : string
      : 'undefined'
    return trimmedString
  }

  const getTrip = (scooterId, tripId) => {
    getTripDetail(scooterId, tripId)
  }
  const { tripDetail } = scooterReducer

  const handleApiLoaded = (map, maps) => {
    var latlngList = []

    var points = []
    tripDetail.points.forEach((element) => {
      points.push({
        lat: element.lat,
        lng: element.lon,
      })
      latlngList.push(new maps.LatLng(element.lat, element.lon))
    })

    var bounds = new maps.LatLngBounds()

    latlngList.forEach(function (n) {
      bounds.extend(n)
    })

    map.setCenter(bounds.getCenter())
    map.fitBounds(bounds)

    new maps.Marker({
      map: map,
      animation: maps.Animation.DROP,
      icon: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
      position: points[0],
    })

    new maps.Marker({
      map: map,
      animation: maps.Animation.DROP,
      icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png',
      position: points[points.length - 1],
    })

    const trip = new maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: 'black',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    trip.setMap(map)
  }

  const isTripFromToday = (date) => {
    const currentDate = new Date()
    const incomeDate = new Date(date)
    return (
      `${currentDate.getDate()}:${currentDate.getMonth()}:${currentDate.getFullYear()}` ===
      `${incomeDate.getDate()}:${incomeDate.getMonth()}:${incomeDate.getFullYear()}`
    )
  }

  useEffect(() => {
    const filter = scooterReducer.trips.filter((t) =>
      isTripFromToday(new Date(t.startTimestamp)),
    )

    setfilterTrips(
      isDailyTrips ? filter.reverse() : scooterReducer.trips.reverse(),
    )
  }, [scooterReducer.trips])

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Grid item md={6}>
          <Paper style={{ maxHeight: 563, overflow: 'auto' }}>
            <List>
              {filterTrips.length > 0 ? (
                <>
                  <h3>{t('scooters.details.trips.page.detailTrip.trips')}</h3>
                  {filterTrips.map((item) => (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => getTrip(idScooter, item.id)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={
                            scooterReducer.tripDetail &&
                            scooterReducer.tripDetail.id === item.id
                              ? classes.activeTrip
                              : null
                          }
                        >
                          <ExploreIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          t('scooters.details.trips.page.detailTrip.from') +
                          trimString(item.fromDescription, 25) +
                          t('scooters.details.trips.page.detailTrip.to') +
                          trimString(item.toDescription, 25)
                        }
                        secondary={
                          moment(item.startTimestamp).format(
                            'DD/MM/YYYY HH:mm',
                          ) +
                          ' ' +
                          item.distance +
                          'm'
                        }
                      />
                    </ListItem>
                  ))}
                </>
              ) : (
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                  {' '}
                  {t('scooters.details.trips.page.detailTrip.noTrips')}{' '}
                </Typography>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item md={6}>
          {Object.keys(scooterReducer.tripDetail).length != 0 && (
            <div className={classes.tripDetails}>
              <strong>
                {t('scooters.details.trips.page.detailTrip.trips')}
              </strong>
              <br />
              <div className={classes.streetDirectionsGroup}>
                <span>
                  {t('scooters.details.trips.page.detailTrip.from')}{' '}
                  {tripDetail.fromDescription}
                </span>
                <br />
                <span>
                  {t('scooters.details.trips.page.detailTrip.to')}{' '}
                  {tripDetail.toDescription}
                </span>
              </div>

              <div className={classes.group}>
                <span>
                  {t('scooters.details.trips.page.detailTrip.distance')}{' '}
                  {tripDetail.distance}m
                </span>
                <span>
                  {t('scooters.details.trips.page.detailTrip.coSave')}{' '}
                  {(Math.round(tripDetail.distance * 100) / 100).toFixed(2)}
                </span>
              </div>
              <div className={classes.group}>
                <span>
                  {t('scooters.details.trips.page.detailTrip.start')}{' '}
                  {formatDateToDisplay(tripDetail.startTimestamp, true)}
                </span>
                <span>
                  {t('scooters.details.trips.page.detailTrip.finish')}{' '}
                  {formatDateToDisplay(tripDetail.endTimestamp, true)}
                </span>
              </div>
              <div className={classes.group}>
                <span>
                  {t('scooters.details.trips.page.detailTrip.start_battery')}{' '}
                  {tripDetail.startBattery}%
                </span>
                <span>
                  {t('scooters.details.trips.page.detailTrip.finish_battery')}{' '}
                  {tripDetail.endBattery}%
                </span>
              </div>
              <div className={classes.group}>
                <span>
                  {t('scooters.details.trips.page.detailTrip.speed_avg')}{' '}
                  {(Math.round(tripDetail.speedAvg * 100) / 100).toFixed(2)}km/h
                </span>
                <span>
                  {t('scooters.details.trips.page.detailTrip.speed_max')}{' '}
                  {tripDetail.speedMax}km/h
                </span>
              </div>
            </div>
          )}
          <div style={{ height: '60vh', width: '100%' }}>
            {!scooterReducer.loadingResults &&
            Object.keys(scooterReducer.tripDetail).length != 0 ? (
              <GoogleMapReact
                bootstrapURLKeys={{ key: stringConstants.GOOGLE_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              ></GoogleMapReact>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default TripsScooterView
