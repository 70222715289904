import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { useTranslation } from 'react-i18next'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const AssignScooterManager = ({
  isOpen,
  onClose,
  scooterSelected,
  onAssign,
  onUnassign,
}) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  const headerStyle = { display: 'flex', alignItems: 'center' }
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('assignScooter.assignScooterManager.title')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t('assignScooter.assignScooterManager.dialogContent')}
          </Typography>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div style={headerStyle}>
                  <Typography variant="h6">
                    {t('assignScooter.assignScooterManager.assign')}
                  </Typography>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={onAssign}
                    disabled={
                      scooterSelected &&
                      scooterSelected.filter((s) => !s.hasOwner).length === 0
                    }
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
                <div>
                  <List dense={true}>
                    {scooterSelected
                      .filter((s) => !s.hasOwner)
                      .map((s) => {
                        return (
                          <ListItem>
                            <ListItemText
                              primary={`${t(
                                'assignScooter.assignScooterManager.assign',
                              )}: ${s.plate || 'Undefined'}`}
                            />
                          </ListItem>
                        )
                      })}
                  </List>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={headerStyle}>
                  <Typography variant="h6">
                    {t('assignScooter.assignScooterManager.unassign')}
                  </Typography>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={onUnassign}
                    disabled={
                      scooterSelected.filter((s) => s.hasOwner).length === 0
                    }
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </div>
                <div>
                  <List dense={true}>
                    {scooterSelected
                      .filter((s) => s.hasOwner)
                      .map((s) => {
                        return (
                          <ListItem>
                            <ListItemText
                              primary={`${t(
                                'assignScooter.assignScooterManager.assign',
                              )}: ${s.plate || 'Undefined'}`}
                              secondary={`${t(
                                'assignScooter.assignScooterManager.owner',
                              )}: ${s.ownerMail || 'Undefined'}`}
                            />
                          </ListItem>
                        )
                      })}
                  </List>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('assignScooter.assignScooterManager.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AssignScooterManager
