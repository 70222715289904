import {
  AppBar,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BatteryCheckDetails = ({ selectedRow, isOpen, handleClose }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Unified details</Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Grid
            container
            spacing={1}
            style={{ padding: '1%', maxWidth: '100%' }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Battery Pack Id"
                value={selectedRow.batteryPackId}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.details.stationId')}
                value={selectedRow.lastStationId}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.details.scooterId')}
                value={selectedRow.lastScooterId}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.details.ownerId')}
                value={selectedRow.ownerId}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            {/* <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="VIN"
                            value={selectedRow.scooterVin}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid> */}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.filters.lastUpdate')}
                value={moment(new Date(selectedRow.lastUpdateSoc)).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="SOC"
                value={selectedRow.lastBatterySoc}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.filters.source')}
                value={selectedRow.lastUpdateFrom}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('unifieds.details.status')}
                value={selectedRow.forcedError}
                inputProps={{ readOnly: true }}
              />
            </Grid>

            {/* <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.rowLetter") }
                            value={selectedRow.rowLetter}
                            inputProps={{ readOnly: true }}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.slotNumber") }
                            value={selectedRow.slotNumber}
                            inputProps={{ readOnly: true }}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.temperature") }
                            value={selectedRow.temperature}
                            inputProps={{ readOnly: true }}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.maxTemperature") }
                            value={selectedRow.maxTemperature}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid> 

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.energyDischarged") }
                            value={selectedRow.energyDischarged}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.chargedEnergy") }
                            value={selectedRow.energyCharged}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={ t("unifieds.details.regeneratedEnergy") }
                            value={selectedRow.energyRegenerated}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid> */}
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

export default BatteryCheckDetails
