import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddBox'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { history } from '../../../helpers/history'
import AlertDialog from '../../alert-dialog'
import Spinner from '../../shared/Spinner'
import FilterFotas from './filter'

import CustomMaterialTable from '../../shared/customMaterialTable'
class FotasView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
    this.onClickCancelModal = this.onClickCancelModal.bind(this)
    this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
  }

  onClickComeBack() {
    history.push('/firmwares')
  }

  onClickCancelModal() {
    this.setState({ openModal: false })
  }

  addOnClick() {
    history.push('/fotas/create')
  }

  detailsOnClick(id) {
    history.push('/fotas/' + id)
  }

  onClickAcceptModal() {
    this.setState({ openModal: false })
    this.props.cancel(this.state.item.id)
  }

  onClickOpenModal(data) {
    this.setState({ openModal: true, item: data })
  }

  render() {
    const { fotaReducer, t } = this.props
    const Wrapper = () => (
      <React.Fragment>
        <Tooltip title={t('fota.fota.page.wrapper.back')}>
          <IconButton onClick={this.onClickComeBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('fota.fota.page.wrapper.add')}>
          <IconButton onClick={this.addOnClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <Spinner loading={fotaReducer.loadingResults} />
        <FilterFotas
          t={t}
          getProcessIds={this.props.getProcessIds}
          filter={this.props.filter}
          fotaReducer={this.props.fotaReducer}
          loading={fotaReducer.loadingResults}
        />
        <Wrapper />
        {!fotaReducer.loadingResults ? (
          <React.Fragment>
            <CustomMaterialTable
              saveFilters={true}
              title={t('fota.fota.page.title')}
              columns={[
                { title: t('fota.fota.page.columns.fotaId'), field: 'id' },
                { title: t('fota.fota.page.columns.vin'), field: 'vin' },
                {
                  title: t('fota.fota.page.columns.startDateProcess'),
                  field: 'processStartDate',
                },
                {
                  title: t('fota.fota.page.columns.expireDateProcess'),
                  field: 'processExpireDate',
                },
                {
                  title: t('fota.fota.page.columns.fileName'),
                  field: 'silFileName',
                },
                {
                  title: t('fota.fota.page.columns.target_type'),
                  field: 'targetType',
                },
                {
                  title: t('fota.fota.page.columns.target_model'),
                  field: 'targetModel',
                },
                {
                  title: t('fota.fota.page.columns.retries'),
                  field: 'notifiedCount',
                },
                {
                  title: t('fota.fota.page.columns.fw_oldVersion'),
                  field: 'oldVersions',
                },
                {
                  title: t('fota.fota.page.columns.fw_newVersion'),
                  field: 'newVersions',
                },
                {
                  title: t('fota.fota.page.columns.updateResult'),
                  field: 'updateResult',
                },
                { title: t('fota.fota.page.columns.status'), field: 'status' },
                {
                  title: t('fota.fota.page.columns.cancel'),
                  field: 'isCanceled',
                },
                { title: t('fota.fota.page.columns.error'), field: 'isError' },
                {
                  title: t('fota.fota.page.columns.expire'),
                  field: 'isExpired',
                },
              ]}
              data={fotaReducer.results}
              actions={[
                {
                  icon: 'view_list',
                  tooltip: t('fota.fota.page.actions.detail'),
                  onClick: (event, rowData) => this.props.getById(rowData.id),
                },
                {
                  icon: 'cancel',
                  tooltip: t('fota.fota.page.actions.delete'),
                  onClick: (event, rowData) => this.onClickOpenModal(rowData),
                },
              ]}
            />
            <AlertDialog
              open={this.state.openModal}
              title={t('fota.fota.page.alert_dialog.title')}
              onClickAccept={this.onClickAcceptModal}
              onClickCancel={this.onClickCancelModal}
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}

export default FotasView
