const AppUrl = process.env.PUBLIC_URL

const selectBattery = (battery) => {
  if (battery == 0) return 0
  if (battery <= 25) return 25
  if (battery <= 50) return 50
  if (battery <= 75) return 75
  if (battery <= 100) return 100
}

const selectColor = (outOfService, reserved) => {
  if (outOfService) return 'red'
  // if (reserved) return "yellow";
  if (!outOfService && !reserved) return 'green'
}

export const selectIconMarkerMap = (scooter) => {
  const { model, scutum, outOfService, activeServiceId } = scooter
  const url = `${AppUrl}/content/icons/iconsByBattery/${selectBattery(
    scutum ? scutum.batterySoc : 0,
  )}/model_${model === 'S01' ? '01' : '02'}_${selectColor(
    outOfService,
    activeServiceId,
  )}.png`

  const oldURL = `${AppUrl}/content/icons/newIcons/pin_1_r.png`
  return oldURL
}
