import React from 'react'
import FotaFormView from './page'

class FotaForm extends React.Component {
  render() {
    return <FotaFormView {...this.props} />
  }
}

export default FotaForm
