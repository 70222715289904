import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoWindow: {
    position: 'absolute',
    bottom: '45px',
    right: '45px',
    background: '#fafafa',
    boxShadow: ' 0 0 8px #b9b9b9',
    padding: '1% 2%',
    maxWidth: '35%',
  },
  infoWindowContent: {
    fontSize: '0.8em',
  },
  link: {
    cursor: 'pointer',
  },
  filterSection: {
    padding: '1% 5%',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },
  imgContainerImgNotFound: {
    // margin: "auto",
    width: '20%',
  },
  imgContainer: {
    // margin: "auto",
    width: '30%',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default useStyles
