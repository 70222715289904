import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const firmwareService = {
  getAll,
  getById,
  insert,
  update,
  _delete,
}

function getAll() {
  return handleCall(`/fota/v1/firmware`, {
    method: 'GET',
    body: null,
  })

  // const requestOptions = {
  //     method: 'GET',
  //     headers: authHeader()
  // };

  // return fetch(stringConstants.URL_ApiGateway + '/fota/v1/firmware', requestOptions).then(handleResponse);
}

function getById(id) {
  return handleCall(`/fota/v1/firmware/${id}`, {
    method: 'GET',
    body: null,
  })
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/firmware/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function insert(firmware) {
  return handleCall(`/fota/v1/firmware`, {
    method: 'POST',
    body: JSON.stringify(firmware),
  })
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(firmware),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/firmware",
  //     requestOptions
  //   ).then(handleResponse);
}

function update(firmware) {
  const id = firmware.Id
  return handleCall(`/fota/v1/firmware/${id}`, {
    method: 'PUT',
    body: JSON.stringify(firmware),
  })
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(firmware),
  //   };
  //   const id = firmware.Id;
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/firmware/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function _delete(id) {
  return handleCall(`/fota/v1/firmware/${id}`, {
    method: 'DELETE',
    body: null,
  })
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/firmware/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
