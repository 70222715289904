import { combineReducers } from 'redux'

import { alertReducer } from './alert_reducer'
import { authenticationReducer } from './authentication_reducer'
import { customerReducer } from './customer_reducer'
import { dynamicsReducer } from './dynamic_reducer'
import { fileReducer } from './fota/file_reducer'
import { firmwareReducer } from './fota/firmware_reducer'
import { fotaReducer } from './fota/fota_reducer'
import { upgradeReducer } from './fota/upgrade_reducer'
import { notificationsReducer } from './notifications_reducer'
import { oemReducer } from './oem_reducer'
import { operatorCustomerReducer } from './operatorCustomer_reducer'
import { operatorReducer } from './operator_reducer'
import { registrationReducer } from './registration_reducer'
import { scooterReducer } from './scooter_reducer'
import { servicesReducer } from './services_reducer'
import { uiReducer } from './ui_reducer'
import { userReducer } from './user_reducer'

const reducer = combineReducers({
  userReducer,
  alertReducer,
  registrationReducer,
  authenticationReducer,
  scooterReducer,
  customerReducer,
  firmwareReducer,
  upgradeReducer,
  fileReducer,
  fotaReducer,
  operatorReducer,
  servicesReducer,
  operatorCustomerReducer,
  oemReducer,
  notificationsReducer,
  uiReducer,
  dynamicsReducer,
})

export default reducer
