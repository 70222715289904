import stringConstants from '../constants/strings'
import { handleCall } from './handleResponse'

export const dynamicsService = {
  getAll,
  updateReport,
}

function getAll(body = {}) {
  let endpoint = '/batteries/v1/UnifiedDynamics?'

  if (body.source === 'none') body.source = ''

  endpoint += (body.batteryPackId && `&bpid=${body.batteryPackId}`) ?? ''
  endpoint += (body.stationId && `&station=${body.stationId}`) ?? ''
  endpoint += (body.scooterId && `&scooter=${body.scooterId}`) ?? ''
  endpoint += (body.minSoc && `&minSoc=${body.minSoc}`) ?? ''
  endpoint += (body.maxSoc && `&maxSoc=${body.maxSoc}`) ?? ''
  endpoint += (body.forcedError && `&forcedError=${body.forcedError}`) ?? ''
  endpoint += (body.source && `&source=${body.source}`) ?? ''
  endpoint += (body.since && `&since=${body.since}`) ?? ''
  endpoint += (body.until && `&until=${body.until}`) ?? ''

  return handleCall(
    endpoint,
    {
      method: 'GET',
      body: null,
    },
    stringConstants.URL_Baas,
  )
}

function updateReport(bPackId = '') {
  const endpoint = `/bgservices/v1/UnifiedDynamic/UpdateReport${
    bPackId ? `?batteryPackId=${bPackId}` : ''
  }`

  return handleCall(
    endpoint,
    {
      method: 'PATCH',
      body: null,
    },
    stringConstants.URL_Fleet,
  )
}
