import i18next from 'i18next'
import { scooterConstants } from '../../constants/scooter_constants'
import { history } from '../../helpers/history'
import { scooterService } from '../../services/scooterService'
import { alertActions } from './alert_actions'

import { customerActions } from './customers_actions'

import moment from 'moment'
import { commandString } from '../../helpers/scooterCommands'

export const scooterActions = {
  getAll,
  filter,
  getId,
  clearResults,
  getDynamics,
  getDynamicsById,
  getTripsByScooterId,
  getTripDetails,
  getAllMapByOperatorId,
  assignToOperate,
  getAllDynamicByOperator,
  getAllDynamics,
  getDataById,
  getMissingDynamic,
  assignToSharingOperator,
  assignToPrivateCustomer,
  unassignToSharingOperator,
  createNotification,
  getAllByCustomer,
  assignToOEM,
  getAllByOemId,
  sendCommand,
  removeFriendSharingUser,
  checkAssignedScootersMessages,
  getImage,
  saveCenterMap,
  resetRsdk,
  getRsdkByVehicleId,
}

function saveCenterMap(body) {
  return (dispatch) => {
    dispatch(success(body))
  }

  function success(body) {
    return { type: scooterConstants.SAVE_CENTER_MAP, body }
  }
}

function getImage(options) {
  return (dispatch) => {
    scooterService.getImage(options).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        // dispatch(failure(error));
      },
    )
  }

  function success(results) {
    return { type: scooterConstants.GET_IMAGE_SUCCESS, results, options }
  }
}

function checkAssignedScootersMessages(result) {
  return (dispatch) => {
    result && result.length != 0
      ? dispatch(showMessage(result))
      : dispatch(hiddeMessage())
  }
  function showMessage(result) {
    return { type: scooterConstants.SHOW_MESSAGES_ASSIGN_SCOOTER, result }
  }
  function hiddeMessage() {
    return { type: scooterConstants.HIDDE_MESSAGES_ASSIGN_SCOOTER }
  }
}

function removeFriendSharingUser(body) {
  return (dispatch) => {
    scooterService.removeFriendSharingUser(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.removeFriendSharingUserSuccess'),
          ),
        )
        dispatch(customerActions.getFriendSharingByScooterId(body.vehicleId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.scooters.removeFriendSharingUserError') + error,
          ),
        )
      },
    )
  }
}

function sendCommand(body) {
  return (dispatch) => {
    dispatch(request(body))
    if (body.command.command === commandString.LOCATE) {
      let newBodyScooterOn = {
        ...body,
        command: { args: null, command: commandString.SCOOTER_ON },
      }
      scooterService.sendCommand(newBodyScooterOn).then(
        (results) => {
          dispatch(success(results))
          let newBodyWarnings = {
            ...body,
            command: { args: null, command: commandString.WARNINGS },
          }
          scooterService.sendCommand(newBodyWarnings).then(
            (results) => {
              dispatch(success(results))
            },
            (error) => {
              dispatch(failure(error))
            },
          )
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    } else {
      scooterService.sendCommand(body).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }

    dispatch(alertActions.success(i18next.t('services.scooters.commandSended')))
  }

  function request() {
    return {
      type: scooterConstants.SEND_COMMAND_REQUEST,
      payload: body,
    }
  }

  function success(results) {
    return { type: scooterConstants.SEND_COMMAND_SUCCESS, results, body }
  }

  function failure(error) {
    return { type: scooterConstants.SEND_COMMAND_FAILURE, error, body }
  }
}

function getAllByOemId(oemId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllByOemId(oemId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_SCOOTER_BY_OEM_ID_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_SCOOTER_BY_OEM_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_SCOOTER_BY_OEM_ID_FAILURE, error }
  }
}

function getAllByCustomer(userId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllByCustomer(userId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.scooters.getAllError')))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_FAILURE, error }
  }
}

function createNotification(body) {
  return (dispatch) => {
    scooterService.createNotification(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            alertActions.error(
              i18next.t('services.scooters.createNotificationSuccess'),
            ),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.scooters.createNotificationFalse') + error,
          ),
        )
      },
    )
  }
}

function unassignToSharingOperator(body) {
  return (dispatch) => {
    scooterService.unassignToSharingOperator(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.unassignToSharingOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.scooters.unassignToSharingOperatorError') +
              error,
          ),
        )
      },
    )
  }
}

function assignToPrivateCustomer(body) {
  return (dispatch) => {
    scooterService.assignToPrivateCustomer(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(checkAssignedScootersMessages(results))
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.assignToPrivateCustomerSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.scooters.assignToPrivateCustomerError'),
          ),
        )
      },
    )
  }
}

function assignToOEM(body) {
  return (dispatch) => {
    scooterService.assignToOEM(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(checkAssignedScootersMessages(results))
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.assignToOEMSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.scooters.assignToOEMError')),
        )
      },
    )
  }
}

function assignToSharingOperator(body) {
  return (dispatch) => {
    scooterService.assignToSharingOperator(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(checkAssignedScootersMessages(results))
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.assignToSharingOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.scooters.assignToSharingOperatorError'),
          ),
        )
      },
    )
  }
}

function getMissingDynamic() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getMissingDynamic().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.scooters.getAllError')))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_FAILURE, error }
  }
}

function getDataById(scooterId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getDataById(scooterId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.scooters.getAllError')))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_FAILURE, error }
  }
}

function getAll(operatorId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_FAILURE, error }
  }
}

function getDynamicsById(idScooter) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getDynamicsById(idScooter).then(
      (dynamic) => {
        dispatch(success(dynamic))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_DYNAMIC_REQUEST }
  }

  function success(dynamic) {
    return { type: scooterConstants.GET_DYNAMIC_SUCCESS, dynamic }
  }

  function failure(error) {
    return { type: scooterConstants.GET_DYNAMIC_FAILURE, error }
  }
}

function getAllMapByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllMapByOperatorId(operatorId).then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_REQUEST }
  }

  function success(dynamics) {
    return {
      type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS,
      dynamics,
    }
  }

  function failure(error) {
    return { type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getAllDynamics() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllDynamics().then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}

function getDynamics() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getDynamics().then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}
function getAllDynamicByOperator(operatorId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllDynamicByOperator(operatorId).then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}

function getTripsByScooterId(idScooter, since, until) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getTripsByScooterId(idScooter, since, until).then(
      (trips) => {
        dispatch(success(trips))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.TRIPS_REQUEST }
  }

  function success(trips) {
    return { type: scooterConstants.TRIPS_SUCCESS, trips }
  }

  function failure(error) {
    return { type: scooterConstants.TRIPS_FAILURE, error }
  }
}

function getTripDetails(idScooter, IdTrip) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getTripDetails(idScooter, IdTrip).then(
      (tripDetail) => {
        dispatch(success(tripDetail))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.TRIPS_DETAIL_REQUEST }
  }

  function success(tripDetail) {
    return { type: scooterConstants.TRIPS_DETAIL_SUCCESS, tripDetail }
  }

  function failure(error) {
    return { type: scooterConstants.TRIPS_DETAIL_FAILURE, error }
  }
}

function filter(field, value) {
  return (dispatch) => {
    dispatch(request(field, value))
    const data = { field, value }
    scooterService.filter(data).then(
      (results) => {
        results.forEach((element) => {
          element.manufactureDate = moment(element.manufactureDate).format(
            'DD/MM/YYYY',
          )
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request(field, value) {
    return { type: scooterConstants.FILTER_REQUEST, field, value }
  }

  function success(results) {
    return { type: scooterConstants.FILTER_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.FILTER_FAILURE, error }
  }
}

function assignToOperate(body, isCloseTicket = false) {
  return (dispatch) => {
    scooterService.assignToOperate(body).then(
      (scooter) => {
        isCloseTicket ||
          dispatch(
            alertActions.success(
              i18next.t('services.scooters.assignToOperateSuccess'),
            ),
          )
      },
      (error) => {
        isCloseTicket ||
          dispatch(
            alertActions.error(
              i18next.t('services.scooters.assignToOperateError') + error,
            ),
          )
      },
    )
  }
}

function getId(id) {
  return (dispatch) => {
    dispatch(request(id))
    scooterService.getById(id).then(
      (scooter) => {
        dispatch(success(scooter))
        history.push('/scooters/' + id)
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request(id) {
    return { type: scooterConstants.DETAILS_REQUEST, id }
  }

  function success(scooter) {
    return { type: scooterConstants.DETAILS_SUCCESS, scooter }
  }

  function failure(error) {
    return { type: scooterConstants.DETAILS_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: scooterConstants.CLEAR_RESULTS }
  }
}

function resetRsdk(vehicleId) {
  return (dispatch) => {
    if (!vehicleId) dispatch(failure('No vehicleId'))
    dispatch(request())
    scooterService.resetRsdk(vehicleId).then(
      () => {
        dispatch(success())
        dispatch(
          alertActions.success(i18next.t('services.scooters.resetRsdkSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.scooters.resetRsdkError')),
        )
      },
    )
  }

  function request() {
    return { type: scooterConstants.RESET_RSDK_REQUEST }
  }

  function success() {
    return { type: scooterConstants.RESET_RSDK_SUCCESS }
  }

  function failure(error) {
    return { type: scooterConstants.RESET_RSDK_FAILURE, error }
  }
}

function getRsdkByVehicleId(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request())
      scooterService.getRsdkByVehicleId(id).then(
        (data) => {
          dispatch(success(data))
          resolve(data)
        },
        (error) => {
          dispatch(failure(error))
          reject(error)
        },
      )
    })
  }

  function request() {
    return { type: scooterConstants.GET_RSDK_BY_VEHICLE_ID_REQUEST }
  }

  function success(data) {
    return { type: scooterConstants.GET_RSDK_BY_VEHICLE_ID_SUCCESS, data }
  }

  function failure(error) {
    return { type: scooterConstants.GET_RSDK_BY_VEHICLE_ID_FAILURE, error }
  }
}
