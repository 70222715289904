import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { formatDateToDisplay } from '../../../shared/formatDate'

import CustomMaterialTable from '../../../shared/customMaterialTable'

const useStyles = makeStyles({
  container: {
    maxHeight: 400,
    padding: '2%',
  },
})

const DrawerFilter = ({
  isOpen,
  onClose,
  scooterData,
  onSelect,
  onShowAllScooters,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const list = (anchor) => (
    <div role="presentation" className={classes.container}>
      <div style={{ padding: '0px 10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onShowAllScooters()}
        >
          {t('scooters.location.filter.btn')}
        </Button>
      </div>
      <CustomMaterialTable
        saveFilters={true}
        title={t('scooters.location.filter.title')}
        onRowClick={(data) => onSelect(data)}
        rowClickAvaiable={true}
        columns={[
          {
            field: 'plate',
            title: t('scooters.location.filter.columns.plate'),
          },
          {
            field: 'model',
            title: t('scooters.location.filter.columns.model'),
          },
          { field: 'imei', title: t('scooters.location.filter.columns.imei') },
          {
            field: 'frameNo',
            title: t('scooters.location.filter.columns.frameN'),
          },
          {
            field: 'lastConnectionTimestamp',
            title: t('scooters.location.filter.columns.lastConnection'),
            render: (rowData) =>
              formatDateToDisplay(rowData.lastConnectionTimestamp, true),
          },
        ]}
        data={scooterData}
      />
    </div>
  )

  return (
    <div>
      <Drawer anchor={'bottom'} open={isOpen} onClose={() => onClose()}>
        {list('bottom')}
      </Drawer>
    </div>
  )
}

export default DrawerFilter
