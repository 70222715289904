import { Button, Grid, Hidden, InputLabel, TextField } from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'
import Spinner from '../../../shared/Spinner'
import useStyles from './style'

class FileDetailView extends React.Component {
  constructor(props) {
    super(props)
    const { fileReducer } = props
    const file = fileReducer.file
    this.state = {
      targetType: file.targetType,
      targetModel: file.targetModel,
      firmwares: file.firmwares,
      firmwaresTypes: file.containedFirmwaresTypes,
      firmwaresVersions: file.containedFirmwaresVersions,
      filePath: file.filePath,
      fileName: file.fileName,
      description: file.description,
      createDate: file.createDate,
      createUser: file.createUser,
    }
  }

  render() {
    const { t, fileReducer } = this.props
    const translations = t('material_table.tableStrings', {
      returnObjects: true,
    })
    const classes = this.props.styles
    return (
      <React.Fragment>
        <Spinner loading={fileReducer.loadingResults} />
        <h2 className={classes.title}>{this.props.title}</h2>
        <div className={classes.divContainer}>
          <form autoComplete="off">
            <Grid className={classes.root} container spacing={3}>
              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2}>
                <TextField
                  label={t('fota.file.detail.page.target_type')}
                  value={this.state.targetType || ''}
                  name="targetType"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  label={t('fota.file.detail.page.target_model')}
                  value={this.state.targetModel || ''}
                  name="targetModel"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('fota.file.detail.page.fw_types')}
                  value={this.state.firmwaresTypes || ''}
                  name="firmwaresTypes"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('fota.file.detail.page.fw_versions')}
                  value={this.state.firmwaresVersions || ''}
                  name="firmwaresVersions"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={3}>
                <TextField
                  label={t('fota.file.detail.page.file_path')}
                  value={this.state.filePath || ''}
                  name="filePath"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('fota.file.detail.page.file_name')}
                  value={this.state.fileName || ''}
                  name="fileName"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={t('fota.file.detail.page.description')}
                  value={this.state.description || ''}
                  name="description"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={2}>
                <TextField
                  label={t('fota.file.detail.page.createDate')}
                  value={this.state.createDate || ''}
                  name="createDate"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t('fota.file.detail.page.user')}
                  value={this.state.createUser || ''}
                  name="createUser"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid
                item
                md={1}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />
              <Grid item md={6}>
                <MaterialTable
                  localization={translations}
                  components={{
                    Toolbar: (props) => (
                      <React.Fragment>
                        <InputLabel>
                          {t('fota.file.detail.page.fws')}
                        </InputLabel>
                      </React.Fragment>
                    ),
                    Pagination: (props) => <React.Fragment />,
                  }}
                  options={{ padding: 'dense', pageSize: 3 }}
                  columns={[
                    {
                      title: t('fota.file.detail.page.columns.hw_type'),
                      field: 'hardwareType',
                    },
                    {
                      title: t('fota.file.detail.page.columns.hw_model'),
                      field: 'hardwareModel',
                    },
                    {
                      title: t('fota.file.detail.page.columns.version'),
                      field: 'version',
                    },
                  ]}
                  data={this.state.firmwares}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={1}
                implementation="css"
                smDown
                component={Hidden}
              />

              <Grid container justify="center" alignItems="center">
                <Grid
                  container
                  item
                  md={2}
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.props.onClickLeftButton}
                    style={{ margin: 15 }}
                    startIcon={this.props.leftIcon}
                  >
                    {this.props.leftButtonValue}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const classes = useStyles()
  return <FileDetailView styles={classes} {...props} />
}

export default Hook
