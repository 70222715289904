import React, { Fragment, useEffect, useState } from 'react'

import MaterialTable, { MTableBody } from 'material-table'

import SortIcon from '@material-ui/icons/Sort'

import { findSelectedRow, insertToColumns } from '../currentFilters'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import { uiActions } from '../../../redux/actions/ui_actions'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

//https://material-table.com/#/

const CustomMaterialTable = (props) => {
  const {
    t,
    selection, //si se informa se permite la selección de filas.
    saveFilters, //si se informa se guardará en redux el estado actual de la tabla, guardando filtros y filas seleccionadas.
    onRowClick, //función que se ejecuta al hacer 'click' a una fila. Es necesario informar de rowClickAvaiable para habilitar esta función.
    rowClickAvaiable, //si se informa se habilitará que se pueda hacer 'click' en una fila.
    columns, //definición de columnas a insertar en la tabla.
    data, //array para llenar la tabla de datos.
    setFilterTable,
    title, // si se informa define el título de la tabla.
    tab, // en caso de querer guardar en redux el state de la tabla y esta esté en una misma localización, ej: /customers, se deberá asignar un valor único y descriptivo de la ubicación de la tabla.
    autoclickSelected = false, // si se guarda el estado de la tabla en redux esto permitirá que cuando se cargue de nuevo la tabla y esta tenga una fila seleccionada se seleccione.
    preSelectedRows, //array con valores preseleccionado, util cuando tenemos el selection habilitado y queremos preseleccionar ciertas filas.
    addSummaryRow = false, // si se informa los valores numéricos de la tabla se sumarán en una última fila de la tabla.
    filtering = true, // Si se informa a false, se quitan los filtros por columna
    isLoading = false, // Spinner al cargar los datos
  } = props

  const translations = t('material_table.tableStrings', {
    returnObjects: true,
  })

  const [pageSize, setpageSize] = useState(20)
  const [selectedRow, setselectedRow] = useState(null)
  const [localMaterialTableData, setlocalMaterialTableData] = useState([])
  const [localMaterialTableDataRender, setlocalMaterialTableDataRender] =
    useState([])
  const [localMaterialTableColumns, setlocalMaterialTableColumns] = useState([])
  useEffect(() => {
    const updatedColumns = columns.filter(
      (c) => !c.hidden || !c.hasOwnProperty('hidden'),
    )

    addSummaryRow &&
      updatedColumns.splice(0, 0, {
        title: '#',
        field: 'index',
        // filtering: false,
        // filterComponent: (props) => (
        //   <Fragment
        //     {...props}
        //     style={{ background: "rgb(151, 215, 0)" }}
        //   ></Fragment>
        // ),

        customFilterAndSearch: (term, rowData) =>
          term == rowData.tableData.id + 1,
        render: (rowData) => parseInt(rowData.tableData.id) + 1,
        cellStyle: {
          backgroundColor: 'rgb(151 215 0)',
          color: 'rgb(0 0 0)',
        },
      })

    setlocalMaterialTableColumns(
      saveFilters ? insertToColumns(updatedColumns, tab) : updatedColumns,
    )

    autoclickSelected &&
      rowClickAvaiable &&
      saveFilters &&
      setselectedRow(findSelectedRow(tab))
  }, [columns])

  function isNumeric(str) {
    if (typeof str === 'number') return true
    if (typeof str != 'string') return false // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
  }

  let summaryRow = { summaryRow: true }

  const loopObject = (obj) => {
    for (const key in obj) {
      const updatedsummaryRow = summaryRow
      if (typeof obj[key] === 'object') {
        loopObject(obj[key])
      } else {
        if (isNumeric(obj[key])) {
          if (summaryRow.hasOwnProperty([key])) {
            let currentValue = parseFloat(summaryRow[key])
            currentValue += parseFloat(obj[key])
            summaryRow = {
              ...updatedsummaryRow,
              [key]: parseFloat(currentValue).toFixed(2),
            }
          } else {
            summaryRow = {
              ...updatedsummaryRow,
              [key]: parseFloat(obj[key]).toFixed(2),
            }
          }
        }
      }
    }
  }

  const renderSummaryRow = (array = []) => {
    const currentArraySize = array.length

    array.slice(0, pageSize - 1).map((a) => {
      loopObject(a)
    })

    if (Object.keys(summaryRow).length === 1 || !addSummaryRow) return array

    if (currentArraySize < pageSize) {
      return [...array, summaryRow]
    } else {
      const updatedArray = array
      updatedArray.splice(pageSize - 1, 0, summaryRow)

      return updatedArray
    }
  }

  useEffect(() => {
    const updatedData =
      data &&
      data.map((i, index) => {
        return {
          ...i,
          localMaterialTableRowId: index, //Math.random() * Math.random(),
          tableData: {
            checked:
              preSelectedRows &&
              preSelectedRows.find((s) => (s.id || s) === i.id) != null,
          },
        }
      })

    setlocalMaterialTableDataRender(
      updatedData && updatedData.filter((i) => !i.hasOwnProperty('summaryRow')),
    )

    setlocalMaterialTableData(renderSummaryRow(updatedData))

    autoclickSelected &&
      rowClickAvaiable &&
      saveFilters &&
      setselectedRow(findSelectedRow(tab))
  }, [data, pageSize, preSelectedRows])

  const handleRowClick = (rowData) => {
    if (rowClickAvaiable) {
      // onRowClick(rowData);
      setselectedRow(rowData)
      saveFilters && setFilterTable({ tab, selectedRow: rowData })
    }
  }

  useEffect(() => {
    selectedRow && rowClickAvaiable && onRowClick(selectedRow)
  }, [selectedRow])

  const allowSumFields = (key) => {
    let fields = [
      'localMaterialTableRowId',
      'imei',
      'phone',
      'contactPhone',
      'cif',
      'nif',
      'mobilePhone',
      'iban',
      'batterySoc',
      'endBattery',
      'batteryUsage',
      'startBattery',
      'speedAvg',
      'speedMax',
      'speedAverage',
      'id',
    ]
    return !fields.find((i) => i === key)
  }

  const rendersummaryRowComponent = () => {
    const summaryRowLocal =
      localMaterialTableData.find((i) => i.hasOwnProperty('summaryRow')) || {}

    let sumTotalFieldsToRender = []

    localMaterialTableColumns.map((c) => {
      if (summaryRowLocal.hasOwnProperty(c.field) && allowSumFields(c.field)) {
        sumTotalFieldsToRender.push({
          ...c,
          MTDisplaySumTotal: true,
          MTsumTotalValue: summaryRowLocal[c.field],
        })
      } else {
        let add = false

        const fieldSplit = c.field && c.field.split('.')
        const lastFieldSplit = fieldSplit
          ? fieldSplit[fieldSplit.length - 1]
          : []

        Object.keys(summaryRowLocal).map((k) => {
          if (lastFieldSplit === k && allowSumFields(lastFieldSplit)) {
            sumTotalFieldsToRender.push({
              ...c,
              MTDisplaySumTotal: true,
              MTsumTotalValue: summaryRowLocal[k],
            })
            add = true
          }
        })

        !add && sumTotalFieldsToRender.push({ ...c, MTDisplaySumTotal: false })
      }
    })

    // console.log("summaryRowLocal", summaryRowLocal);
    // console.log("sumTotalFieldsToRender", sumTotalFieldsToRender);
    return (
      sumTotalFieldsToRender.filter((i) => i.MTDisplaySumTotal).length > 0 && (
        <TableRow>
          {sumTotalFieldsToRender.map((d) => {
            const { MTsumTotalValue, MTDisplaySumTotal, field } = d
            return (
              <TableCell style={{ background: '#dedede' }}>
                {field === 'index'
                  ? 'Subtotal'
                  : MTDisplaySumTotal
                  ? MTsumTotalValue
                  : ''}
              </TableCell>
            )
          })}
          {props.actions && props.actions.length > 0 && (
            <TableCell style={{ background: '#dedede' }}></TableCell>
          )}
        </TableRow>
      )
    )
  }

  return (
    <MaterialTable
      style={{ minWidth: 700 }}
      icons={{ Filter: () => <SortIcon /> }}
      {...props}
      isLoading={isLoading}
      data={localMaterialTableDataRender}
      title={title}
      columns={localMaterialTableColumns}
      components={{
        // FilterRow: (props) => <input />,
        Body: (props) => (
          <Fragment>
            <MTableBody
              // style={{ backgroundColor: "blue" }}
              {...props}
              onFilterChanged={(columnId, value) => {
                props.onFilterChanged(columnId, value)
                // Do you job here :)
                saveFilters &&
                  setFilterTable({ columnId, value, tab, selectedRow })
              }}
            />
            {rendersummaryRowComponent()}
          </Fragment>
        ),
      }}
      onRowClick={(event, rowData) =>
        rowClickAvaiable && handleRowClick(rowData)
      }
      localization={translations}
      onChangeRowsPerPage={(pageSize) => {
        setpageSize(pageSize)
      }}
      options={{
        padding: 'dense',
        filtering,
        search: false,
        headerStyle: { padding: '5px' }, //change header padding
        emptyRowsWhenPaging: false,
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          // maxWidth: 100,
        },
        headerStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          backgroundColor: '#e41622',
          color: 'white',
          fontSize: '1.2em',
        },
        bodyStyle: {
          Width: '100%',
        },
        exportButton: true,
        selection: selection,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 20, 100],
        pageSize: pageSize,

        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow &&
            selectedRow.localMaterialTableRowId ===
              rowData.localMaterialTableRowId
              ? 'rgb(241 255 207)'
              : rowData.tableData.id % 2
              ? '#f9f9f9'
              : 'inherit',
        }),
      }}
    />
  )
}

function mapState(state) {
  //
}

const actionCreators = {
  setFilterTable: uiActions.setFilterTable,
}

export default withTranslation('common')(
  connect(null, actionCreators)(CustomMaterialTable),
)
