import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const fotaService = {
  getAll,
  getById,
  insert,
  update,
  _delete,
  startProcess,
  filter,
  cancel,
  getMasterData,
}

function getAll() {
  return handleCall(`/fota/v1/fota`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //     method: 'GET',
  //     headers: authHeader()
  // };
  // return fetch(stringConstants.URL_ApiGateway + '/fota/v1/fota', requestOptions).then(handleResponse);
}

function getById(id) {
  return handleCall(`/fota/v1/fota/${id}`, {
    method: 'GET',
    body: null,
  })
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function insert(fota) {
  return handleCall(`/fota/v1/fota`, {
    method: 'POST',
    body: JSON.stringify(fota),
  })
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(fota),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota",
  //     requestOptions
  //   ).then(handleResponse);
}

function startProcess(process) {
  return handleCall(`/fota/v1/fota/startFotasProcess`, {
    method: 'POST',
    body: JSON.stringify(process),
  })
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(process),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/startFotasProcess",
  //     requestOptions
  //   ).then(handleResponse);
}

function update(fota) {
  const id = JSON.stringify(fota).id
  return handleCall(`/fota/v1/fota/${id}`, {
    method: 'PUT',
    body: JSON.stringify(fota),
  })
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(fota),
  //   };
  //   const id = JSON.stringify(fota).id;
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function cancel(id) {
  return handleCall(`/fota/v1/fota/cancel?id=${id}`, {
    method: 'PUT',
    body: null,
  })
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/cancel?id=" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function filter(data) {
  const querystring = data.field + '&value=' + data.value
  return handleCall(`/fota/v1/fota/cancel?field=${querystring}`, {
    method: 'GET',
    body: null,
  })
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };
  //   const querystring = data.field + "&value=" + data.value;
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/?field=" + querystring,
  //     requestOptions
  //   ).then(handleResponse);
}

function _delete(id) {
  return handleCall(`/fota/v1/fota/${id}`, {
    method: 'DELETE',
    body: null,
  })
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/" + id,
  //     requestOptions
  //   ).then(handleResponse);
}

function getMasterData() {
  return handleCall(`/fota/v1/fota/GetMasterData`, {
    method: 'GET',
    body: null,
  })
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     stringConstants.URL_ApiGateway + "/fota/v1/fota/GetMasterData",
  //     requestOptions
  //   ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'mngmt_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(error)
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('mngmt_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('mngmt_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'mngmt_token',
          token.access_token,
          token.expires_in,
        )
      })
    }
    return data
  })
}
