import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { Fragment, useState } from 'react'

const DialogConfirmAction = ({
  isOpen,
  onClose,
  onConfirm,
  data,
  hasNote,
  label = 'Nota',
  addNote = true,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [newComment, setnewComment] = useState('')

  const { header, body } = data
  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    onConfirm(newComment)
    setnewComment('')
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
          {hasNote && (
            <Fragment>
              {addNote && <p>Añade una nota</p>}
              <TextField
                label={label}
                multiline
                fullWidth
                rows={10}
                variant="outlined"
                value={newComment}
                onChange={(e) => setnewComment(e.target.value)}
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogConfirmAction
