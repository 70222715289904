import React, { useEffect, useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { connect } from 'react-redux'

import { scooterActions } from '../../../redux/actions/scooter_actions'

import { useTranslation } from 'react-i18next'
import ScooterDetails from '../../scooters/dialgos/scooterDetails'

import CustomMaterialTable from '../../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ScooterListOEM = ({
  isOpen,
  onClose,
  data,
  scooterReducer,
  getAllByOemId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isDialogScooterDetailsOpen, setisDialogScooterDetailsOpen] =
    useState(false)
  const [selectedRow, setselectedRow] = useState()
  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    isOpen && getAllByOemId(data.oid)
  }, [data])

  const handleSelectScooterAction = (data) => {
    setselectedRow(data)
    setisDialogScooterDetailsOpen(true)
  }
  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('customers.page.scooterList.toolbarTitle')}: {data.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          {scooterReducer.showResults ? (
            <CustomMaterialTable
              saveFilters={true}
              title=""
              columns={[
                {
                  title: t('customers.page.scooterList.table.columns.name'),
                  field: 'name',
                },
                {
                  title: t('customers.page.scooterList.table.columns.plate'),
                  field: 'plate',
                },
                {
                  title: t('customers.page.scooterList.table.columns.imei'),
                  field: 'imei',
                },
              ]}
              data={scooterReducer.scootersByOemId}
              actions={[
                {
                  tooltip: t(
                    'customers.page.scooterList.table.actions.scooterDetails',
                  ),
                  icon: 'chrome_reader_mode',
                  onClick: (evt, data) => handleSelectScooterAction(data),
                },
              ]}
            />
          ) : (
            <CircularProgress color="primary" />
          )}
        </div>
        <ScooterDetails
          isOpen={isDialogScooterDetailsOpen}
          onClose={() => setisDialogScooterDetailsOpen(false)}
          data={selectedRow}
        />
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getAllByOemId: scooterActions.getAllByOemId,
}

export default connect(mapState, actionCreators)(ScooterListOEM)
