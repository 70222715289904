import { Grid, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import moment from 'moment'
import React from 'react'
import useStyles from './styles'

class FilterFotas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processId: '',
    }
    this.onClickSearch = this.onClickSearch.bind(this)
  }

  onChangeProcessId = (event, newValue) => {
    this.setState({
      processId: newValue,
    })
  }

  onClickSearch() {
    const data = { field: 'ProcessId', value: this.state.processId.processId }
    this.props.filter(data)
  }

  componentDidMount() {
    this.props.getProcessIds()
  }

  render() {
    const { t, fotaReducer } = this.props
    const classes = this.props.styles
    return (
      <React.Fragment>
        {fotaReducer.processIds != null ? (
          <div className={classes.root}>
            <Grid container spacing={3} alignItems="center" justify="center">
              <Grid item md={5}>
                <Autocomplete
                  value={this.state.processId || ''}
                  id="processId_id"
                  onChange={this.onChangeProcessId}
                  getOptionLabel={(option) =>
                    option === ''
                      ? ''
                      : moment(option.processStartDate).format('DD/MM/YYYY') +
                        ' ' +
                        option.oldVersions +
                        ' to ' +
                        option.newVersions
                  }
                  name="processId"
                  options={fotaReducer.processIds}
                  className={classes.autocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('fota.fota.filter.index.processId')}
                      name="processId"
                    />
                  )}
                />
              </Grid>
              <Grid item md={1}>
                <Button
                  size="small"
                  disabled={
                    this.state.processId !== null &&
                    this.state.processId !== '' &&
                    !this.props.loading
                      ? false
                      : true
                  }
                  variant="contained"
                  color="primary"
                  onClick={this.onClickSearch}
                >
                  {t('fota.fota.filter.index.search')}
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}

function Hook(props) {
  const styles = useStyles()
  return <FilterFotas styles={styles} {...props} />
}

export default Hook
