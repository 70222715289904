import { Grid, TextField } from '@material-ui/core'
import React from 'react'

class DetailPanelUpgrade extends React.Component {
  render() {
    const { classes, t, rowData } = this.props
    return (
      <React.Fragment>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={3}
          className={classes.rootDetail}
        >
          <Grid item md={2}>
            <TextField
              label={t('fota.upgrade.page.columns.createDate')}
              value={rowData.createDate}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={t('fota.upgrade.page.columns.createUser')}
              value={rowData.createUser}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default DetailPanelUpgrade
