import { operatorConstant } from '../../constants/operator_constant'

const initialState = {
  results: false,
  loadingResults: true,
  results: [],
  dataOperatorLogged: {
    id: '',
    areas: [],
  },
  discounts: [],
  customersAffectedByDiscount: [],
}

export function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
      }

    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST:
      return {
        ...state,
      }
    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS:
      return {
        ...state,
        customersAffectedByDiscount: action.results,
      }
    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE:
      return {
        ...state,
        customersAffectedByDiscount: [],
        error: action.error,
      }

    case operatorConstant.GET_ALL_DISCOUNTS_REQUEST:
      return {
        ...state,
      }
    case operatorConstant.GET_ALL_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: action.results,
      }
    case operatorConstant.GET_ALL_DISCOUNTS_FAILURE:
      return {
        ...state,
        discounts: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.SUCCESS_OPERATOR_INFO:
      return {
        ...state,
        dataOperatorLogged: action.user,
        loadingResults: false,
      }
    case operatorConstant.REQUEST_OPERATOR_INFO:
      return {
        ...state,
        loadingResults: true,
      }
    default:
      return state
  }
}
