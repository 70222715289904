import { servicesConstants } from '../../constants/services_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
}

export function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case servicesConstants.GETALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case servicesConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case servicesConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
