import i18next from 'i18next'
import { upgradeConstants } from '../../../constants/fota/upgrade_constants'
import { factory } from '../../../helpers/factory'
import { history } from '../../../helpers/history'
import { upgradeService } from '../../../services/upgradeService'
import { alertActions } from '../alert_actions'

export const upgradeActions = {
  getAll,
  getById,
  insert,
  update,
  _delete,
  clearResults,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    upgradeService.getAll().then(
      (results) => {
        results.forEach((element) => {
          element.createDate = factory.formatDate(
            element.createDate,
            'DD/MM/YYYY HH:MM',
          )
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.upgrades.getAllError')))
      },
    )
  }

  function request() {
    return { type: upgradeConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: upgradeConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: upgradeConstants.GETALL_FAILURE, error }
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id))
    upgradeService.getById(id).then(
      (upgrade) => {
        dispatch(success(upgrade))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.upgrades.getByIdError')),
        )
      },
    )
  }

  function request(id) {
    return { type: upgradeConstants.DETAILS_REQUEST, id }
  }

  function success(upgrade) {
    return { type: upgradeConstants.DETAILS_SUCCESS, upgrade }
  }

  function failure(error) {
    return { type: upgradeConstants.DETAILS_FAILURE, error }
  }
}

function update(upgrade) {
  return (dispatch) => {
    dispatch(request())
    upgradeService.update(upgrade).then(
      (upgrade) => {
        dispatch(success(upgrade))
        dispatch(
          alertActions.success(i18next.t('services.upgrades.updateSuccess')),
        )
        history.push('/upgrades/' + upgrade.id)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.upgrades.updateError')))
      },
    )
  }

  function request() {
    return { type: upgradeConstants.EDIT_REQUEST }
  }

  function success(upgrade) {
    return { type: upgradeConstants.EDIT_SUCCESS, upgrade }
  }

  function failure(error) {
    return { type: upgradeConstants.EDIT_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: upgradeConstants.CLEAR_RESULTS }
  }
}

function insert(upgrade) {
  return (dispatch) => {
    dispatch(request())
    upgradeService.insert(upgrade).then(
      (upgrade) => {
        dispatch(success(upgrade))
        dispatch(
          alertActions.success(i18next.t('services.upgrades.insertSuccess')),
        )
        history.push('/upgrades')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.upgrades.insertError')))
      },
    )
  }

  function request() {
    return { type: upgradeConstants.ADD_REQUEST }
  }

  function success(upgrade) {
    return { type: upgradeConstants.ADD_SUCCESS, upgrade }
  }

  function failure(error) {
    return { type: upgradeConstants.ADD_FAILURE, error }
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request())
    upgradeService._delete(id).then(
      (upgrade) => {
        dispatch(success(id))
        dispatch(
          alertActions.success(i18next.t('services.upgrades.deleteSuccess')),
        )
        history.push('/upgrades')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.upgrades.deleteError')))
      },
    )
  }

  function request() {
    return { type: upgradeConstants.DELETE_REQUEST }
  }

  function success(id) {
    return { type: upgradeConstants.DELETE_SUCCESS, id }
  }

  function failure(error) {
    return { type: upgradeConstants.DELETE_FAILURE, error }
  }
}
