import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useState } from 'react'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const CreateOem = ({
  isOpen,
  onClose,
  onInsert,
  onUpdate,
  isDialogSetupInsert,
  dataSelected,
}) => {
  const { t } = useTranslation()
  const [name, setname] = useState('')
  const [password, setpassword] = useState('')
  const [email, setemail] = useState('')
  const [contactPhone, setcontactPhone] = useState('')
  const [endpoint, setendpoint] = useState('')
  const [oemAuthUserName, setoemAuthUserName] = useState('')
  const [oemAuthPassword, setoemAuthPassword] = useState('')

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (isOpen && !isDialogSetupInsert) {
      const { name, email, endpoint, contactPhone, authentication } =
        dataSelected
      setname(name)
      setemail(email)
      setcontactPhone(contactPhone)
      setendpoint(endpoint)
      setoemAuthUserName(authentication.userName)
      setoemAuthPassword(authentication.password)
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
    setname('')
    setpassword('')
    setemail('')
    setcontactPhone('')
    setendpoint('')
    setoemAuthUserName('')
    setoemAuthPassword('')
  }

  const handleSubmit = () => {
    const body = {
      name,
      email,
      password,
      contactPhone: contactPhone.includes('+34')
        ? contactPhone
        : `+34${contactPhone}`,
      endpoint,
      // oemAuthEndpoint: oemAuthEndpoint === "" ? null : oemAuthEndpoint,
      oemAuthUserName,
      oemAuthPassword,
    }

    !isDialogSetupInsert && delete body.password

    isDialogSetupInsert
      ? onInsert(body)
      : onUpdate({ ...body, id: dataSelected.id })

    handleClose()
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isDialogSetupInsert
            ? t('oem.register.title')
            : t('oem.register.titleUpdate')}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={t('oem.register.label.name')}
                type={'email'}
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  fullWidth
                  label={t('oem.register.label.email')}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </FormControl>
            </Grid>
            {isDialogSetupInsert && (
              <Grid item xs={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    label={t('oem.register.label.password')}
                    value={password}
                    type={'password'}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={t('oem.register.label.phone')}
                value={contactPhone}
                onChange={(e) => setcontactPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                required
                fullWidth
                label={t('oem.register.label.endpoint')}
                value={endpoint}
                onChange={(e) => setendpoint(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <TextField
                fullWidth
                label={t('oem.register.label.oemAuthUserName')}
                value={oemAuthUserName}
                onChange={(e) => setoemAuthUserName(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <TextField
                fullWidth
                label={t('oem.register.label.oemAuthPassword')}
                type="password"
                value={oemAuthPassword}
                onChange={(e) => setoemAuthPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {isDialogSetupInsert
              ? t('oem.register.btnSubmit')
              : t('oem.register.btnUpdate')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateOem
