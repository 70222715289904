import i18next from 'i18next'

export const validations = {
  required,
  minLength,
  maxLength,
  alMostOne,
  compareDate,
  checkExtensionFile,
  isInteger,
}

function required(value, callback = null) {
  if (value !== null && value.length === 0) {
    return { result: false, message: i18next.t('validations.required') }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function checkExtensionFile(fileName, extension, callback = null) {
  var ext = fileName.split('.')
  if (extension.toLowerCase() !== ext[ext.length - 1].toLowerCase()) {
    return {
      result: false,
      message: i18next.t('validations.checkExtensionFile'),
    }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function compareDate(date1, date2) {
  var d1 = new Date(date1)
  var d2 = new Date(date2)
  if (d2.getTime() < d1.getTime()) {
    return { result: false, message: i18next.t('validations.comparateDate') }
  } else {
    return { result: true, message: '' }
  }
}

function alMostOne(array) {
  var result = false
  var message = i18next.t('validations.alMostOne')
  array = array.filter(function (el) {
    return el !== ''
  })
  if (array.length === 0) {
    return { result, message }
  } else {
    return { result: true, message: '' }
  }
}

function minLength(value, fieldName, min) {
  if (value.length < min) {
    return {
      result: false,
      message: 'El minimo para ' + fieldName + ' es ' + min,
    }
  } else {
    return { result: true, message: '' }
  }
}

function maxLength(value, fieldName, max) {
  if (value.length > max) {
    return {
      result: false,
      message: 'El maximo para ' + fieldName + ' es ' + max,
    }
  } else {
    return { result: true, message: '' }
  }
}

function isInteger(value) {
  var result = false
  var message = i18next.t('validations.alMostOne')

  if (value == null) {
    return { result, message }
  }

  return /^[0-9]*$/.test(value)
    ? { result: true, message: '' }
    : { result, message }
}
