import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import React from 'react'
import { history } from '../../../../helpers/history'
import FotaForm from '../form/index'

class FotaCreateView extends React.Component {
  constructor(props) {
    super(props)
    this.onClickCreate = this.onClickCreate.bind(this)
  }

  onClickComeBack() {
    history.goBack()
  }

  onClickCreate = (fota) => {
    if (fota.type !== 'click') {
      console.log(JSON.stringify(fota))
      this.props.insert(fota)
    }
  }

  render() {
    const { t } = this.props
    return (
      <FotaForm
        readOnly={false}
        {...this.props}
        onClickLeftButton={this.onClickComeBack}
        onClickRightButton={this.onClickCreate}
        leftIcon={<KeyboardBackspaceIcon />}
        rightIcon={<FilterListIcon />}
        leftButtonValue={t('fota.fota.create.page.leftButtonValue')}
        rightButtonValue={t('fota.fota.create.page.rightButtonValue')}
        typeButton="submit"
      />
    )
  }
}

export default FotaCreateView
