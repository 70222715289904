import i18next from 'i18next'
import { fileConstants } from '../../../constants/fota/file_constansts'
import { factory } from '../../../helpers/factory'
import { history } from '../../../helpers/history'
import { fileService } from '../../../services/fileService'
import { alertActions } from '../alert_actions'

export const fileActions = {
  getAll,
  getById,
  insert,
  update,
  _delete,
  clearResults,
  getFirmwaresVersions,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    fileService.getAll().then(
      (results) => {
        results.forEach((element) => {
          element.createDate = factory.formatDate(
            element.createDate,
            'DD/MM/YYYY HH:MM',
          )
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.files.getAllError')))
      },
    )
  }

  function request() {
    return { type: fileConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: fileConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: fileConstants.GETALL_FAILURE, error }
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id))
    fileService.getById(id).then(
      (file) => {
        file.createDate = factory.formatDate(
          file.createDate,
          'DD/MM/YYYY HH:MM',
        )
        dispatch(success(file))
        history.push('/files/' + id)
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.files.getByIdError')))
      },
    )
  }

  function request(id) {
    return { type: fileConstants.DETAILS_REQUEST, id }
  }

  function success(file) {
    return { type: fileConstants.DETAILS_SUCCESS, file }
  }

  function failure(error) {
    return { type: fileConstants.DETAILS_FAILURE, error }
  }
}

function update(file) {
  return (dispatch) => {
    dispatch(request())
    fileService.update(file).then(
      (file) => {
        dispatch(success(file))
        dispatch(
          alertActions.success(i18next.t('services.files.updateSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.files.updateError')))
      },
    )
  }

  function request() {
    return { type: fileConstants.EDIT_REQUEST }
  }

  function success(file) {
    return { type: fileConstants.EDIT_SUCCESS, file }
  }

  function failure(error) {
    return { type: fileConstants.EDIT_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: fileConstants.CLEAR_RESULTS }
  }
}

function insert(file) {
  return (dispatch) => {
    dispatch(request())
    fileService.insert(file).then(
      (file) => {
        dispatch(success(file))
        dispatch(
          alertActions.success(i18next.t('services.files.insertSuccess')),
        )
        history.push('/files')
      },
      (error) => {
        dispatch(failure(error))
        //dispatch(alertActions.error(i18next.t('services.files.insertError')))
        dispatch(alertActions.error(error.toString()))
      },
    )
  }

  function request() {
    return { type: fileConstants.ADD_REQUEST }
  }

  function success(file) {
    return { type: fileConstants.ADD_SUCCESS, file }
  }

  function failure(error) {
    return { type: fileConstants.ADD_FAILURE, error }
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request())
    fileService._delete(id).then(
      (file) => {
        dispatch(success(id))
        dispatch(
          alertActions.success(i18next.t('services.files.deleteSuccess')),
        )
        history.push('/files')
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.files.deleteError')))
      },
    )
  }

  function request() {
    return { type: fileConstants.DELETE_REQUEST }
  }

  function success(id) {
    return { type: fileConstants.DELETE_SUCCESS, id }
  }

  function failure(error) {
    return { type: fileConstants.DELETE_FAILURE, error }
  }
}

function getFirmwaresVersions(string) {
  return (dispatch) => {
    dispatch(request())
    let string64 = string.split(',')[1]
    fileService.getFirmwaresVersion(string64).then(
      (versions) => {
        dispatch(success(versions))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: fileConstants.FIRMWARES_VERSION_REQUEST }
  }

  function success(versions) {
    return { type: fileConstants.FIRMWARES_VERSION_SUCCESS, versions }
  }

  function failure(error) {
    return { type: fileConstants.FIRMWARES_VERSION_FAILURE, error }
  }
}
