import { scooterConstants } from '../../constants/scooter_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  dynamics: [],
  dynamic: [],
  scooterSelected: {},
  missingDynaimcs: [],
  tripDetail: [],
  trips: [],
  scootersByOemId: [],
  commandSended: [],
  showAssignScooterMsg: false,
  msgAssignScooter: [],
  image: [],
  mapPosition: {
    zoom: 10,
    center: {
      lat: 41.390205,
      lng: 2.154007,
    },
  },
  errorResetRsdk: null,
  successResetRsdk: null,
}

export function scooterReducer(state = initialState, action) {
  switch (action.type) {
    case scooterConstants.SAVE_CENTER_MAP:
      return {
        ...state,
        mapPosition: action.body,
      }
    case scooterConstants.GET_IMAGE_SUCCESS:
      const updatedImageData = [
        ...state.image,
        {
          model: action.options.model,
          color: action.options.color,
          img: action.results,
        },
      ].filter((v, i, a) => a.findIndex((t) => t.img === v.img) === i)
      return {
        ...state,
        image: updatedImageData,
      }
    case scooterConstants.SHOW_MESSAGES_ASSIGN_SCOOTER:
      return {
        ...state,
        showAssignScooterMsg: true,
        msgAssignScooter: action.result,
      }
    case scooterConstants.HIDDE_MESSAGES_ASSIGN_SCOOTER:
      return {
        ...state,
        showAssignScooterMsg: false,
        msgAssignScooter: [],
      }

    case scooterConstants.SEND_COMMAND_REQUEST:
      const updatedCommandSended = [
        ...state.commandSended,
        { ...action.payload, status: 'pending', error: null },
      ]
      return {
        ...state,
        commandSended: updatedCommandSended,
      }
    case scooterConstants.SEND_COMMAND_SUCCESS:
      // action.results
      let selectedSuccess = {
        ...action.body,
        status: 'success',
        error: null,
        msgSuccess: action.results,
      }

      const updatedSuccesscommandSended = state.commandSended.map((c) =>
        c.id === selectedSuccess.id ? selectedSuccess : c,
      )

      return {
        ...state,
        commandSended: updatedSuccesscommandSended,
      }
    case scooterConstants.SEND_COMMAND_FAILURE:
      let selectedFailed = {
        ...action.body,
        status: 'failed',
        error: action.error,
      }

      const updatedFailedcommandSended = state.commandSended.map((c) =>
        c.id === selectedFailed.id ? selectedFailed : c,
      )

      return {
        ...state,
        commandSended: updatedFailedcommandSended,
      }

    case scooterConstants.GET_SCOOTER_BY_OEM_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_SCOOTER_BY_OEM_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        scootersByOemId: action.results,
      }
    case scooterConstants.GET_SCOOTER_BY_OEM_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        scootersByOemId: [],
        error: action.error,
      }

    case scooterConstants.GET_MISSING_DYNAMICS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_MISSING_DYNAMICS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        missingDynaimcs: action.results,
      }
    case scooterConstants.GET_MISSING_DYNAMICS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        missingDynaimcs: [],
        error: action.error,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        scooterSelected: action.results,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        scooterSelected: {},
        error: action.error,
      }

    case scooterConstants.GETALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case scooterConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case scooterConstants.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        showResults: false,
        loadingResults: false,
      }
    case scooterConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case scooterConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case scooterConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case scooterConstants.DETAILS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.DETAILS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        scooter: action.scooter,
      }
    case scooterConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case scooterConstants.GETALL_DYNAMIC_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.GETALL_DYNAMIC_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        dynamics: action.dynamics,
      }
    case scooterConstants.GETALL_DYNAMIC_FAILURE:
      return {
        ...state,
        loadingResults: false,
        dynamics: [],
        error: action.error,
      }
    case scooterConstants.GET_DYNAMIC_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.dynamic,
        loadingResults: false,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        mapByOperator: [],
        error: action.error,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        mapByOperator: action.dynamic,
        loadingResults: false,
      }
    case scooterConstants.GET_DYNAMIC_FAILURE:
      return {
        ...state,
        loadingResults: false,
        dynamic: {},
        error: action.error,
      }
    case scooterConstants.TRIPS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        tripDetail: {},
        trips: [],
      }
    case scooterConstants.TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.trips,
        loadingResults: false,
      }
    case scooterConstants.TRIPS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        trips: [],
        error: action.error,
      }
    case scooterConstants.TRIPS_DETAIL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.TRIPS_DETAIL_SUCCESS:
      return {
        ...state,
        tripDetail: action.tripDetail,
        loadingResults: false,
      }
    case scooterConstants.TRIPS_DETAIL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        tripDetail: {},
        error: action.error,
      }
    case scooterConstants.RESET_RSDK_REQUEST:
      return {
        ...state,
        loadingResetRsdk: true,
        successResetRsdk: false,
        errorResetRsdk: null,
      }
    case scooterConstants.RESET_RSDK_SUCCESS:
      return {
        ...state,
        loadingResetRsdk: false,
        successResetRsdk: true,
        errorResetRsdk: null,
      }
    case scooterConstants.RESET_RSDK_FAILURE:
      return {
        ...state,
        loadingResetRsdk: false,
        successResetRsdk: false,
        errorResetRsdk: action.error,
      }
    default:
      return state
  }
}
