import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import MapDetail from '../details/scooterOnMap'
import SpecsScooterOnMap from '../details/specsOnMap'

import { connect } from 'react-redux'

import { customerActions } from '../../../redux/actions/customers_actions'
import { scooterActions } from '../../../redux/actions/scooter_actions'

import { formatDateToDisplay } from '../../shared/formatDate'

import { useTranslation } from 'react-i18next'
import DialogConfirmAction from '../../shared/modalConfirmAction'

import CustomMaterialTable from '../../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ScooterDetails = ({
  isOpen,
  onClose,
  data,
  getDynamicsById,
  scooterReducer,
  customerReducer,
  getFriendSharingByScooterId,
  removeFriendSharingUser,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [
    isConfirmModalRemoveUserFromFriendSharingOpen,
    setisConfirmModalRemoveUserFromFriendSharingOpen,
  ] = useState(false)
  const [selectedRow, setselectedRow] = useState({})

  useEffect(() => {
    isOpen && getDynamicsById(data.id)
    isOpen && getFriendSharingByScooterId(data.id)
  }, [isOpen])

  const {
    activeServiceId,
    bastidorNum,
    bluetoothMAC,
    frameNo,
    hasOwner,
    id,
    imei,
    isHelmetIncluded,
    lastOwnerChange,
    login,
    manufactureDate,
    model,
    name,
    plate,
    revision,
    selectToOperate,
    sharing,
    sharingOperatorId,
    sharingOperatorKey,
    tableData,
    typeDisplay,
    userDisplayData,
    userId,
  } = data || {}

  const handleClose = () => {
    onClose()
  }

  const handleRemoveUserFromSharing = (rowData) => {
    setselectedRow(rowData)
    setisConfirmModalRemoveUserFromFriendSharingOpen(true)
  }

  const handleRemoveUserFriendSharing = () => {
    const body = {
      vehicleId: data.id,
      email: selectedRow.userEmail,
    }
    removeFriendSharingUser(body)
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('scooters.dialogs.toolbarText')}: {plate || 'undefined'}
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Grid
            container
            spacing={1}
            // alignItems="center"
            // justify="center"
            style={{ padding: '1%', maxWidth: '100%' }}
          >
            <Grid item xs={6} sm={6}>
              {scooterReducer.loadingResults ? (
                <CircularProgress />
              ) : (
                <MapDetail dynamic={scooterReducer.dynamic} />
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <SpecsScooterOnMap data={data} dynamic={scooterReducer.dynamic} />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ margin: '2% 0%' }} />
            </Grid>
            <Grid item xs={12}>
              <CustomMaterialTable
                saveFilters={true}
                title={t('scooters.dialogs.table.title')}
                columns={[
                  {
                    title: t('scooters.dialogs.table.columns.email'),
                    field: 'userEmail',
                  },
                  {
                    title: t('scooters.dialogs.table.columns.start'),
                    field: 'start',
                    render: (rowData) =>
                      formatDateToDisplay(rowData.start, true),
                  },
                  {
                    title: t('scooters.dialogs.table.columns.end'),
                    field: 'end',
                    render: (rowData) => formatDateToDisplay(rowData.end, true),
                  },
                  {
                    title: t('scooters.dialogs.table.columns.sharingMode'),
                    field: 'sharingMode',
                  },
                ]}
                data={customerReducer.friendSharingByScooterId.sharingUsers}
                actions={[
                  {
                    icon: 'remove_circle_outline',
                    tooltip: t('scooters.dialogs.table.actions.remove'),
                    onClick: (event, rowData) =>
                      handleRemoveUserFromSharing(rowData),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <DialogConfirmAction
        isOpen={isConfirmModalRemoveUserFromFriendSharingOpen}
        onClose={() => setisConfirmModalRemoveUserFromFriendSharingOpen(false)}
        onConfirm={handleRemoveUserFriendSharing}
        data={{
          header: t('scooters.dialogs.confirmAction.header'),
          body: t('scooters.dialogs.confirmAction.body'),
        }}
      />
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, customerReducer } = state
  return { scooterReducer, customerReducer }
}

const actionCreators = {
  getDynamicsById: scooterActions.getDynamicsById,
  removeFriendSharingUser: scooterActions.removeFriendSharingUser,
  getFriendSharingByScooterId: customerActions.getFriendSharingByScooterId,
}

export default connect(mapState, actionCreators)(ScooterDetails)
