import React from 'react'
import FirmwareFormView from './page'

class FirmwareForm extends React.Component {
  render() {
    return <FirmwareFormView {...this.props} />
  }
}

export default FirmwareForm
