import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Dashboard from '../../../dashboard'
import FirmwareDetailsView from './page'

class FirmwareDetails extends React.Component {
  render() {
    const { firmwareReducer, t } = this.props
    const firmware = firmwareReducer.firmware
    return (
      <Dashboard
        component={
          <FirmwareDetailsView
            id={firmware.id}
            targetType={firmware.targetType}
            targetModel={firmware.targetModel}
            initialFrameNo={firmware.initialFrameNo}
            finalFrameNo={firmware.finalFrameNo}
            hardwareType={firmware.hardwareType}
            hardwareModel={firmware.hardwareModel}
            version={firmware.version}
            responsible={firmware.responsible}
            description={firmware.description}
            createUser={firmware.createUser}
            createDate={firmware.createDate}
            readOnly={true}
            readEdit={true}
            detailMode={true}
            firmwareReducer={firmwareReducer}
            title={t('fota.firmware.details.index.title')}
            targetTypes={firmwareReducer.targetTypes}
            hardwareTypes={firmwareReducer.hardwareTypes}
            hardwareModels={firmwareReducer.hardwareModels}
            targetModels={firmwareReducer.targetModels}
            t={t}
          />
        }
      />
    )
  }
}

function mapState(state) {
  const { firmwareReducer } = state
  return { firmwareReducer }
}

const actionCreators = {}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(FirmwareDetails)
