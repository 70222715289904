import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firmwareActions } from '../../../redux/actions/fota/firmware_actions'
import { fotaActions } from '../../../redux/actions/fota/fota_actions'
import Dashboard from '../../dashboard'
import FirmwareView from './page'

class Firmware extends React.Component {
  componentDidMount() {
    this.props.getMasterData()
  }

  render() {
    return <Dashboard component={<FirmwareView {...this.props} />} />
  }
}

function mapState(state) {
  const { firmwareReducer } = state
  return { firmwareReducer }
}

const actionCreators = {
  getFirmwares: firmwareActions.getAll,
  getById: firmwareActions.getById,
  _delete: firmwareActions._delete,
  getMasterData: fotaActions.getMasterData,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Firmware)
